import React, { Component } from "react";
import { connect } from "react-redux";

import { updateScale } from "../../../api";
import { Row, Col, Input, InputGroup, InputGroupText } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function roundTo(value, decimals, zeropadding) {
  if (zeropadding === false) {
    return Number(
      Math.round(+value.toFixed(11) + "e" + decimals) + "e-" + decimals
    );
  } else {
    return Number(
      Math.round(+value.toFixed(11) + "e" + decimals) + "e-" + decimals
    ).toFixed(decimals);
  }
}

class Dimensions extends Component {
  state = {
    dims: {
      x: "",
      y: "",
      xFull: "",
      yFull: "",
    },
    scale: 1,
    aspectRatio:
      this.props.design.dimensions.x / this.props.design.dimensions.y,
    timer: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.design.scale != this.props.design.scale) {
      this.setState({
        dims: {
          x: "",
          y: "",
          xFull: "",
          yFull: "",
        },
        scale: 1,
      });
    }
  }

  updateDims = (ev) => {
    // var val = parseFloat(ev.target.value)
    // const aspectRatio = this.props.design.dimensions.x/this.props.design.dimensions.y
    // alert(val)
    if (ev.target.value == "") {
      this.setState({
        dims: {
          x: "",
          y: "",
          xFull: "",
          yFull: "",
        },
        scale: 1,
      });
      return;
    }
    var val = parseFloat(ev.target.value);
    var dims = this.state.dims;
    var scale = val / this.props.design.dimensions[ev.target.name];
    dims = {
      x: "",
      y: "",
      xFull: "",
      yFull: "",
    };
    dims[ev.target.name] = val;

    // if (ev.target.name == "x") {
    //   //dims.y = roundTo(1/aspectRatio*dims.x,4)
    // } else if (ev.target.name == "y") {
    //   //  dims.x = roundTo(aspectRatio*dims.y,4)
    // } else if (ev.target.name == "xFull") {
    //   //  dims.xFull = roundTo(aspectRatio*dims.y,4)
    // } else {
    //   //dims.yFull = roundTo(aspectRatio*dims.y,4)
    // }
    // if(this.state.timer!= null)
    //   clearTimeout(this.state.timer)
    // var timer = setTimeout(()=>{this.saveDims()},2500)
    this.setState({ dims: dims, scale: scale });
  };

  saveDims = (e) => {
    if (e.key !== "Enter" && e.type != "blur") {
      return;
    }
    if (
      this.state.dims.x != "" ||
      this.state.dims.y != "" ||
      this.state.dims.xFull != "" ||
      this.state.dims.yFull != ""
    )
      updateScale(this.state.dims);
  };

  render() {
    return (
      <React.Fragment>
        <h6 className="mt-2">Afmetingen</h6>
        <Row>
          <Col xs="6" className="">
            <span>Ingeschakelde lagen</span>
            <div className="d-flex">
              <InputGroup>
                <Input
                  id="width"
                  name="x"
                  placeholder={roundTo(
                    this.state.scale * this.props.design.dimensions.x,
                    2
                  )}
                  value={roundTo(parseFloat(this.state.dims.x), 2, false) || ""}
                  onChange={this.updateDims}
                  onKeyDown={this.saveDims}
                  onBlur={this.saveDims}
                  type="number"
                />
                <InputGroupText>cm</InputGroupText>
              </InputGroup>
              <div xs="2" className="text-center mx-2">
                <FontAwesomeIcon className=" align-bottom" icon={faTimes} />
              </div>
              <InputGroup>
                <Input
                  id="height"
                  name="y"
                  placeholder={roundTo(
                    this.state.scale * this.props.design.dimensions.y,
                    2
                  )}
                  value={roundTo(parseFloat(this.state.dims.y), 2, false) || ""}
                  onChange={this.updateDims}
                  onKeyDown={this.saveDims}
                  onBlur={this.saveDims}
                  type="number"
                />
                <InputGroupText>cm</InputGroupText>
              </InputGroup>
            </div>
          </Col>
          <Col xs="6" className="">
            <span>Volledige ontwerp</span>
            <div className="d-flex">
              <InputGroup>
                <Input
                  id="completeWidth"
                  name="xFull"
                  placeholder={roundTo(
                    this.state.scale * this.props.design.dimensions.xFull,
                    2
                  )}
                  value={
                    roundTo(parseFloat(this.state.dims.xFull), 2, false) || ""
                  }
                  onChange={this.updateDims}
                  onKeyDown={this.saveDims}
                  onBlur={this.saveDims}
                  type="number"
                />
                <InputGroupText>cm</InputGroupText>
              </InputGroup>
              <div xs="2" className="text-center mx-2">
                <FontAwesomeIcon className=" align-bottom" icon={faTimes} />
              </div>
              <InputGroup>
                <Input
                  id="completeHeigh"
                  name="yFull"
                  placeholder={roundTo(
                    this.state.scale * this.props.design.dimensions.yFull,
                    2
                  )}
                  value={
                    roundTo(parseFloat(this.state.dims.yFull), 2, false) || ""
                  }
                  onChange={this.updateDims}
                  onKeyDown={this.saveDims}
                  onBlur={this.saveDims}
                  type="number"
                />
                <InputGroupText>cm</InputGroupText>
              </InputGroup>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const design = state.design;
  return {
    design,
  };
}

export default connect(mapStateToProps)(Dimensions);
// export default DesignsMenu
