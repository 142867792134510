import React, { useEffect } from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';

import { Provider } from 'react-redux'
import store from './store'

import { loadDesigns } from './store/designs'
import { uploading } from './store/settings'
import { getAnnouncement, createNotification } from './api'

import { loadMaterials } from './store/materials'
import Cookies from 'universal-cookie';
import io  from "socket.io-client";
import { toast } from 'react-toastify';
import * as CC from 'react-cookie-consent';
import GTM from './gtm';
import tax from './tax';

const body = (title,text) =>{
  return <React.Fragment>
    <div className="toast-header p-0" style={{fontSize: "15px"}}>
      <strong className="me-auto p-0">{title}</strong>
    </div>
    <div className="toast-body pt-1 p-0" style={{fontSize: "14px"}}>
      {text}
    </div>
  </React.Fragment>
}
const cookies = new Cookies();
GTM.init()
if(!window.location.pathname.startsWith("/embed")){
  tax.init()
  getAnnouncement();

  const socket = io.connect(process.env.REACT_APP_API);
  socket.connect()

  

  socket.on("upload",data => {
    toast.update("upload",
      {
        render: body(data.title,data.text),
      }
    );
    // store.dispatch(
    //   uploading({
    //     show:true,
    //     title:data.title,
    //     text:data.text
    //   })
    // )
  });

  socket.on("notification",data => {
    console.log(data)
    // createNotification({
    //   type: data.type,
    //   title: data.title,
    //   text: data.text,
    // },5000)
  });

  // client-side
  socket.on("connect", () => {
    window.io_sid = socket.id;
  });
  socket.io.on("reconnect", () => {
    window.io_sid = socket.id;
  });

  socket.on("disconnect", () => {
  });
}

const rootelement = document.getElementById('root');
const root = createRoot(rootelement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);