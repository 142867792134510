import { createSlice } from '@reduxjs/toolkit'
// import { updateDesignColors } from "../../api";
export const counterSlice = createSlice({
  name: 'counter',
  initialState: null
  ,
  reducers: {
    load: (state,action) => {
      
      return {...action.payload,loaded:1, showIgnored:false}
    },
    loading: (state)=> {
      if(state != null)
        state.loaded = 0
      return state
    },
    showIgnored: (state, action) => {
      if(state !== null)
        state.showIgnored = action.payload
      return state
    },
    updateQuantity: (state, action) => {
      state.qty = action.payload
      return state
    },
    updateLayers: (state, action) => {
      let data = action.payload
      // state = {...state,...data}
      if(data){
        state =  {...state,...data}
      }
      //updateDesignColors(state.designColors)
      return state
    },
    unloadDesign: (state) => {
      return null
    }
  },
})

// Action creators are generated for each case reducer function
export const { load,loading, updateQuantity, updateLayers, unloadDesign, showIgnored } = counterSlice.actions

export default counterSlice.reducer
