import React, { Component } from "react";
import { connect } from "react-redux";
import * as d3 from "d3";

import { createRoot } from "react-dom/client";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import FileEmpty from './FileEmpty.svg'

class Magnifier extends React.Component {
    constructor() {
        super();
        this._handleClick = this._handleClick.bind(this);
    }
    _handleClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }
    render() {
        var props = this.props;
        var halfSizeY = props.size / 2;
        var halfSizeX = (props.size + (props.size * .4)) / 2;
        var magX = props.zoomImage.width / props.smallImage.width;
        var magY = props.zoomImage.height / props.smallImage.height;
        var bgX = -(props.offsetX * magX - halfSizeX);
        var bgY = -(props.offsetY * magY - halfSizeY);
        var isVisible = props.offsetY < props.smallImage.height && props.offsetX < props.smallImage.width && props.offsetY > 0 && props.offsetX > 0 && !this.props.disableZoom;
        if(isVisible){
          document.body.classList.add("overflow-hidden")
        } else {
          document.body.classList.remove("overflow-hidden")
        }
        return (<div
                    className={'cursor-zoom-magnifier-container'}
                    style={{
                        position: 'absolute',
                        display: isVisible ? 'block' : 'none',
                        top: props.y,
                        left: props.x,
                        width: props.size + (props.size * .4),
                        height: props.size,
                        marginLeft: -halfSizeX + props.cursorOffset.x,
                        marginTop: -halfSizeY + props.cursorOffset.y,
                        backgroundColor: 'white',
                        boxShadow: '1px 1px 6px rgba(0,0,0,0.3)',
                        pointerEvents:"none",
                        zIndex: 1070
                    }}
                >
                    { props.pointerStyle && <div
                        className={'cursor-zoom-pointer'}
                        style={props.pointerStyle}
                    /> }
                    <div
                        className={'cursor-zoom-magnifier'}
                        style={{
                            width: props.size + (props.size * .4),
                            height: props.size,
                            backgroundImage: 'url(' + props.zoomImage.src + ')',
                            backgroundSize: props.zoomImage.width+'px '+props.zoomImage.height+'px',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: bgX + 'px ' + bgY + 'px',
                            border: props.borderSize + ' solid ' + props.borderColor
                        }}
                        onClick={this._handleClick}
                    />
            </div>
        );
    }
}

function getOffset(el) {
  var x = 0;
  var y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    // FF & IE don't support body's scrollTop - use window instead
    x +=
      el.offsetLeft -
      (el.tagName === "BODY" ? window.pageXOffset : el.scrollLeft);
    y +=
      el.offsetTop -
      (el.tagName === "BODY" ? window.pageYOffset : el.scrollTop);
    el = el.offsetParent;
  }

  return { x: x, y: y };
}

class CursorZoom extends React.Component {
  constructor() {
    super();
    this.state = {
      x: 0,
      y: 0,
      offsetX: -1,
      offsetY: -1,
      loaded:false
    };
    this.image = React.createRef()
  }

  componentDidMount = () => {
    document.addEventListener("mousemove", this.onEvent);
    document.addEventListener("touchmove", this.onEvent);
    document.addEventListener("touchend", this.onEvent);
    // document.addEventListener("scroll", this.onEvent);
    if (!this.portalElement) {
      this.portalElement = document.createElement("div");
      document.body.appendChild(this.portalElement);
      this.root = createRoot(this.portalElement);
    }
    this.componentDidUpdate();
  }

  componentWillUnmount = () => {
    document.removeEventListener("mousemove", this.onEvent);
    document.removeEventListener("touchmove", this.onEvent);
    document.removeEventListener("touchend", this.onEvent);
    // document.removeEventListener("scroll", this.onEvent);
    

    document.body.removeChild(this.portalElement);
    this.portalElement = null;
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps != undefined && prevProps.visualisation != this.props.visualisation)
      this.setState({loaded:false})
    if(!this.state.loaded){

      return
    }
    var img = this.image.current.getElementsByClassName("material")[0].getBoundingClientRect()
    var scale = {
      x:this.props.viewboxSize[2]/this.props.materialSize[2],
      y:this.props.viewboxSize[3]/this.props.materialSize[3],
      scale:3
    }

    var s = new XMLSerializer().serializeToString(this.image.current)
    var encodedData = "data:image/svg+xml;base64," + window.btoa(s);
    this.root.render(
      React.createElement(
        Magnifier,
          {
            size: 250,
            disableZoom:this.props.disableZoom,
            // smallImage: {width:img.clientWidth,height:img.clientHeight},
            // zoomImage: {...this.props.zoomImage, width:img.clientWidth*3, height: img.clientHeight*3,  src:encodedData},
            smallImage: {width:scale.x*img.width,height:scale.y*img.height},
            zoomImage: {...this.props.zoomImage, width:scale.x*img.width*scale.scale, height: scale.y*img.height*scale.scale,  src:encodedData},
            cursorOffset: this.props.cursorOffset,
            borderSize: this.props.borderSize,
            borderColor: this.props.borderColor,
            pointerStyle: this.props.pointerStyle,
            onClick: this._handleClick,
            x: this.state.x,
            y: this.state.y,
            offsetX: this.state.offsetX,
            offsetY: this.state.offsetY
          }
      )
    );
  }

  onEvent = (e)=> {
    if(!this.state.loaded)
      return

    var x = e.clientX
    var y = e.clientY
    if(e.type == "touchmove"){
      x = e.touches[0].clientX;
      y = e.touches[0].clientY;
    } else if(e.type == "touchend") {
      x = -1;
      y = -1;
    }
    if(document.querySelectorAll( "svg.visualisation:hover" ).length == 0){
      x = -1;
      y = -1;
    }
    var bounds = this.image.current.getElementsByClassName("material")[0].getBoundingClientRect()
    var offset = {
      x:bounds.width/this.props.materialSize[2]*(this.props.viewboxSize[0]-this.props.materialSize[0]),
      y:bounds.height/this.props.materialSize[3]*(this.props.viewboxSize[1]-this.props.materialSize[1])
    }
    // var offset = {
    //   x: this.props.viewboxSize[0]*scale.x,
    //   y: this.props.viewboxSize[1]*scale.x
    // }
    offset = {x:bounds.left+offset.x, y: bounds.top+offset.y}//getOffset(this.image);
    var scrollX =
      window.pageXOffset !== undefined
        ? window.pageXOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollLeft;
    var scrollY =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;
    this.setState({
      x: x + scrollX, //(window.scrollX || window.pageXOffset),
      y: y + scrollY, //(window.scrollY || window.pageYOffset),
      offsetX: x - offset.x,
      offsetY: y - offset.y
    });
  }

  loaded = () => {
    this.setState({loaded:true})
    this.props.loaded()
  }

  render() {
    return (
      // <img
      //   ref="image"
      //   width={this.props.image.width}
      //   height={this.props.image.height}
      //   src={this.props.image.src}
      //   style={this.props.image.style}
      // />
      <SVG className="visualisation" innerRef={this.image} src={`${process.env.REACT_APP_API}${this.props.visualisation}`} onLoad={this.loaded} width="100%" height="100%" />
      //<Vis ref={this.image} >
    );
  }
}


function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

class Visualisation extends Component {
  state= {
    img:"",
    loaded:false,
    designColors: {...this.props.design.designColors},
    materialSize: "",
    viewboxSize: "",
    scale:1,
    resizeObserver: null
  }
  constructor(props) {
    super(props);
    this.reference = React.createRef();
  }
  componentDidMount() {

    this.updateLayers(this.state.loaded)
    // window.addEventListener('resize', this.updateLayers);
    if(document.getElementById("visualisation") != null){
      let resizeObserver = new ResizeObserver(this.updateText)
      resizeObserver.observe(document.getElementById("visualisation"))
      this.setState({
        resizeObserver: resizeObserver
      })
    }
  }
  componentWillUnmount() {
    if(this.state.resizeObserver != null)
      this.state.resizeObserver.disconnect(); 
  }
  

  componentDidUpdate(prevProps,prevState) {
    // if(JSON.stringify(prevProps.designColors) != JSON.stringify(this.props.designColors)){
    //   this.setState({designColors:{...this.props.designColors}})
    // }
    if(prevProps != undefined && (prevProps.visualisation != this.props.visualisation || (this.props.design.dimensions.x == null && prevProps.design.dimensions.x != null))) {
      this.materialDims = undefined;
      this.ignored = undefined;
      this.setState({loaded:false})
    } else if(prevProps != undefined && this.props.tab != prevProps.tab ) {
      // setTimeout(()=>{this.updateText(this.state.loaded)},300); 
    } else {
      // if(prevProps.sliderDone != this.props.sliderDone){
      //   this.updateText(this.state.loaded)
      // }
      if(JSON.stringify({...prevProps,sliderDone:false}) == JSON.stringify({...this.props,sliderDone:false})){
       return
      }
      
      this.updateLayers(this.state.loaded)
    }

  }

  updateText = () => {
    if(!this.state.loaded || this.props.design.dimensions.x == null || this.reference.current == null)
      return
    const acc = this.reference.current.image.current//.children;
    
    const material = acc.getElementsByClassName("material")[0]
    let viewbox = this.props.design.viewbox
    
    this.materialDims.selectAll("*").remove()
    
    var dims = this.props.design.dimensions
    material.setAttribute("d",`M ${viewbox[0]} ${viewbox[1]} h ${viewbox[2]} v ${viewbox[3]} h -${viewbox[2]} Z`)
    material.setAttribute("fill","white")
    acc.setAttribute("viewBox", `${roundTo(viewbox[0],2)} ${roundTo(viewbox[1],2)} ${roundTo(viewbox[2],2)} ${roundTo(viewbox[3],2)}`)
    var bbox = material.getBoundingClientRect()
    
    var dist = 10
    var scale = ((viewbox[3])/(bbox.height))
    dist = dist*scale

    let offset = 0;
    if(this.props.design.material != undefined && !this.props.showIgnored && this.props.design.material.XY.length){
      offset = dims.offset
    }

    var xMargin = 0
    var yMargin = 0
    var xMarginRight = 0 
    var yMarginTop = 0
    var shadow = 10
    if(!this.props.showIgnored){
      this.materialDims.append("path").attr("d",`M ${viewbox[2]+viewbox[0]+dist} ${viewbox[1]+offset*96/2.54} v ${dims.y*96/2.54}`).attr("stroke","black").attr("vector-effect","non-scaling-stroke")
      this.materialDims.append("path").attr("d",`M ${viewbox[2]+viewbox[0]+dist-2.5*scale} ${viewbox[1]+offset*96/2.54} h ${5*scale}`).attr("stroke","black").attr("vector-effect","non-scaling-stroke")
      this.materialDims.append("path").attr("d",`M ${viewbox[2]+viewbox[0]+dist-2.5*scale} ${viewbox[1]+(offset+dims.y)*96/2.54} h ${5*scale}`).attr("stroke","black").attr("vector-effect","non-scaling-stroke")
      var text = this.materialDims.append("text").attr("font-size",`${(16)*scale}px`).attr("alignment-baseline","central").attr("x",viewbox[2]+viewbox[0]+dist+5*scale).attr("y",viewbox[1]+offset*96/2.54+(dims.y*96/2.54/2)).attr("font-family","system-ui").text(dims.y+" cm")
      xMarginRight = dist+5*scale-shadow+text.node().getBBox().width
      
      this.materialDims.append("path").attr("d",`M ${viewbox[0]+viewbox[2]-offset*96/2.54} ${viewbox[1]-dist} h ${-dims.x*96/2.54}`).attr("stroke","black").attr("vector-effect","non-scaling-stroke")
      this.materialDims.append("path").attr("d",`M ${viewbox[0]+viewbox[2]-offset*96/2.54-dims.x*96/2.54} ${viewbox[1]-dist-2.5*scale} v ${5*scale}`).attr("stroke","black").attr("vector-effect","non-scaling-stroke")
      this.materialDims.append("path").attr("d",`M ${viewbox[0]+viewbox[2]-offset*96/2.54} ${viewbox[1]-dist-2.5*scale} v ${5*scale}`).attr("stroke","black").attr("vector-effect","non-scaling-stroke")
      var text = this.materialDims.append("text").attr("font-size",`${(16)*scale}px`).attr("text-anchor","middle").attr("x",viewbox[0]+viewbox[2]-offset*96/2.54-(dims.x*96/2.54/2)).attr("y",viewbox[1]-dist-10*scale).attr("font-family","system-ui").text(dims.x+" cm")
      yMarginTop = dist+5*scale-shadow+text.node().getBBox().height
    }

    acc.setAttribute("viewBox", `${roundTo(viewbox[0]-xMargin-0.5-shadow,2)} ${roundTo(viewbox[1]-yMarginTop-0.5-shadow,2)} ${roundTo(viewbox[2]+1+2*shadow+xMargin+xMarginRight,2)} ${roundTo(viewbox[3]+1+2*shadow+yMargin+yMarginTop,2)}`)
    this.setState({
      scale:scale,
      viewboxSize: [
      parseFloat(roundTo(viewbox[0]-0.5-xMargin-shadow,2)),
      parseFloat(roundTo(viewbox[1]-0.5-shadow-yMarginTop,2)),
      parseFloat(roundTo(viewbox[2]+1+2*shadow+xMargin+xMarginRight,2)),
      parseFloat(roundTo(viewbox[3]+1+2*shadow+yMargin+yMarginTop,2))
    ]})
  }

  updateLayers = async (loaded) => {
    if(!loaded || this.props.design.dimensions.x == null || this.reference.current == null)
      return

    const acc = this.reference.current.image.current//.children;
    // svg .material {
    //   -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    //   filter: drop-shadow( 0px 0px 6px rgba(0, 0, 0, .4));
    //   fill: white;
    //   stroke:#ddd;
    // }
    if (!this.materialDims) {
      var svg = d3.select(acc)
      this.materialDims = svg.append("g")
    }
    if (!this.ignored) {
      var svg = d3.select(acc)
      this.ignored = svg.append("g")
    }
    const material = acc.getElementsByClassName("material")[0]
    let viewbox = this.props.design.viewbox

    if(this.props.showIgnored){
      var ignored = this.props.design.ignored
      var smallX = this.state.viewbox[0];
			var smallY = this.state.viewbox[1];
			var largeX = this.state.viewbox[2]-this.state.viewbox[0];
			var largeY = this.state.viewbox[3]-this.state.viewbox[1];
      smallX = Math.min(smallX, ...ignored.text.map(el=>el.smallX), ...ignored.images.map(el=>el.smallX), ...ignored.fills.map(el=>el.smallX))
    	smallY = Math.min(smallY, ...ignored.text.map(el=>el.smallY), ...ignored.images.map(el=>el.smallY), ...ignored.fills.map(el=>el.smallY))
      largeX = Math.max(largeX, ...ignored.text.map(el=>el.width+el.smallX), ...ignored.images.map(el=>el.width+el.smallX), ...ignored.fills.map(el=>el.width+el.smallX))
      largeY = Math.max(largeY, ...ignored.text.map(el=>el.height+el.smallY), ...ignored.images.map(el=>el.height+el.smallY), ...ignored.fills.map(el=>el.height+el.smallY))
      viewbox = [
        (smallX)-30,
        (smallY)-30,
        (largeX - smallX)+60,
        (largeY - smallY)+60
      ]
    }
    if(viewbox[0] == null && viewbox[1] == null && viewbox[2] == null && viewbox[3] == null){
      return
    }

    material.setAttribute("d",`M ${viewbox[0]} ${viewbox[1]} h ${viewbox[2]} v ${viewbox[3]} h -${viewbox[2]} Z`)
    material.setAttribute("fill","white")
    acc.setAttribute("viewBox", `${roundTo(viewbox[0],2)} ${roundTo(viewbox[1],2)} ${roundTo(viewbox[2],2)} ${roundTo(viewbox[3],2)}`)
    this.setState({materialSize: viewbox})
    var xMargin = 0
    var yMargin = 0
    var xMarginRight = 0 
    var yMarginTop = 0
    this.materialDims.selectAll("*").remove()

    var shadow = 10
    var dist = 10
    var dims = this.props.design.dimensions
    // var offset = 0
    var offset = 0

    var bbox = material.getBoundingClientRect()
    
    var scale = ((viewbox[3])/(bbox.height))
    dist = dist*scale
    if(this.props.design.material != undefined && !this.props.showIgnored && this.props.design.material.XY.length){
      material.setAttribute("stroke","#ddd")
      material.setAttribute("fill","white")
      material.setAttribute("style","filter: drop-shadow( 0px 0px 6px rgba(0, 0, 0, .4));")
      offset = dims.offset

    } else {
      material.setAttribute("stroke","none")
      material.setAttribute("fill","white")
      material.setAttribute("style","")
    }

    if(!this.props.showIgnored){
      this.materialDims.append("path").attr("d",`M ${viewbox[2]+viewbox[0]+dist} ${viewbox[1]+offset*96/2.54} v ${dims.y*96/2.54}`).attr("stroke","black").attr("vector-effect","non-scaling-stroke")
      this.materialDims.append("path").attr("d",`M ${viewbox[2]+viewbox[0]+dist-2.5*scale} ${viewbox[1]+offset*96/2.54} h ${5*scale}`).attr("stroke","black").attr("vector-effect","non-scaling-stroke")
      this.materialDims.append("path").attr("d",`M ${viewbox[2]+viewbox[0]+dist-2.5*scale} ${viewbox[1]+(offset+dims.y)*96/2.54} h ${5*scale}`).attr("stroke","black").attr("vector-effect","non-scaling-stroke")
      var text = this.materialDims.append("text").attr("font-size",`${(16)*scale}px`).attr("alignment-baseline","central").attr("x",viewbox[2]+viewbox[0]+dist+5*scale).attr("y",viewbox[1]+offset*96/2.54+(dims.y*96/2.54/2)).attr("font-family","system-ui").text(dims.y+" cm")
      xMarginRight = dist+5*scale-shadow+text.node().getBBox().width
      
      this.materialDims.append("path").attr("d",`M ${viewbox[0]+viewbox[2]-offset*96/2.54} ${viewbox[1]-dist} h ${-dims.x*96/2.54}`).attr("stroke","black").attr("vector-effect","non-scaling-stroke")
      this.materialDims.append("path").attr("d",`M ${viewbox[0]+viewbox[2]-offset*96/2.54-dims.x*96/2.54} ${viewbox[1]-dist-2.5*scale} v ${5*scale}`).attr("stroke","black").attr("vector-effect","non-scaling-stroke")
      this.materialDims.append("path").attr("d",`M ${viewbox[0]+viewbox[2]-offset*96/2.54} ${viewbox[1]-dist-2.5*scale} v ${5*scale}`).attr("stroke","black").attr("vector-effect","non-scaling-stroke")
      var text = this.materialDims.append("text").attr("font-size",`${(16)*scale}px`).attr("text-anchor","middle").attr("x",viewbox[0]+viewbox[2]-offset*96/2.54-(dims.x*96/2.54/2)).attr("y",viewbox[1]-dist-10*scale).attr("font-family","system-ui").text(dims.x+" cm")
      yMarginTop = dist+5*scale-shadow+text.node().getBBox().height
    }

    //Scaling
    if(!this.props.showIgnored){
      var transformRaster = acc.getElementsByClassName("raster")[0].getAttribute("transform").replaceAll(/(matrix\()|(\))/ig,"").split(",")
      transformRaster[0] = this.props.design.scale
      transformRaster[3] = this.props.design.scale

      var transformNonRaster = acc.getElementsByClassName("nonRaster")[0].getAttribute("transform").replaceAll(/(matrix\()|(\))/ig,"").split(",")
      transformNonRaster[0] = this.props.design.scale
      transformNonRaster[3] = this.props.design.scale

      acc.getElementsByClassName("raster")[0].setAttribute("transform",`matrix(${transformRaster.join(",")})`)
      acc.getElementsByClassName("nonRaster")[0].setAttribute("transform",`matrix(${transformNonRaster.join(",")})`)
    } else {
      var transformRaster = acc.getElementsByClassName("raster")[0].getAttribute("transform").replaceAll(/(matrix\()|(\))/ig,"").split(",")
      transformRaster[0] = 1
      transformRaster[3] = 1

      var transformNonRaster = acc.getElementsByClassName("nonRaster")[0].getAttribute("transform").replaceAll(/(matrix\()|(\))/ig,"").split(",")
      transformNonRaster[0] = 1
      transformNonRaster[3] = 1

      acc.getElementsByClassName("raster")[0].setAttribute("transform",`matrix(${transformRaster.join(",")})`)
      acc.getElementsByClassName("nonRaster")[0].setAttribute("transform",`matrix(${transformNonRaster.join(",")})`)
    }

    const rasters = acc.getElementsByClassName("raster")[0].children//[1].children//
    for (let i = 0; i < rasters.length; i++) {
      let path = rasters[i];
      let stroke = path.getAttribute("stroke")
      let layer  = this.props.design.designColors[stroke]

      if(layer.raster){
        //path.classList.add("raster");
        path.setAttribute("fill-rule","evenodd")
        path.setAttribute("fill-opacity","0.5")
        path.setAttribute("fill",stroke)
      } else {
        //path.classList.remove("raster");
        path.setAttribute("fill-rule","evenodd")
        path.setAttribute("fill-opacity","0.5")
        path.setAttribute("fill","none")
      }

      if(layer.cut || layer.engrave){
        if(layer.engrave){
          path.setAttribute("stroke-opacity","0.5")
        } else {
          path.setAttribute("stroke-opacity","1")
        }
      } else {
        // path.classList.add("noStroke");
        path.setAttribute("stroke-opacity","0")
      }
    }

    const nonRasters = acc.getElementsByClassName("nonRaster")[0].children//[1].children//
    for (let i = 0; i < nonRasters.length; i++) {
      let path = nonRasters[i];
      let stroke = path.getAttribute("stroke")
      let layer  = this.props.design.designColors[stroke]
      if(layer.cut || layer.engrave){
        if(layer.engrave){
          path.setAttribute("stroke-opacity","0.4")
        } else {
          path.setAttribute("stroke-opacity","1")
        }

      } else {
        path.setAttribute("stroke-opacity","0")
      }
    }

    var shadow = 10
    acc.setAttribute("viewBox", `${roundTo(viewbox[0]-xMargin-0.5-shadow,2)} ${roundTo(viewbox[1]-yMarginTop-0.5-shadow,2)} ${roundTo(viewbox[2]+1+2*shadow+xMargin+xMarginRight,2)} ${roundTo(viewbox[3]+1+2*shadow+yMargin+yMarginTop,2)}`)
    this.setState({
      scale:scale,
      viewboxSize: [
      parseFloat(roundTo(viewbox[0]-0.5-xMargin-shadow,2)),
      parseFloat(roundTo(viewbox[1]-0.5-shadow-yMarginTop,2)),
      parseFloat(roundTo(viewbox[2]+1+2*shadow+xMargin+xMarginRight,2)),
      parseFloat(roundTo(viewbox[3]+1+2*shadow+yMargin+yMarginTop,2))
    ]})
    bbox = material.getBoundingClientRect()
    this.ignored.selectAll("*").remove()

    if(this.props.showIgnored){
      Object.keys(this.props.design.ignored).map((key)=>{
        this.props.design.ignored[key].map((el,i)=>{
          this.ignored.append("g").append("path").attr("fill","#dc3545").attr("opacity","0.2").attr("d", `M ${el.smallX} ${el.smallY} H ${el.largeX} V ${el.largeY} H ${el.smallX} Z`)
          if(key == "fills"){
            var error = this.ignored.append("g").attr("id",key+i).attr("transform",`translate(${(el.smallX + el.width/2)} ${(el.smallY + el.height/2)}) scale(${(14/496)*((viewbox[2])/bbox.width)}) `)
            ///error.append("path").attr("fill","white").attr("d","M 256 -128 V -224 c 0 -17.67 -14.33 -32 -32 -32 h -96 c -17.67 0 -32 14.33 -32 32 H -96 c 0 -17.67 -14.33 -32 -32 -32 H -224 C -241.67 -256 -256 -241.67 -256 -224 v 96 c 0 17.67 14.33 32 32 32 v 192 c -17.67 0 -32 14.33 -32 32 v 96 c 0 17.67 14.33 32 32 32 h 96 c 17.67 0 32 -14.33 32 -32 h 192 c 0 17.67 14.33 32 32 32 h 96 c 17.67 0 32 -14.33 32 -32 v -96 c 0 -17.67 -14.33 -32 -32 -32 V -96 c 17.67 0 32 -14.33 32 -32 z")
            error.append("path").attr("fill","#dc3545").attr("d","M 256 -128 V -224 c 0 -17.67 -14.33 -32 -32 -32 h -96 c -17.67 0 -32 14.33 -32 32 H -96 c 0 -17.67 -14.33 -32 -32 -32 H -224 C -241.67 -256 -256 -241.67 -256 -224 v 96 c 0 17.67 14.33 32 32 32 v 192 c -17.67 0 -32 14.33 -32 32 v 96 c 0 17.67 14.33 32 32 32 h 96 c 17.67 0 32 -14.33 32 -32 h 192 c 0 17.67 14.33 32 32 32 h 96 c 17.67 0 32 -14.33 32 -32 v -96 c 0 -17.67 -14.33 -32 -32 -32 V -96 c 17.67 0 32 -14.33 32 -32 z m -96 -64 h 32 v 32 h -32 V -192 z M -192 -192 h 32 v 32 H -192 V -192 z m 32 384 H -192 v -32 h 32 v 32 z m 352 0 h -32 v -32 h 32 v 32 z m -32 -96 h -32 c -17.67 0 -32 14.33 -32 32 v 32 H -96 v -32 c 0 -17.67 -14.33 -32 -32 -32 H -160 V -96 h 32 c 17.67 0 32 -14.33 32 -32 V -160 h 192 v 32 c 0 17.67 14.33 32 32 32 h 32 v 192 z")
          } else if(key=="text") {
            var error = this.ignored.append("g").attr("id",key+i).attr("transform",`translate(${(el.smallX + el.width/2)} ${(el.smallY + el.height/2)}) scale(${(14/496)*((viewbox[2])/bbox.width)}) `)
            //error.append("path").attr("fill","white").attr("d","M 248 0 c 0 136.997 -111.043 248 -248 248 S -248 136.997 -248 0 C -248 -136.917 -136.957 -248 0 -248 s 248 111.083 248 248 z")
            error.append("path").attr("fill","#dc3545").attr("d","M 215.5789 -256 H -215.5789 C -237.9021 -256 -256 -237.9021 -256 -215.5789 v 105.0678 c 0 22.3232 18.0979 40.4211 40.4211 40.4211 c 22.3232 0 40.4211 -18.0979 40.4211 -40.4211 V -175.1579 h 134.7368 v 350.3158 H -102.8419 c -22.3232 0 -40.4211 18.0979 -40.4211 40.4211 s 18.0979 40.4211 40.4211 40.4211 h 205.6839 c 22.3232 0 40.4211 -18.0979 40.4211 -40.4211 s -18.0979 -40.4211 -40.4211 -40.4211 H 40.4211 V -175.1579 h 134.7368 v 64.6467 c 0 22.3232 18.0979 40.4211 40.4211 40.4211 c 22.3232 0 40.4211 -18.0979 40.4211 -40.4211 V -215.5789 C 256 -237.9021 237.9021 -256 215.5789 -256 z")
          } else if(key=="images") {
            var error = this.ignored.append("g").attr("id",key+i).attr("transform",`translate(${(el.smallX + el.width/2)} ${(el.smallY + el.height/2)}) scale(${(14/512)*((viewbox[2])/bbox.width)} ${(14/(512))*((viewbox[2])/bbox.width)}) `)
            //error.append("path").attr("fill","white").attr("d","M 208 -192 H -208 C -234.51 -192 -256 -170.51 -256 -144 v 288 c 0 26.51 21.49 48 48 48 h 416 c 26.51 0 48 -21.49 48 -48 V -144 c 0 -26.51 -21.49 -48 -48 -48 z m -6 336 H -202 a 6 6 0 0 1 -6 -6 V -138 a 6 6 0 0 1 6 -6 h 404 a 6 6 0 0 1 6 6 v 276 a 6 6 0 0 1 -6 6 z")
            error.append("path").attr("fill","#dc3545").attr("d","M 208 -192 H -208 C -234.51 -192 -256 -170.51 -256 -144 v 288 c 0 26.51 21.49 48 48 48 h 416 c 26.51 0 48 -21.49 48 -48 V -144 c 0 -26.51 -21.49 -48 -48 -48 z m -6 336 H -202 a 6 6 0 0 1 -6 -6 V -138 a 6 6 0 0 1 6 -6 h 404 a 6 6 0 0 1 6 6 v 276 a 6 6 0 0 1 -6 6 z M -128 -104 c -22.091 0 -40 17.909 -40 40 s 17.909 40 40 40 s 40 -17.909 40 -40 s -17.909 -40 -40 -40 z M -160 96 h 320 v -80 l -87.515 -87.515 c -4.686 -4.686 -12.284 -4.686 -16.971 0 L -64 48 l -39.515 -39.515 c -4.686 -4.686 -12.284 -4.686 -16.971 0 L -160 48 v 48 z")
          }
        })
      })
    }
  }

  loaded = () => {
    this.setState({loaded:true,viewbox:this.reference.current.image.current.getAttribute("viewBox").split(/ |,/ig)})

    this.updateLayers(true)
  }
  render (){

    const design = this.props.design

    let file_empty = design.dimensions.x == null
    if(file_empty){
      return <div className="position-relative h-100" style={{minHeight:"200px"}}>
        <img className="position-absolute top-50 start-50 translate-middle" height="25%" width="25%"  src={FileEmpty}/>
      </div>
    }
    return(

      <React.Fragment>
      <CursorZoom disableZoom={false} scale={this.state.scale} loaded={this.loaded} visualisation={this.props.visualisation} viewboxSize={this.state.viewboxSize} materialSize={this.state.materialSize} ref={this.reference}
                image={{

                    width: 450,
                    height: 450
                }}
                zoomImage={{

                    width: 2000,
                    height: 2000
                }}
                cursorOffset={{ x: 0, y: 0 }}
            />
            {/*this.state.loaded && this.props.showIgnored?
              Object.keys(this.props.ignored).map((key)=>{
                return this.props.ignored[key].map((el,i)=>{
                  if(key == "fills") {
                    return <UncontrolledTooltip target={key+i.toString()}>
                      {key=="fills"?"Fill":key=="text"?"Text":key=="im"?"Text":""}
                    </UncontrolledTooltip>
                  } else if(key == "text") {
                    return <UncontrolledTooltip target={key+i.toString()}>
                      {key=="fills"?"Fill":key=="text"?"Text":key=="im"?"Text":""}
                    </UncontrolledTooltip>
                  } else if(key == "images") {
                    return <UncontrolledTooltip target={key+i.toString()}>
                      {key=="fills"?"Fill":key=="text"?"Text":key=="im"?"Text":""}
                    </UncontrolledTooltip>
                  }
                })
              })
            :""*/}

        </React.Fragment>
    )
  }
}


export default (Visualisation);
