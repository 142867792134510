import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'designs',
  initialState: {designs:null,emptyFiles:[]},
  reducers: {
    loadDesigns: (state,action) => {
      state.designs = action.payload
      return state
    },
    addDesigns: (state,action) => {
      state.designs.push(...action.payload)
      return state
    },
    loadEmptyFiles: (state,action) => {
      state.emptyFiles = action.payload
      return state
    },
    closeEmptyFiles: (state) => {
      state.emptyFiles = []
      return state
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    removeDesign: (state,action) => {
      var idx = state.designs.findIndex((design)=>design.ident == action.payload)
      state.designs.splice(idx,1)
      if(!state.designs.length){
        window.history.pushState({}, '', `/`);
      }
      return state
    },
    updateDesigns: (state, action) => {
      const {ident, data} = action.payload
      var idx = state.designs.findIndex((design)=>design.ident == ident)
      state.designs[idx] = {
        ...state.designs[idx],
        ...data
      }
      return state
    }
  },
})

// Action creators are generated for each case reducer function
export const { loadDesigns, loadEmptyFiles, closeEmptyFiles, addDesigns, incrementByAmount, removeDesign, updateDesigns } = counterSlice.actions

export default counterSlice.reducer
