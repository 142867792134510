import React, { Component } from "react";
import { connect } from "react-redux";

import {Container, Row, Col, Button,
FormGroup, Label, Input,
Card, CardBody, UncontrolledTooltip } from "reactstrap";
import {useLocation, useParams, Navigate } from 'react-router-dom';

import { setQuoteField, quoteValidate, quoteData,quoteReset,quoteLoading, quoteSubmit } from "../../store/quote";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faInfoCircle,  faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'

import FadeInOut from "./FadeInOut";
import CheckOperations from "./CheckOperations";
import Loader from "../Analyzer/components/Loader";

import _ from 'lodash'

import { loadquote,quote } from "../../api";
import Designs from "./Designs"
import Error404 from "../../components/error404"
import Error410 from "../../components/error410"
import SubmissionSent from "../../components/SubmissionSent"
import Announcement from "../../components/announcement"
import GTM from "../../gtm";


//Utility

function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {height, width};
}

const InfoCard = (props) => {
  const id = _.uniqueId("shipping")
  return (
  <Card className={"mb-3 "+(props.disabled==true?"text-muted":"border-primary")}>
    <CardBody>
      <Row>
        <Col>
          {props.title}
          <span className="fs-7 d-block">{props.subtitle}</span>
        </Col>
        <Col className="d-flex justify-content-end align-self-center">
          <div>
          <span className="d-block fw-bold lh-sm">&euro; {roundTo(+props.total+parseFloat(roundTo(props.total*0.21,2)),2)}</span>
        <span className="d-block fs-8">&euro; {roundTo(props.total,2)} Ex. BTW</span>
          </div>{
            props.disabled==true?
            <span className="ms-2 align-self-center">
              <FontAwesomeIcon id={id} className="text-info" style={{outline:"none"}} onClick={(e)=>{e.preventDefault()}} icon={faInfoCircle}/>
              <UncontrolledTooltip
                target={id}
              >
                Deze bestelling bevat een of meerdere materialen die niet direct uit voorraad leverbaar zijn, snelle levering is helaas niet mogelijk.
              </UncontrolledTooltip>
            </span >
            :<span className="ms-2 align-self-center"><FontAwesomeIcon style={{visibility:"hidden"}} icon={faInfoCircle}/></span>
          }
          
        </Col>
      </Row>
    </CardBody>
  </Card>)
}

class Analyzer extends Component {
    state = {
      displayType:0,
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
      loadquote(this.props.params.designGroup)
      //Send conversion of checkout page reached to GTM
      GTM.sendEvent("quoteRequest")
    }

    componentWillUnmount() {
      this.props.dispatch(quoteData(null))
      this.props.dispatch(quoteReset())
    }

    componentDidUpdate(prevProps) {
      if(JSON.stringify(prevProps) == JSON.stringify(this.props) )
        return

      if(this.props.design == null || !this.props.design.loaded)
        this.setState({activeTab:1})

    }

    handleResize = () => {
      this.setState({isMobile: getWindowDimensions().width<992})
    }

    fieldChange = (ev) => {
      const { name, value, checked } = ev.target
      if(name == "terms"){
        this.props.dispatch(setQuoteField({
          type:"data",
          field:name,
          value:checked
        }))
      } else {
        this.props.dispatch(setQuoteField({
          type:"data",
          field:name,
          value:value
        }))
      }
    }
    formatData = async (ev) => {
      await this.props.dispatch(quoteValidate())
      if(this.props.quote.valid == false){
        return;
      }
      this.props.dispatch(quoteLoading(true))
      quote(this.props.params.designGroup, {
        email:this.props.quote.data.email.value,
        firstname:this.props.quote.data.firstname.value,
        lastname:this.props.quote.data.lastname.value
      })
    }
    

    render() {
      if(this.props.settings.error == 404){
        return <Error404/>
      } else if(this.props.settings.error == 410) {
        return <Error410/>
      }

      const {quote } = this.props
      const {VATreverseCharge} = quote
      if(quote.quoteData == null) {
          return <Loader text={this.props.settings.error?this.props.settings.error:"Data aan het laden..."}/>
      }
      if (quote.quoteData.redirect) {
        return <Navigate to={"/designs"+quote.quoteData.redirect} />
      }
      const {data,shipCat,shipping} = quote.quoteData
      // const shipText = quote.shipping.shipping.value=="ship"?(coupon.discount=="shipping"?"Gratis verzending":"Verzending"):"Pickup"
      // const shipVal = quote.shipping.shipping.value=="ship"?(coupon.discount=="shipping"?0:quote.quoteData.shipping):0
      const operations = data.reduce((acc,val)=>acc+parseFloat(val.qty*val.price.operations),0)
      // var discountVal = coupon.discount == "percentage"?-roundTo(coupon.value/100*operations,2):(coupon.discount == "amount"?-coupon.value:0);
      const total = data.reduce((acc,val)=>acc+parseFloat(val.price.total.value),0)
      
      const shipTypeAvailable = Math.max(...data.map((design)=>parseInt(design.material.delivery))) == 1
      
       const {email, firstname, lastname, terms} = quote.data
        return (
          <React.Fragment>
            <FadeInOut className="" show={this.props.quote.submitted == true} duration={250}>
              <SubmissionSent/>
            </FadeInOut>
            <FadeInOut className="" show={this.props.quote.submitted == false} duration={250}>            
              <Announcement/>
              <div className="quoteBg">
                <Container fluid="xl" className="position-relative">
                  <Row className="">
                    <Col lg={6} className="checkout pb-3">
                      <img style={{"height":"80px"}} className={"my-0 mb-3 w-md-50 mr-auto font-weight-normal "} src={"/logo-dark.png"}/>
                      <h4 className="fw-normal">Offerte</h4>
                      <p>
                        Zet je bestelling om in een offerte aanvraag. Je ontvangt een mail met een offerte en een link om af te rekenen. Je offerte is 14 dagen geldig. Let op, je kan de ontwerpen niet meer aanpassen na het aanvragen.
                      </p>
                      <form>
                      <Row className="my-3">
                        <Col xs={12}>
                          <FormGroup floating>
                            <Input
                              id="email"
                              name="email"
                              placeholder="Email*"
                              type="email"
                              invalid={!email.valid?"true":""}
                              value={email.value}
                              required={email.required}
                              onChange={this.fieldChange}
                            />
                            <Label for="email">
                              Email{email.required?"*":""}
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          <FormGroup floating>
                            <Input
                              id="firstname"
                              name="firstname"
                              placeholder="Voornaam*"
                              type="text"
                              invalid={!firstname.valid?"true":""}
                              value={firstname.value}
                              required={firstname.required}
                              onChange={this.fieldChange}
                            />
                            <Label for="firstname">
                              Voornaam{firstname.required?"*":""}
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          <FormGroup floating>
                            <Input
                              id="lastname"
                              name="lastname"
                              placeholder="Achternaam*"
                              type="text"
                              invalid={!lastname.valid?"true":""}
                              value={lastname.value}
                              required={lastname.required}
                              onChange={this.fieldChange}
                            />
                            <Label for="lastname">
                              Achternaam{firstname.required?"*":""}
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col xs={12} className="mb-2">
                          <div>
                            <FormGroup check inline >
                              <Input id="terms" type="checkbox" name="terms" checked={terms.value}
                              invalid={!terms.valid?"true":""}
                              required={terms.required}
                              onChange={this.fieldChange} />
                              <Label check for="terms">
                                Ik ga akkoord met de <a href="https://cdn.shopify.com/s/files/1/0025/2058/0194/files/algemene_voorwaarden_2019.pdf" target="_blank">algemene voorwaarden</a>*
                              </Label>
                            </FormGroup>
                          </div>
                          <Button className="me-2" onClick={this.formatData} color="success" disabled={quote.loading || quote.submitted} outline>{!quote.loading?"Indienen":(<FontAwesomeIcon icon={faCircleNotch} spin/>)}</Button>
                        </Col>
                        <Col xs={12}>
                          <Label>Mogelijke leveropties</Label>
                          <InfoCard title="Ophalen Standaard" subtitle="4-5 werkdagen" total={total}/>
                          <InfoCard title="Ophalen Spoed" subtitle="1-2 werkdagen" total={total+((shipCat.fast-1)*operations)} disabled={shipTypeAvailable}/>
                          <InfoCard title="Verzenden Standaard" subtitle="5-6 werkdagen NL, 5-8 werkdagen BE, DE" total={total+shipping} disabled={false}/>
                          <InfoCard title="Verzenden Spoed" subtitle="2-3 werkdagen NL, 2-5 werkdagen BE, DE" total={total+((shipCat.fast-1)*operations)+shipping} disabled={shipTypeAvailable}/>
                        </Col>
                      </Row>
                      </form>
                    </Col>
                    <Col lg={6} className="border-start checkout pb-4 position-relative sidebar">
                      <div className="position-sticky flex-column d-flex" style={{height: "calc(100vh - 5.5em)",top:"4em"}}>
                        <Container>
                          <div className="block pb-3 text-end">
                            <Button color="primary" className="btn btn-primary" onClick={()=>this.setState({displayType:0})} active={this.state.displayType == 0}><FontAwesomeIcon icon={faList}/></Button>
                            <Button color="primary" className="btn btn-primary" onClick={()=>this.setState({displayType:1})} active={this.state.displayType == 1}><FontAwesomeIcon icon={faSquare}/></Button>
                          </div>
                        </Container>
                        <FadeInOut className="overflow-hidden" show={this.state.displayType == 0} duration={250}>
                          <Designs/>
                        </FadeInOut>
                        <FadeInOut className="overflow-hidden h-100" show={this.state.displayType == 1} duration={250}>
                          <CheckOperations data={data}/>
                        </FadeInOut>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </FadeInOut>
          </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
  const design = state.design;
  const quote = state.quote;
  const settings = state.settings;

  return {
    settings,
    design,
    quote
  };
}

const withRouter = (Component) => {
  const Wrapper = (props) => {
    const location = useLocation();
    const params = useParams();

    return (
      <Component
        location={location}
        params={params}
        {...props}
        />
    );
  };

  return Wrapper;
};



export default withRouter(connect(mapStateToProps)(Analyzer));


// export default Analyzer;
