import React, { useEffect, useState, useCallback, useRef } from "react"
import { Col, Form, FormGroup, Input, Label, Row, FloatingLabel, FormText, Button, Alert } from "reactstrap"
import { Highlighter, Hint, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import SlideDown from "../../../components/SlideDown";
import errorImg from "../../../components//paper-plane-regular.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import FadeInOut from "../../Checkout/FadeInOut";
import "iframe-resizer/js/iframeResizer.contentWindow.js"
import GTM from "../../../gtm";

function filterBy(option, state) {
  if (state.selected.length) {
    return true;
  }
  return option.label.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
}


const typeText = {
  0: "Maak een keuze",
  sheet: "Plaatmateriaal laten lasersnijden/graveren",
  object: "Voorwerpen en/of producten aanleveren en laten graveren",
  other: "Anders"
}

const ActiveIndexWatcher = ({ update }) => {
  useEffect(update);
  return null;
};
const testEmail = (email) => {
  let test = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  return test.test(email)
}
const InputTextField = ({type,size, label, id, value, setter, validate, required, autocomplete}) => {
  useEffect(()=>{
  },[value])

  const emailInValid = type=="email"?!testEmail(value):false
  return <Col sm={size}>
        <FormGroup floating>
          <Input
            id={id}
            placeholder={label}
            type={type?type:"text"}
            style={{height:type=="textarea"?"200px":undefined}}
            name={id}
            invalid={((value == "" || emailInValid) && validate) && required != false}
            required={required !== false}
            value={value}
            onChange={(ev)=>setter(ev.target.value)}
            autoComplete={autocomplete}
          />
          <Label for={id}>
          {label}{required != false && <span className="text-danger"> *</span>}
          </Label>
        </FormGroup>
      </Col>
}
const SEARCH_URI = process.env.REACT_APP_API+'/api/contact/search/materials';
const QuoteEmbed = () => {
  const TypeaheadRef = useRef();
  const [validate, setValidate] = useState(false)

  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [company, setCompany] = useState("")
  const [taxNumber, setTaxNumber] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [address, setAddress] = useState("")
  const [zipcode, setZipcode] = useState("")
  const [city, setCity] = useState("")
  const [country, setCountry] = useState("NL")
  const [description, setDescription] = useState("")
  const [type, setType] = useState(0)
  const [delivery,setDelivery] = useState("self")
  const [materialText,setMaterialText] = useState("")
  const [shipping,setShipping] = useState("delivery")

  const [slider, setSlider] = useState(true)
  const [slider2, setSlider2] = useState(false)
  const [deliveryUserChanged,setDeliveryUserChanged] = useState(false)
  const [material,setMaterial] = useState([])

  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  
  const [activeIndex, setActiveIndex] = useState(-1);
  useEffect(()=>{
  }, [materialText])
  const onKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13 && activeIndex === -1) {
        TypeaheadRef.current?.blur()
      }
    },
    [activeIndex]
  );
  const typeChange = (ev) => {
    setType(ev.target.value)
    setSlider2(true)
    setSlider(ev.target.value === "sheet")
    TypeaheadRef.current?.blur()
    setDeliveryUserChanged(false)
  }

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const [files, setFiles] = useState(null);

  const [submitting, setSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [materialLoadError, setMaterialLoadError] = useState(false);
  useEffect(()=>{
    setIsLoading(true);

    fetch(`${SEARCH_URI}`)
    .then((resp) => resp.json())
    .then((items) => {
      setOptions(items);
      setIsLoading(false);
    }).catch((err)=>{
      console.error(err)
      setMaterialLoadError(true)
      setIsLoading(false);
    });
  },[])

  const SubmitQuote = () =>{
    let valid = true
    // company
    let values = [firstname,lastname,email,phone,address,zipcode,city,country,description,type,delivery,shipping]
    values.forEach((entry)=>{
      if(entry == ""){
        valid = false
      }
    })

    if(type === "sheet"){
      if(materialText === ""){
        valid = false
      }
    }

    if(!testEmail(email))
      valid = false
    if(!valid){
      setValidate(true)
      return
    }
    setSubmitting(true)

    let data = {
      firstname,
      lastname,
      email,
      phone,
      address,
      zipcode,
      city,
      country,
      description,
      type,
      delivery,
      material: materialText,
      company,
      taxNumber,
      shipping
    }

    let formdata = new FormData();
    if(files != null){
      for (const key of Object.keys(files)) {
        formdata.append('files', files[key])
      }
    }
    Object.keys(data).forEach((key)=>{
      formdata.append(key, data[key])
    })

    let request = new XMLHttpRequest();

    request.open('POST', process.env.REACT_APP_API+"/api/contact/quote", true);
    request.withCredentials = true;

    request.upload.addEventListener('progress', function(e) {
      let percent_completed = (e.loaded / e.total)*100;
      setProgress(percent_completed)
      // Upload Progress
    });

    request.addEventListener('load', function(e) {
      setSubmitting(false)
      let error = ""
      if(request.status >= 400 && request.status < 500) {
        let err
        try {
          err = JSON.parse(request.response);
        } catch (e) {
        }
        if(err.error){
          error = err.error
        } else {
          error = "Er is een fout opgetreden."
        }
      } else if(request.status >= 500 && request.status < 600) {
        error = "Er is een server fout opgetreden. Probeer het later opnieuw."
      }

      if(request.status != 200){
        setError(true)
        setErrorMsg(error)
        return
      }
      GTM.sendEvent("Quote")
      setSubmitted(true)
    });

    request.addEventListener('error', function(e){
      console.error(e)
      setSubmitting(false)
      setError(true)
      setErrorMsg("Kan geen verbinding maken met de server. Probeer het later opnieuw.")
    });
    request.send(formdata);
  }

  const handleFileChange = (ev) => {
    setError(false)
    setErrorMsg("")
    let tooBig = false
    let errMsg = []
    for(let i= 0; i < ev.target.files.length; i++){
      if(ev.target.files[i].size > 20 * 1024 *1024) {
        tooBig = true
        errMsg.push(`Bestand ${ev.target.files[i].name} is te groot. Maximaal 20MB per bestand.`)
      }
    }

    if(tooBig){
      setError(true)
      setErrorMsg(errMsg.join("\n"))
      ev.target.value = ""
      return
    }
    setFiles(ev.target.files)
  }

  return <div className="container-fluid py-5" data-iframe-height="">
    <FadeInOut className="" show={submitted == true} duration={250}>
      <React.Fragment>
        <div style={{height:"500px", position:"relative"}}>
        <div className=" w-100 text-center mx-auto">
              <h1 className="font-weight-bold text-error mb-3"><img src={errorImg} alt="" style={{maxWidth:"100px"}}className="error-img w-100"/></h1>
              <h3 className="">Gelukt!</h3>
              <span className="">Bedankt voor je aanvraag, wij nemen zo spoedig mogelijk contact met je op.</span>
        </div>
        </div>
      </React.Fragment>
    </FadeInOut>
    <FadeInOut className="" show={submitted == false} duration={250}>
      <Form>
        <Row className="">
          <InputTextField size="6" value={firstname} setter={setFirstname} id="firstname" label="Voornaam" validate={validate} autocomplete="given-name"/>
          <InputTextField size="6" value={lastname} setter={setLastname} id="lastname" label="Achternaam" validate={validate} autocomplete="family-name"/>
          <InputTextField size="6" value={company} setter={setCompany} id="company" label="Bedrijf" validate={validate} required={false} autocomplete="company"/>
          <InputTextField size="6" value={taxNumber} setter={setTaxNumber} id="taxNumber" label="BTW-nummer" validate={validate} required={false} autocomplete="taxnumber"/>
          <InputTextField size="6" value={email} setter={setEmail} type="email" id="email" label="Email" validate={validate} autocomplete="email"/>
          <InputTextField size="6" value={phone} setter={setPhone} id="phone" label="Telefoonnummer" validate={validate} autocomplete="tel"/>
          <InputTextField size="4" value={address} setter={setAddress} id="address" label="Adres" validate={validate} autocomplete="street-address"/>
          <InputTextField size="4" value={zipcode} setter={setZipcode} id="zipcode" label="Postcode" validate={validate} autocomplete="postal-code"/>
          <InputTextField size="4" value={city} setter={setCity} id="city" label="Stad" validate={validate} autocomplete="address-level2"/>
          <Col sm={6}>
            <FormGroup floating>
              <Input
                id="country"
                placeholder="Land"
                type="select"
                name="country"
                onChange={(ev)=>{setCountry(ev.target.value)}}
                invalid={country == "" && validate}
                value={country}
                autoComplete="country"
              >
                <option value="BE">
                  België
                </option>
                <option value="NL">
                  Nederland
                </option>
                <option value="DE">
                  Duitsland
                </option>
              </Input>
              <Label id="country">
                Land<span className="text-danger"> *</span>
              </Label>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup floating>
              <Input
                id="shipping"
                placeholder="Verzending"
                type="select"
                name="shipping"
                onChange={(ev)=>{setShipping(ev.target.value)}}
                invalid={shipping == "" && validate}
                value={shipping}
                autoComplete="shipping"
              >
                <option value="delivery">
                  Verzenden
                </option>
                <option value="pickup">
                  Ophalen (Witte Vlinderweg 4C, Wormerveer)
                </option>
              </Input>
              <Label id="country">
              Verzending<span className="text-danger"> *</span>
              </Label>
            </FormGroup>
          </Col>

          <Col sm={12}>
            <FormGroup floating>
              <Input
                id="country"
                placeholder="Ik wil*"
                type="select"
                name="country"
                onChange={typeChange}
                invalid={type == "" && validate}
                value={type}
              >
                {Object.keys(typeText).map((option)=><option key={option} value={option} disabled={option==0}>
                  {typeText[option]}
                </option>)}
              </Input>
              <Label id="country">
                Ik wil<span className="text-danger"> *</span>
              </Label>
            </FormGroup>
          </Col>
          <Col>
            <SlideDown transitionOnAppear={false} className={'my-dropdown-slidedown'} style={{zIndex:200}} closed={!slider2}>
              <Row>
                <Col sm={12}>
                  <SlideDown transitionOnAppear={slider2} className={'my-dropdown-slidedown'} style={{zIndex:200}} closed={!slider}>
                    <Row>
                      <Col sm={6}>
                        <FormGroup floating>
                          <Input
                            id="delivery"
                            placeholder="Het materiaal wordt aangeleverd door"
                            type="select"
                            name="delivery"
                            onChange={(ev)=>{
                              setDelivery(ev.target.value);
                              setDeliveryUserChanged(true)}}
                            invalid={delivery == "" && validate}
                            value={delivery}
                          >
                            <option value={"self"}>
                            Ik lever zelf het materiaal aan
                            </option>
                            <option value={"laserlokaal"}>
                            Het Laser Lokaal levert het materiaal (niet altijd mogelijk afhankelijk van hoe specifiek het materiaal is).
                            </option>
                          </Input>
                          <Label id="delivery">
                            Aanlevering van materiaal<span className="text-danger"> *</span>
                          </Label>
                        </FormGroup>
                      </Col>
                    <Col sm={6}>
                      <Typeahead
                        ref={TypeaheadRef}
                        onKeyDown={onKeyDown}
                        allowNew
                        newSelectionPrefix="Anders: "
                        onChange={(selected) => {
                          setMaterial(selected);
                          if(selected.length != 0) {
                            if(deliveryUserChanged == false && selected[0].customOption != true) {
                              setDelivery("laserlokaal")
                            }
                            setMaterialText(selected[0].label)
                          }
                          
                        }}
                        onInputChange={(selected) => {
                          setMaterialText(selected)
                        }}
                        emptyLabel={materialLoadError?"Kan materialen niet ophalen":"Geen resultaten gevonden"}
                        selected={material}
                        filterBy={filterBy}
                        id="material"
                        options={options}
                        paginate={false}
                        isLoading={isLoading}
                        placeholder="Materiaal"
                        
                        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                          return (
                            <Hint className="mb-3">
                              <div className="w-100 form-floating">
                                <Input
                                  invalid={materialText == "" && validate && type === "sheet"}
                                  {...inputProps}
                                  required={type === "sheet"}
                                  innerRef={(node) => {
                                    inputRef(node);
                                    referenceElementRef(node);
                                  }}
                                />
                                <Label id="country">
                                  Materiaal en dikte<span className="text-danger"> *</span>
                                </Label>
                              </div>
                            </Hint>
                          );
                        }}
                        renderMenuItemChildren={(option,{text}) => (
                          <>
                            <img
                              alt={option.label}
                              src={option.img}
                              style={{
                                height: '80px',
                                marginRight: '10px',
                                width: '80px',
                              }}
                            />
                            <Highlighter search={text}>{option.label}</Highlighter>
                          </>
                        )}>
                          {({ activeIndex }) => (
                          <ActiveIndexWatcher update={() => setActiveIndex(activeIndex)} />
                        )}
                        </Typeahead>
                      </Col>
                      <Col sm={12}>
                        <p className="form-text text-muted">Wij snijden geen metalen (RVS, Aluminium, Staal etc.) en materialen die gemaakt zijn van PVC</p>
                      </Col>
                    </Row>
                  </SlideDown>
                </Col>
                <Col sm="12">
                  <p>Om een goed beeld te krijgen van uw opdracht, vragen wij om deze zo goed mogelijk te omschrijven. Denk hierbij aan aantal, vorm, afmetingen, materiaal, positie en afmeting van gravure etc. {type === "object"?<span className="text-danger">Let op! Wij kunnen geen ronde/bolle objecten graveren.</span>:null}</p>
                </Col>
                <InputTextField type="textarea" size="12" value={description} setter={setDescription} id="description" label="Omschrijving" validate={validate}/>
                <Col md="12">
                  <FormGroup>
                    <Label for="files">
                      Gebruik de onderstaande knop om je ontwerp te uploaden. 
                    </Label>
                    <Input
                      id="Files"
                      name="files"
                      type="file"
                      multiple
                      onChange={handleFileChange}
                    />
                    <FormText>
                      Toegestane bestandstypen: .AI, .PDF, .SVG, .EPS, .DXF, .JPG, .PNG, .PSD en .ZIP, maximaal 5 bestanden met een maximumgroote van 20MB per bestand.
                    </FormText>
                  </FormGroup>
                </Col>
                
              </Row>
            </SlideDown>
          </Col>
          <Col sm="12">
            <Alert color="danger" isOpen={error} toggle={()=>setError(false)}>
              <p className="m-0" style={{whiteSpace:"pre"}}>{errorMsg}</p>
            </Alert>
            <Button color="primary" onClick={SubmitQuote} disabled={submitting || submitted || !slider2} outline>{!submitting?"Versturen":(<><FontAwesomeIcon className="me-2" icon={faCircleNotch} spin/> {progress < 100?`Bestanden uploaden (${progress.toFixed(0)}%)`:"Aan het laden" }</>)}</Button>
          </Col>
        </Row>
      </Form>
    </FadeInOut>
  </div>
}

export default QuoteEmbed