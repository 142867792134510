import React, { Component } from "react";
import { connect } from "react-redux";

import { Input, Label, Row, Col} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVectorSquare } from '@fortawesome/free-solid-svg-icons'
import { faImage } from '@fortawesome/free-regular-svg-icons'


import { showIgnored } from "../../../store/design";

function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

class Ignored extends Component {
  state = {
    delete:false
  }
  componentWillUnmount() {
    if(this.props.showIgnored!=undefined){
      this.props.checkbox(false)
    }

  }
  render (){
    if(this.props.ignored.fills.length==0 && this.props.ignored.images.length==0 && this.props.ignored.text.length==0){
      return ""
    }
    return (
      <React.Fragment>
        <h6 className="mt-2 text-danger">Niet-herkende elementen in het ontwerp</h6>
        <Row className="mt-2">
          {this.props.ignored.fills.length!=0?<React.Fragment>
            <Col xs={1}>
              <FontAwesomeIcon className="text-danger" icon={faVectorSquare}/>
            </Col>
            <Col xs={11}>
              {this.props.ignored.fills.length} Vector lijn{this.props.ignored.fills.length>1?"en":""} zonder kleur. Alle lijnen dienen een kleur te hebben. <a href="https://laserlokaal.nl/pages/tekening-uploaden-bestellen" target="_blank">Meer informatie</a>.
            </Col>
          </React.Fragment>:""}
          {this.props.ignored.images.length!=0?<React.Fragment>
            <Col xs={1}>
              <FontAwesomeIcon className="text-danger" icon={faImage}/>
            </Col>
            <Col xs={11}>
              {this.props.ignored.images.length} Afbeelding{this.props.ignored.images.length>1?"en":""}. Helaas kunnen wij alleen vectoren herkennen, pixel gebaseerde afbeeldingen kunnen niet ingelezen worden.
            </Col>
          </React.Fragment>:""}
          {this.props.ignored.text.length!=0?<React.Fragment>
            <Col xs={1}>
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 190 190" className="svg-inline--fa fa-image fa-w-16 text-danger" xmlSpace="preserve">
              <path id="" fill="currentcolor" d="M175,0H15C6.716,0,0,6.716,0,15v38.99c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15V30h50v130H56.836
              c-8.284,0-15,6.716-15,15s6.716,15,15,15h76.328c8.284,0,15-6.716,15-15s-6.716-15-15-15H110V30h50v23.99c0,8.284,6.716,15,15,15
              c8.284,0,15-6.716,15-15V15C190,6.716,183.284,0,175,0z"/></svg>
            </Col>
            <Col xs={11}>
              {this.props.ignored.text.length} Text element{this.props.ignored.text.length>1?"en":""}. Alle tekst elementen dienen omgezet te worden naar vector lijnen. <a href="https://laserlokaal.nl/pages/tekening-uploaden-bestellen" target="_blank">Meer informatie</a>.
            </Col>
          </React.Fragment>:""}
        </Row>
        {this.props.showIgnored!=undefined?<div className="form-check form-switch mx-auto">
          <Input type="checkbox" id={"showIgnored"} data-attr="raster" onChange={(ev)=>{this.props.checkbox(ev.target.checked)}} checked={this.props.showIgnored}/>
          <Label className="form-check-label"for={"showIgnored"}>Niet-herkende elementen weergeven</Label>
        </div>:""}

      </React.Fragment>
    )
  }
}


export default (Ignored);
// export default DesignsMenu
