import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Col, Button,
Card, CardBody, CardTitle, CardSubtitle,
Label, Input, CardImg, Progress, UncontrolledTooltip, ButtonGroup } from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { updateMaterial, updateExtra } from "../../../api";

function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

function foilText({top,bot}){
  if(top == 1 && bot == 1){
   return "Beschermfolie beide kanten"
  } else if(top == 1 && bot == 2){
    return "Bescherm- en dubbelzijdige plakfolie"
  } else if (bot == 2){
    return "Dubbelzijdige plakfolie"
  } else if(top == 1){
    return "Beschermfolie boven"
  } else if(bot == 1){
    return "Beschermfolie onder"
  }
  return -1;
}


class MaterialOptions extends Component {
  state = {
    design:this.props.design
  }
  updateMaterial = (material, thickness) => {
    updateMaterial({material, thickness})
  }
  updateExtra = (top,bot) => {
    updateExtra({top,bot})
  }
  render (){
    const design = this.props.design
    const material = this.props.materials.find((el)=>{return el.name == design.material.material})
    var text = foilText(design.extra)
    return(
      <React.Fragment>
        <div className="mx-3 my-3">
          <div className="d-flex flex-col mb-2">
            <div className="flex-grow-1">
              <div className="h-100 d-flex flex-column align-bottom">
                <h4>{material.name}</h4>
                <p className="text-muted mb-1">Dikte: {design.material.thickness} mm </p>
                <p className="text-muted mb-1">Materiaal prijs: &euro; {roundTo(design.material.materialPrice*(this.props.tax?1.21:1),2)}</p>
                {text!=-1?
                  <p className="text-muted mb-1">{text}: &euro; {roundTo(design.price.additional || 0,2)}</p>
                :""}
              </div>
            </div>
            <div className="flex-grow-0" style={{maxWidth:"200px", width:"50%"}}>
              <div className="rounded-start ratio ratio-1x1 bgcontain" style={{maxWidth:"200px"}}>
                <picture>
                  <source srcSet={`${process.env.REACT_APP_API}${material.image.slice(0,material.image.lastIndexOf("."))+".webp@500"}`} type="image/webp"/>
                  <source srcSet={`${process.env.REACT_APP_API}${material.image.slice(0,material.image.lastIndexOf("."))+".png@500"}`} type="image/png"/>
                  <source srcSet={`${process.env.REACT_APP_API}${material.image}`} type="image/png"/>
                  <img src={`${process.env.REACT_APP_API}${material.image}`} alt="" width="100%"/>
                </picture>
              </div>
            </div>
          </div>
        <h5>Materiaal opties</h5>
        <Row>
          <Col xs={12}>
            <h6>Dikte</h6>
            <div className="mb-3">
              <ButtonGroup>
              {material.thicknesses.map((thickness,i)=>{
                return (
                  <Button key={i} color="primary" disabled={thickness.paused?true:false} active={thickness.thickness == design.material.thickness} onClick={()=>{this.updateMaterial(design.material.material, thickness.thickness)}} outline size="sm">{thickness.thickness} mm</Button>
                )
              })}
              </ButtonGroup>
            </div>

            <h6>Bovenkant</h6>
            <Row className="mb-2">
              <Col xs={3}>
                <Input
                  name="top"
                  id="top-none"
                  type="radio"
                  checked={(design.extra.top == 0)}
                  onChange={()=>{this.updateExtra(0, undefined)}}
                />
                <Label className="ms-2" for="top-none">Geen</Label>
              </Col>
              <Col xs={4}>
                <Input
                  name="top"
                  id="top-foil"
                  type="radio"
                  disabled={!material.extra.top}
                  checked={(design.extra.top == 1)}
                  onChange={()=>{this.updateExtra(1, undefined)}}
                />
                <Label className="ms-2" for="top-foil">
                  Beschermfolie <FontAwesomeIcon id={"protective_film_1"} className="ms-1 text-info" icon={faInfoCircle}/>
                  <UncontrolledTooltip target={"protective_film_1"}>{!material.extra.top?"Beschermfolie is niet mogelijk/materiaal heeft al beschermfolie.":"Dunne papierfolie aangebracht op het oppervlak om residu vlekken op het materiaal te voorkomen."}</UncontrolledTooltip>
                </Label>

              </Col>
            </Row>

            <h6>Onderkant</h6>
            <Row className="mb-2">
              <Col xs={3}>
                <Input
                  name="bot"
                  id="bot-none"
                  type="radio"
                  checked={(design.extra.bot == 0)}
                  onChange={()=>{this.updateExtra(undefined, 0)}}
                />
                <Label className="ms-2" for="bot-none">Geen</Label>
              </Col>
              <Col xs={4}>
                <Input
                  name="bot"
                  id="bot-foil"
                  type="radio"
                  disabled={!material.extra.bot}
                  checked={(design.extra.bot == 1)}
                  onChange={()=>{this.updateExtra(undefined, 1)}}
                />
                <Label className="ms-2" for="bot-foil">Beschermfolie <FontAwesomeIcon id={"protective_film_1"} className="ms-1 text-info" icon={faInfoCircle}/>
                  <UncontrolledTooltip target={"protective_film_1"}>{!material.extra.bot?"Beschermfolie is niet mogelijk/materiaal heeft al beschermfolie.":"Dunne papierfolie aangebracht op het oppervlak om residu vlekken op het materiaal te voorkomen."}</UncontrolledTooltip>
                </Label>
              </Col>
              <Col xs={5}>
                <Input
                  name="bot"
                  id="bot-tape"
                  type="radio"
                  disabled={!material.extra.double}
                  checked={(design.extra.bot == 2)}
                  onChange={()=>{this.updateExtra(undefined, 2)}}
                />
                <Label className="ms-2" for="bot-tape">Dubbelzijdige plakfolie <FontAwesomeIcon id={"adhesive_film"} className="ms-1 text-info" icon={faInfoCircle}/>
                  <UncontrolledTooltip target={"adhesive_film"}>{!material.extra.double?"Plakfolie is niet mogelijk voor dit materiaal.":"Dubbelzijdige plakfolie is een hele dunne (0,05mm) folie die kan worden aangebracht op de achterzijde van het materiaal zodat onderdelen na het lasersnijden op een oppervlak opgeplakt kunnen worden. Dubbelzijdige plakfolie dient ook als beschermfolie voor de achterzijde van het materiaal."} </UncontrolledTooltip></Label>
              </Col>
            </Row>
          </Col>
        </Row>
        </div>
      </React.Fragment>
    )
  }
}
function mapStateToProps(state) {
  const design = state.design;
  const materials = state.materials;
  const tax = state.settings.tax;
  return {
    design,
    tax,
    ...materials
  };
}


export default connect(mapStateToProps)(MaterialOptions);
