import React, { Component } from "react";
import { connect } from "react-redux";

import { updateQuantity } from "../../../store/design";

import { Input, InputGroup, Button } from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'


import { updateQty } from "../../../api";

function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

class Quantity extends Component {
  state = {
    qty:1
  }
  increase = () => {
    //this.props.dispatch(updateQuantity(this.props.qty+1))
    updateQty(this.props.qty+1)
  }
  decrease = () => {
    if(this.props.qty-1 < 1)
      return

    //this.props.dispatch(updateQuantity(this.props.qty-1))\
    updateQty(this.props.qty-1)
  }
  update = (ev) => {
    var val = parseInt(ev.target.value)
    if(val < 1)
      return

    if(isNaN(val)){
      this.setState({qty:""})
      return
    }

    //this.props.dispatch(updateQuantity(val))
    updateQty(val)
  }

  validate = (ev) => {
    var val = parseInt(ev.target.value)
    if(isNaN(val)){
      // this.props.dispatch(updateQuantity(1))
      updateQty(1)
      return
    } else if (val < 1) {
      // this.props.dispatch(updateQuantity(1))
      updateQty(1)
    }
  }
  render (){
    return (
      <React.Fragment>
      <div  className="d-inline-block">
        <h6 className="mt-2">Aantal</h6>
        <InputGroup style={{width:"150px"}}>
          <Button disabled={this.props.qty<=1} color="danger" outline onClick={this.decrease}>
            <FontAwesomeIcon icon={faMinus}/>
          </Button>
          <Input placeholder="" onBlur={this.validate} onChange={this.update} value={this.props.qty}/>
          <Button color="success" outline onClick={this.increase}>
            <FontAwesomeIcon icon={faPlus}/>
          </Button>
        </InputGroup>

      </div>
      {this.props.price.discount.discount>0?<React.Fragment><span className="ms-3"><b>{this.props.price.discount.discount}%</b> Productie korting</span></React.Fragment>:""}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const {qty, price} = state.design;
  return {
    qty,price
  };
}

export default connect(mapStateToProps)(Quantity);
// export default DesignsMenu
