import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  InputGroup,
  CardImg,
  UncontrolledTooltip,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faTruck,
  faInfoCircle,
  faSortAmountUp,
  faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons";
import { sort } from "../../../store/settings";
import { updateMaterial } from "../../../api";

const fastDeliveryDisabled = false; // Disables fast delivery (also disable in backend and checkout)

function roundTo(value, decimals) {
  return Number(
    Math.round(+value.toFixed(11) + "e" + decimals) + "e-" + decimals
  ).toFixed(2);
}

function compare(a, b) {
  if (a.X * a.Y < b.X * b.Y) {
    return -1;
  }
  if (a.X * a.Y > b.X * b.Y) {
    return 1;
  }
  return 0;
}

class Materials extends Component {
  state = {
    materials: this.props.materials,
    searchTerm: "",
    sort: false,
    categories: false,
    categoryList: [],
    thicknesses: false,
    thicknessList: [],
  };
  componentDidMount() {
    var categories = this.props.materials.map((item) => item.category);
    this.setState({
      categoryList: this.props.categories.map((el) => {
        return { ...el, checked: false };
      }),
      thicknessList: this.props.thicknesses.map((el) => {
        return { ...el, checked: false };
      }),
    });
  }

  updateSortList = (what, searchTerm) => {
    if (what == 1) {
      var categoryList = this.state.categoryList;
      var thicknesses = this.state.thicknessList
        .filter((el) => el.checked == true)
        .map((el) => el.thickness);
      if (thicknesses.length != 0) {
        var categories = [
          ...this.props.materials
            .filter((el) => {
              return (
                el.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                el.category.toLowerCase().includes(searchTerm.toLowerCase())
              );
            })
            .filter((item) => {
              return (
                item.thicknesses.findIndex((el) =>
                  thicknesses.includes(el.thickness)
                ) != -1
              );
            })
            .map((item) => item.category),
        ];

        for (var i in categoryList) {
          var cat = categoryList[i].category;
          var occurences = categories.filter((el) => el == cat).length;
          categoryList[i].count = occurences;
        }
      } else {
        var categories = [
          ...this.props.materials
            .filter((el) => {
              return (
                el.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                el.category.toLowerCase().includes(searchTerm.toLowerCase())
              );
            })
            .map((item) => item.category),
        ];

        for (var i in categoryList) {
          var cat = categoryList[i].category;
          var occurences = categories.filter((el) => el == cat).length;
          categoryList[i].count = occurences;
        }
      }
      this.setState({ categoryList: categoryList });
    } else if (what == 2) {
      var thicknessList = this.state.thicknessList;
      var categories = this.state.categoryList
        .filter((el) => el.checked == true)
        .map((el) => el.category);
      if (categories.length != 0) {
        var thicknesses = [
          ...this.props.materials
            .filter((el) => {
              return (
                el.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                el.category.toLowerCase().includes(searchTerm.toLowerCase())
              );
            })
            .filter((item) => {
              return categories.includes(item.category);
            })
            .reduce((acc, item) => {
              return [...acc, ...item.thicknesses];
            }, []),
        ].map((el) => el.thickness);
        for (var i in thicknessList) {
          var thick = thicknessList[i].thickness;
          var occurences = thicknesses.filter((el) => el == thick).length;
          thicknessList[i].count = occurences;
        }
      } else {
        var thicknesses = [
          ...this.props.materials
            .filter((el) => {
              return (
                el.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                el.category.toLowerCase().includes(searchTerm.toLowerCase())
              );
            })
            .reduce((acc, item) => {
              return [...acc, ...item.thicknesses];
            }, []),
        ].map((el) => el.thickness);
        for (var i in thicknessList) {
          var thick = thicknessList[i].thickness;
          var occurences = thicknesses.filter((el) => el == thick).length;
          thicknessList[i].count = occurences;
        }
      }
      this.setState({ thicknessList: thicknessList });
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.design.material != null &&
      (prevProps.design.material == null ||
        this.props.design.material.material !=
          prevProps.design.material.material)
    ) {
      document.getElementById("optionsPane").scroll(0, 0);
    }
  }
  updateMaterial = (material, thickness) => {
    updateMaterial({ material, thickness });
  };

  sortIcon = (name) => {
    const { direction, sortBy } = this.props.settings.material;
    if (sortBy == name) {
      return (
        <span className="float-end pe-none">
          <FontAwesomeIcon
            icon={direction == "asc" ? faSortAmountUp : faSortAmountDown}
          />
        </span>
      );
    } else {
      return null;
    }
  };

  sort = (ev) => {
    this.props.dispatch(sort(ev.target.name));
  };

  thicknessFilter = (checked, i) => {
    var thicknesses = this.state.thicknessList;
    if (i == "reset") {
      for (var j = 0; j < thicknesses.length; j++) {
        thicknesses[j].checked = false;
      }
    } else {
      thicknesses[i].checked = checked;
    }
    this.setState({ thicknessList: thicknesses });
    this.updateSortList(1, this.state.searchTerm);
  };

  categoryFilter = (checked, i) => {
    var categories = this.state.categoryList;
    if (i == "reset") {
      for (var j = 0; j < categories.length; j++) {
        categories[j].checked = false;
      }
    } else {
      categories[i].checked = checked;
    }
    this.setState({ categoryList: categories });
    this.updateSortList(2, this.state.searchTerm);
  };

  render() {
    const materials = [...this.props.materials]
      .map((material, i) => {
        const prices = material.thicknesses.map((el) => {
          var size = el.sizes.find((size) => {
            return (
              (size.X >=
                this.props.design.dimensions.x +
                  2 * this.props.design.dimensions.offset &&
                size.Y >=
                  this.props.design.dimensions.y +
                    2 * this.props.design.dimensions.offset) ||
              (size.X >=
                this.props.design.dimensions.y +
                  2 * this.props.design.dimensions.offset &&
                size.Y >=
                  this.props.design.dimensions.x +
                    2 * this.props.design.dimensions.offset)
            );
          });

          if (size == undefined) {
            size = el.sizes.find((size) => {
              return (
                (size.X >= this.props.design.dimensions.x &&
                  size.Y >= this.props.design.dimensions.y) ||
                (size.X >= this.props.design.dimensions.y &&
                  size.Y >= this.props.design.dimensions.x)
              );
            });
            if (size == undefined) {
              return -2;
            } else {
              return -1;
            }
          } else {
            return parseFloat(size.price);
          }
        });

        return {
          name: material.name,
          image: material.image,
          delivery: parseInt(material.delivery),
          category: material.category,
          thicknesses: material.thicknesses,
          prices: prices,
          sizes: material.thicknesses[0].sizes,
        };
      })
      .filter((el) => {
        return (
          el.name.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
          el.category
            .toLowerCase()
            .includes(this.state.searchTerm.toLowerCase())
        );
      });

    const { direction, sortBy } = this.props.settings.material;
    return (
      <React.Fragment>
        <div
          className="mx-3 pt-2 pb-2 bg-white"
          style={{ position: "sticky", top: 0, zIndex: 10 }}
        >
          <Label for="search">Search</Label>
          <InputGroup>
            <Input
              id="search"
              type="text"
              value={this.state.searchTerm}
              onChange={(ev) => {
                this.setState({ searchTerm: ev.target.value });
                this.updateSortList(1, ev.target.value);
                this.updateSortList(2, ev.target.value);
              }}
            />
            <ButtonDropdown
              isOpen={this.state.sort}
              toggle={(e) => {
                if (![...e.target.classList].includes("dropdown-item")) {
                  this.setState({ sort: !this.state.sort });
                }
              }}
            >
              <DropdownToggle
                color="primary"
                active={this.state.sort}
                outline
                caret
              >
                Sorteren
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem name="name" onClick={this.sort}>
                  Naam {this.sortIcon("name")}
                </DropdownItem>
                <DropdownItem name="price" onClick={this.sort}>
                  Prijs {this.sortIcon("price")}
                </DropdownItem>
                <DropdownItem name="delivery" onClick={this.sort}>
                  Levering {this.sortIcon("delivery")}
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>

            <ButtonDropdown
              isOpen={this.state.categories}
              toggle={(e) => {
                if (![...e.target.classList].includes("dropdown-item")) {
                  this.setState({ categories: !this.state.categories });
                }
              }}
            >
              <DropdownToggle
                color="primary"
                active={this.state.categories}
                outline
                caret
              >
                Materiaal
              </DropdownToggle>
              <DropdownMenu end>
                {this.state.categoryList.map((item, i) => {
                  return (
                    <div key={i} className="ms-3">
                      <Input
                        onChange={(e) =>
                          this.categoryFilter(e.target.checked, i)
                        }
                        checked={item.checked}
                        id={"category" + i}
                        type="checkbox"
                      />{" "}
                      <Label check for={"category" + i} className="mb-0">
                        {item.category}
                      </Label>
                      <span className="float-end me-3">({item.count})</span>
                    </div>
                  );
                })}
                <DropdownItem divider />
                <DropdownItem
                  onClick={(e) => this.categoryFilter(false, "reset")}
                  className="text-center"
                >
                  Reset filter
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>

            <ButtonDropdown
              isOpen={this.state.thicknesses}
              toggle={(e) => {
                if (![...e.target.classList].includes("dropdown-item")) {
                  this.setState({ thicknesses: !this.state.thicknesses });
                }
              }}
              end="true"
            >
              <DropdownToggle
                color="primary"
                active={this.state.thicknesses}
                outline
                caret
              >
                Dikte
              </DropdownToggle>
              <DropdownMenu end>
                {this.state.thicknessList.map((item, i) => {
                  return (
                    <div key={i} className="ms-3">
                      <Input
                        onChange={(e) =>
                          this.thicknessFilter(e.target.checked, i)
                        }
                        checked={item.checked}
                        id={"thickness" + i}
                        type="checkbox"
                      />{" "}
                      <Label check for={"thickness" + i} className="mb-0">
                        {item.thickness}
                      </Label>
                      <span className="float-end me-3">({item.count})</span>
                    </div>
                  );
                })}
                <DropdownItem divider />
                <DropdownItem
                  className="text-center"
                  onClick={(e) => this.thicknessFilter(false, "reset")}
                >
                  Reset filter
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </InputGroup>
        </div>
        <div className="mx-3 mb-3">
          <Row>
            {materials
              .filter((item) => {
                var thicknesses = this.state.thicknessList
                  .filter((el) => el.checked == true)
                  .map((el) => el.thickness);
                var thicknessExists = 1;
                if (thicknesses.length != 0) {
                  thicknessExists =
                    item.thicknesses.findIndex((el) =>
                      thicknesses.includes(el.thickness)
                    ) != -1;
                }

                var categories = this.state.categoryList
                  .filter((el) => el.checked == true)
                  .map((el) => el.category);
                var categoryExists = 1;
                if (categories.length != 0) {
                  categoryExists = categories.includes(item.category);
                }

                return categoryExists && thicknessExists;
              })
              .sort((a, b) => {
                if ("name" == sortBy) {
                  if (a[sortBy] < b[sortBy]) {
                    return direction == "asc" ? -1 : 1;
                  }
                  if (a[sortBy] > b[sortBy]) {
                    return direction == "asc" ? 1 : -1;
                  }
                } else if (sortBy == "price") {
                  if (direction == "desc") {
                    return Math.max(...b.prices) - Math.max(...a.prices);
                  } else {
                    return Math.min(...a.prices) - Math.min(...b.prices);
                  }
                } else if (sortBy == "delivery") {
                  if (a[sortBy] < b[sortBy]) {
                    return direction == "desc" ? -1 : 1;
                  }
                  if (a[sortBy] > b[sortBy]) {
                    return direction == "desc" ? 1 : -1;
                  }
                }
                return 0;
              })
              .sort((a, b) => {
                if (a.prices[0] == -2) return 1;
                else {
                  return -1;
                }
                return 0;
              })
              .sort((a, b) => {
                if (
                  this.props.design.material != null &&
                  this.props.design.material.material == a.name
                ) {
                  return -1;
                }
                return 0;
              })
              .map((material, i) => {
                const priceRange = [
                  Math.min(...material.prices),
                  Math.max(...material.prices),
                ];

                const paused = material.thicknesses.map((el) => {
                  return el.paused;
                });
                const fastDelivery =
                  material.delivery == 0 && !fastDeliveryDisabled;
                const oos =
                  Math.max(...paused) == 1
                    ? Math.min(...paused) == 1
                      ? 1
                      : 2
                    : 0;
                return (
                  <Col
                    xs="6"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6"
                    xxl="4"
                    key={i}
                    className="my-3"
                  >
                    <Card>
                      <CardBody className="p-0">
                        <Row>
                          <Col sm="6" className="">
                            {/* <div className="border-bottom rounded-start ratio ratio-1x1 bgcontain" style={{backgroundImage:`url(https://app.laserlokaal.nl${material.image})`}}>
                      // </div>*/}
                            <div className="rounded-start ratio ratio-1x1 bgcontain">
                              <picture>
                                <source
                                  srcSet={`${process.env.REACT_APP_API}${
                                    material.image.slice(
                                      0,
                                      material.image.lastIndexOf(".")
                                    ) + ".webp@500"
                                  }`}
                                  type="image/webp"
                                />
                                <source
                                  srcSet={`${process.env.REACT_APP_API}${
                                    material.image.slice(
                                      0,
                                      material.image.lastIndexOf(".")
                                    ) + ".png@500"
                                  }`}
                                  type="image/png"
                                />
                                <source
                                  srcSet={`${process.env.REACT_APP_API}${material.image}`}
                                  type="image/png"
                                />
                                <img
                                  src={`${process.env.REACT_APP_API}${material.image}`}
                                  alt=""
                                  width="100%"
                                />
                              </picture>
                            </div>
                            {/*} <img />*/}
                          </Col>
                          <Col sm="6" className="">
                            <div className="p-2 h-100 ps-1 d-flex flex-column text-xxl-start align-bottom">
                              <CardTitle tag="h6" className="pe-5">
                                {material.name}
                              </CardTitle>
                              <p className="text-muted mb-0">
                                {priceRange[0] < 0 ? (
                                  priceRange[0] == -1 ? (
                                    <React.Fragment>
                                      <p className="text-danger mb-1">
                                        Ontwerp buiten veiligheidsmarge{" "}
                                        <FontAwesomeIcon
                                          id={"tooLarge_" + i}
                                          className="ms-1 text-info"
                                          icon={faInfoCircle}
                                        />
                                      </p>

                                      <UncontrolledTooltip
                                        placement="auto"
                                        container={".tooltipContainer"}
                                        target={"tooLarge_" + i}
                                      >
                                        In verband met de plaatrand hanteren we
                                        een veiligheidsmarge van{" "}
                                        {this.props.design.dimensions.offset}cm
                                        aan elke zijde van het ontwerp. Je
                                        ontwerp mag maximaal{" "}
                                        {material.sizes[
                                          material.sizes.length - 1
                                        ].X -
                                          2 *
                                            this.props.design.dimensions.offset}
                                        x
                                        {material.sizes[
                                          material.sizes.length - 1
                                        ].Y -
                                          2 *
                                            this.props.design.dimensions.offset}
                                        cm zijn voor dit materiaal. Op aanvraag
                                        kunnen wij grotere platen bewerken vraag
                                        hiervoor een offerte aan.
                                      </UncontrolledTooltip>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <p className="text-danger mb-1">
                                        Ontwerp te groot{" "}
                                        <FontAwesomeIcon
                                          id={"tooLarge_" + i}
                                          className="ms-1 text-info"
                                          icon={faInfoCircle}
                                        />
                                      </p>

                                      <UncontrolledTooltip
                                        placement="auto"
                                        container={".tooltipContainer"}
                                        target={"tooLarge_" + i}
                                      >
                                        Het maximale formaat is{" "}
                                        {
                                          material.sizes[
                                            material.sizes.length - 1
                                          ].X
                                        }
                                        x
                                        {
                                          material.sizes[
                                            material.sizes.length - 1
                                          ].Y
                                        }
                                        cm (
                                        {material.sizes[
                                          material.sizes.length - 1
                                        ].X -
                                          2 *
                                            this.props.design.dimensions.offset}
                                        x
                                        {material.sizes[
                                          material.sizes.length - 1
                                        ].Y -
                                          2 *
                                            this.props.design.dimensions.offset}
                                        cm met veiligheidsmarge). Op aanvraag
                                        kunnen wij grotere platen bewerken vraag
                                        hiervoor een offerte aan.
                                      </UncontrolledTooltip>
                                    </React.Fragment>
                                  )
                                ) : priceRange[0] == priceRange[1] ? (
                                  <React.Fragment>
                                    &euro;
                                    {roundTo(
                                      priceRange[0] *
                                        (this.props.settings.tax ? 1.21 : 1),
                                      2
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    &euro;
                                    {roundTo(
                                      priceRange[0] *
                                        (this.props.settings.tax ? 1.21 : 1),
                                      2
                                    )}{" "}
                                    - &euro;
                                    {roundTo(
                                      priceRange[1] *
                                        (this.props.settings.tax ? 1.21 : 1),
                                      2
                                    )}
                                  </React.Fragment>
                                )}
                              </p>
                              <p className="text-muted flex-grow-1 mb-0">
                                {material.thicknesses.map(
                                  (thickness, i, thicknesses) => {
                                    return (
                                      <React.Fragment key={i}>
                                        {thickness.paused ? (
                                          <del className="text-gray">
                                            {thickness.thickness}
                                          </del>
                                        ) : (
                                          <span>{thickness.thickness}</span>
                                        )}

                                        {i + 1 === thicknesses.length
                                          ? ""
                                          : "-"}
                                      </React.Fragment>
                                    );
                                  }
                                )}{" "}
                                mm
                                {oos == 1 ? (
                                  <React.Fragment>
                                    <FontAwesomeIcon
                                      id={"outOfStock_" + i}
                                      className="ms-1 text-info"
                                      icon={faInfoCircle}
                                    />
                                    <UncontrolledTooltip
                                      placement="auto"
                                      container={".tooltipContainer"}
                                      target={"outOfStock_" + i}
                                    >
                                      Dit materiaal is momenteel helaas niet
                                      leverbaar.
                                    </UncontrolledTooltip>
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                                {oos == 2 ? (
                                  <React.Fragment>
                                    <FontAwesomeIcon
                                      id={"outOfStock_" + i}
                                      className="ms-1 text-info"
                                      icon={faInfoCircle}
                                    />
                                    <UncontrolledTooltip
                                      placement="auto"
                                      container={".tooltipContainer"}
                                      target={"outOfStock_" + i}
                                    >
                                      Een aantal dikten van dit materiaal zijn
                                      momenteel helaas niet leverbaar.
                                    </UncontrolledTooltip>
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </p>
                              <div className="text-end">
                                <span
                                  className={
                                    "me-1 " +
                                    (material.delivery
                                      ? "text-muted"
                                      : "text-muted")
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="me-1 text-info"
                                    icon={faTruck}
                                    id={"delivery_" + i}
                                  />
                                  {fastDelivery ? "1-2" : "4-5"}
                                  <UncontrolledTooltip
                                    placement="auto"
                                    container={".tooltipContainer"}
                                    target={"delivery_" + i}
                                  >
                                    {fastDelivery
                                      ? "Dit materiaal is op vooraad, Waardoor wij voor dit materiaal een snelle productie (1-2 werkdagen) kunnen bieden."
                                      : "Doordat we dit materiaal niet standaard op voorraad hebben is de productietijd 4-5 werkdagen."}
                                  </UncontrolledTooltip>
                                </span>
                                {oos == 1 || priceRange[0] < 0 ? (
                                  <>
                                    <Button
                                      tag={"a"}
                                      outline
                                      href="https://laserlokaal.nl/pages/offerte-aanvragen-1"
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      color="primary"
                                    >
                                      Offerte{" "}
                                      <FontAwesomeIcon
                                        className="me-1"
                                        icon={faInfoCircle}
                                        id={"quote_" + i}
                                      />
                                    </Button>
                                    <div id={"test_" + i}></div>
                                    <UncontrolledTooltip
                                      placement="auto"
                                      container={".tooltipContainer"}
                                      target={"quote_" + i}
                                    >
                                      Als je je ontwerp kan aanpassen zodat deze
                                      binnen de maximale formaten van dit
                                      materiaal valt kan je direct via het
                                      portal bestellen. Op aanvraag kunnen we
                                      grotere platen snijden, vraag hiervoor een
                                      offerte aan.
                                    </UncontrolledTooltip>
                                  </>
                                ) : (
                                  <Button
                                    outline
                                    active={
                                      this.props.design.material != null &&
                                      this.props.design.material.material ==
                                        material.name
                                    }
                                    color={oos == 1 ? "dark" : "primary"}
                                    onClick={() => {
                                      this.updateMaterial(
                                        material.name,
                                        material.thicknesses.filter(
                                          (thickness) => thickness.paused == 0
                                        )[0].thickness
                                      );
                                    }}
                                  >
                                    Kies
                                  </Button>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      {/*<div className="ribbon rounded-bottom border-1 border mx-3 px-2 py-1 border-info bg-white"><FontAwesomeIcon className="text-info" icon={faInfo}/></div>*/}
                    </Card>
                  </Col>
                );
              })}
          </Row>
          <div className="tooltipContainer"></div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  const materials = state.materials;
  const design = state.design;
  const settings = state.settings;
  return {
    ...materials,
    design,
    settings,
  };
}

export default connect(mapStateToProps)(Materials);
