import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    data: {
      email: { value:"", required:true, valid:true },
      number: { value:"", required:true, valid:true },
      shipping: {
        firstname: { value:"", required:true, valid:true },
        lastname: { value:"", required:true, valid:true },
        company: { value:"", required:false, valid:true },
        address: { value:"", required:true, valid:true },
        zipcode: { value:"", required:true, valid:true },
        city: { value:"", required:true, valid:true },
        country: { value:"NL", required:true, valid:true },
      },
      differentInvoiceAddress: false,
      isCompany: false,
      invoice: {
        firstname: { value:"", required:true, valid:true },
        lastname: { value:"", required:true, valid:true },
        vatNumber: { value:"", required:false, valid:true, disabled:false, checking:false},
        reference: { value:"", required:false, valid:true },
        company: { value:"", required:false, valid:true, disabled:false },
        address: { value:"", required:true, valid:true, disabled:false },
        zipcode: { value:"", required:true, valid:true, disabled:false },
        city: { value:"", required:true, valid:true, disabled:false },
        country: { value:"NL", required:true, valid:true, disabled:false },
      },
      terms: { value:false, required:true, valid:true },
      operations: { value:false, required:true, valid:true },

    },
    shipping:{
      production:{ value:"standard", required:true, valid:true },
      recycle: { value:"all", required:true, valid:true },
      shipping: { value:"ship", required:true, valid:true },
      comments:{ value:"", required:false, valid:true }
    },
    coupon:{
      code:null,
      discount:"",
      value:0
    },
    VATreverseCharge:false,
    checkoutData: null,
    page: 1,
    loading:false,
    valid:false
  },
  reducers: {
    checkoutData: (state, action) => {
      state.checkoutData = action.payload
      return state
    },
    setField: (state, action) => {
      const { field, type, group, value } = action.payload
      if(group != undefined){
        state[type][group][field].value = value
        state[type][group][field].valid = true
      } else {
        state[type][field].value = value
        state[type][field].valid = true
      }
      return state
    },
    setCompany: (state,action) => {
      const { checked } = action.payload
      if(checked){
        state.data.isCompany = true
        state.data.invoice.company.required = true
      } else {
        state.data.isCompany = false
        state.data.invoice.company.required = false
        state.data.invoice.company.valid = true
        state.data.invoice.vatNumber.value = ""
        Object.keys(state.data.invoice).map(key=>{
          if(state.data.invoice[key].disabled != undefined){
            state.data.invoice[key].disabled = false
          }
        })
      }
      return state
    } ,
    setDifferentInvoiceAddress: (state, action) => {
      const { checked } = action.payload
      if(checked){
        state.data.differentInvoiceAddress = true
      } else {
        state.data.differentInvoiceAddress = false
        Object.keys(state.data.shipping).map((key)=>{
          state.data.shipping[key] = {...state.data.shipping[key], valid:true}
        })
      }
      return state
    },
    setVat: (state, action) => {
      var data = action.payload
      Object.keys(data).map(key=>{
        state.data.invoice[key].value = data[key]
        state.data.invoice[key].disabled = true
        state.data.invoice[key].valid = true
      })
      if(data.country != "NL")
        state.VATreverseCharge = true
      return state
    },
    setEmptyVatNumber: (state,action) => {
      state.data.invoice.vatNumber.valid = false
      return state
    },
    resetVat: (state, action) => {
      Object.keys(state.data.invoice).map(key=>{
        if(state.data.invoice[key].disabled != undefined){
          state.data.invoice[key].disabled = false
        }
      })
      state.data.invoice.vatNumber.value = ""
      state.VATreverseCharge = false
      return state
    },
    setChecking: (state, action) => {
      state.data.invoice.vatNumber.checking = action.payload
      return state
    },
    validate: (state, action) => {
      const { type } = action.payload
      if(type=="data") {
        var allValid = true
        if(state.data.email.value == ""){
          allValid = false
          state.data.email.valid = false
        }
        if(state.data.number.value == ""){
          allValid = false
          state.data.number.valid = false
        }
        if(state.data.terms.value == false){
          allValid = false
          state.data.terms.valid = false
        }
        if(state.data.operations.value == false){
          allValid = false
          state.data.operations.valid = false
        }
        Object.keys(state.data.invoice).map((key)=>{
          var field = state.data.invoice[key]
          var valid = true
          if(field.value == "" && field.required){
            valid = false
            allValid = false
          }
          state.data.invoice[key] = {...field, valid:valid}
        })
        Object.keys(state.data.shipping).map((key)=>{
          var field = state.data.shipping[key]
          var valid = true
          if(field.value == "" && state.data.differentInvoiceAddress  && field.required){
            valid = false
            allValid = false
          }
          state.data.shipping[key] = {...field, valid:valid}
        })
        // toast.warning(
        //   <React.Fragment>
        //     <div className="toast-header p-0" style={{fontSize: "15px"}}>
        //       <strong className="me-auto p-0">BTW controleren</strong>
        //     </div>
        //     <div className="toast-body pt-1 p-0" style={{fontSize: "14px"}}>
        //       Druk op BTW controleren.
        //     </div>
        //   </React.Fragment>, {
        //   position: "bottom-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   className: "pe-auto"
        // });
        if(state.data.invoice.vatNumber.value != "" && state.data.invoice.vatNumber.disabled == false) {
          state.data.invoice.vatNumber.valid = false
        }

        if(allValid){
          state.page = 2;
          state.valid = true;
        }
        return state
      } else {
        state.page = 2
      }
    },
    back: (state)=>{
      state.page--
      if(state.page < 1)
        state.page = 1
      return
    },
    setCoupon: (state,action)=>{
      if (action.payload.valid == true) {
        state.coupon = {
          code: action.payload.code,
          discount: action.payload.discount,
          value: action.payload.value
        }
      } else {
        state.coupon = {
          code:null,
          discount:null,
          value:null
        }

      }
      state.loading = false
      return state
    },
    CouponLoading: (state, action) => {
      state.loading = action.payload
      return state
    },
    rstValidate: (state, action) => {
      const { field, group } = action.payload
      if(group != undefined){

      } else {

      }
    }
  },
})

// Action creators are generated for each case reducer function
export const {setField, setDifferentInvoiceAddress,setCompany, validate,back, setCoupon, CouponLoading, checkoutData, setChecking, setVat, resetVat, setEmptyVatNumber} = counterSlice.actions

export default counterSlice.reducer
