import React, { Component } from "react";

import img from "./loader.svg"

class Loader extends Component {
  render (){
    return(
      <div className="h-100 w-100 position-relative">
        <div className=" w-50 text-center position-absolute top-50 start-50 translate-middle">
          <img className="w-25 mx-auto d-block" src={img}/>
          <h5><b>{this.props.text}</b></h5>
        </div>
      </div>
    )
  }
}

export default Loader
