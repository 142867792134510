import React, { useEffect, useState, useCallback, useRef } from "react"
import { Col, Form, FormGroup, Input, Label, Row, FloatingLabel, FormText, Button, Alert } from "reactstrap"
import { Highlighter, Hint, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import SlideDown from "../../../components/SlideDown";
import errorImg from "../../../components//paper-plane-regular.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import FadeInOut from "../../Checkout/FadeInOut";
import "iframe-resizer/js/iframeResizer.contentWindow.js"
import GTM from "../../../gtm";
const testEmail = (email) => {
  let test = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  return test.test(email)
}
const InputTextField = ({type,size, label, id, value, setter, validate, required, autocomplete}) => {
  useEffect(()=>{
  },[value])

  const emailInValid = type=="email"?!testEmail(value):false
  return <Col sm={size}>
        <FormGroup floating>
          <Input
            id={id}
            placeholder={label}
            type={type?type:"text"}
            style={{height:type=="textarea"?"200px":undefined}}
            name={id}
            invalid={((value == "" || emailInValid) && validate) && required != false}
            required={required !== false}
            value={value}
            onChange={(ev)=>setter(ev.target.value)}
            autoComplete={autocomplete}
          />
          <Label for={id}>
          {label}{required != false && <span className="text-danger"> *</span>}
          </Label>
        </FormGroup>
      </Col>
}
const QuoteEmbed = () => {
  
  const fileRef = useRef(null)
  const [validate, setValidate] = useState(false)

  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [company, setCompany] = useState("")
  const [email, setEmail] = useState("")
  const [description, setDescription] = useState("")
  const [materialText,setMaterialText] = useState("")

  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  const [submitted, setSubmitted] = useState(false);

  const [files, setFiles] = useState(null);

  const [submitting, setSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);

  const SubmitQuote = () =>{
    let valid = true
    // company
    let values = [firstname,lastname,email,description]
    values.forEach((entry)=>{
      if(entry == ""){
        valid = false
        
      }
    })

    if(!testEmail(email))
      valid = false

    if(!valid){
      setValidate(true)
      return
    }
    setSubmitting(true)

    let data = {
      firstname,
      lastname,
      email,
      description,
      company,
    }

    let formdata = new FormData();
    if(files != null){
      for (const key of Object.keys(files)) {
        formdata.append('files', files[key])
      }
    }
    Object.keys(data).forEach((key)=>{
      formdata.append(key, data[key])
    })

    let request = new XMLHttpRequest();

    request.open('POST', process.env.REACT_APP_API+"/api/contact/contact", true);
    request.withCredentials = true;

    request.upload.addEventListener('progress', function(e) {
      let percent_completed = (e.loaded / e.total)*100;
      setProgress(percent_completed)
      // Upload Progress
    });

    request.addEventListener('load', function(e) {
      setSubmitting(false)
      let error = ""
      if(request.status >= 400 && request.status < 500) {
        let err
        try {
          err = JSON.parse(request.response);
        } catch (e) {
        }
        if(err.error){
          error = err.error
        } else {
          error = "Er is een fout opgetreden."
        }
      } else if(request.status >= 500 && request.status < 600) {
        error = "Er is een server fout opgetreden. Probeer het later opnieuw."
      }

      if(request.status != 200){
        setError(true)
        setErrorMsg(error)
        return
      }
      GTM.sendEvent("Contact")
      setSubmitted(true)
    });

    request.addEventListener('error', function(e){
      console.error(e)
      setSubmitting(false)
      setError(true)
      setErrorMsg("Kan geen verbinding maken met de server. Probeer het later opnieuw.")
    });
    request.send(formdata);
  }

  const handleFileChange = (ev) => {
    setError(false)
    setErrorMsg("")
    let tooBig = false
    let errMsg = []
    for(let i= 0; i < ev.target.files.length; i++){
      if(ev.target.files[i].size > 20 * 1024 *1024) {
        tooBig = true
        errMsg.push(`Bestand ${ev.target.files[i].name} is te groot. Maximaal 20MB per bestand.`)
      }
    }

    if(tooBig){
      setError(true)
      setErrorMsg(errMsg.join("\n"))
      ev.target.value = ""
      return
    }
    setFiles(ev.target.files)
  }

  useEffect(()=>{
  },[files])
  
  // const filterBy = () => true;

  return <div className="container py-5" data-iframe-height="">
    <FadeInOut className="" show={submitted == true} duration={250}>
      <React.Fragment>
        <div className=" w-100 text-center mx-auto">
              <h1 className="font-weight-bold text-error mb-3"><img src={errorImg} alt="" style={{maxWidth:"100px"}}className="error-img w-100"/></h1>
              <h3 className="">Gelukt!</h3>
              <span className="">Bedankt voor het invullen van het contactformulier, we streven er naar om je binnen enkele uren te beantwoorden.</span>
        </div>
      </React.Fragment>
    </FadeInOut>
    <FadeInOut className="" show={submitted == false} duration={250}>
      <Form>
        <Row className="">
          <InputTextField size="6" value={firstname} setter={setFirstname} id="firstname" label="Voornaam" validate={validate} autocomplete="given-name"/>
          <InputTextField size="6" value={lastname} setter={setLastname} id="lastname" label="Achternaam" validate={validate} autocomplete="family-name"/>
          <InputTextField size="6" value={company} setter={setCompany} id="company" label="Bedrijf" validate={validate} required={false} autocomplete="company"/>
          <InputTextField size="6" type="email" value={email} setter={setEmail} id="email" label="Email" validate={validate} autocomplete="email"/>
          <InputTextField type="textarea" size="12" value={description} setter={setDescription} id="description" label="Omschrijving" validate={validate}/>
          <Col md="12">
            <FormGroup>
              <Label for="files">
                Gebruik de onderstaande knop om ontwerpen te uploaden. 
              </Label>
              <Input
                id="Files"
                name="files"
                type="file"
                multiple
                ref={fileRef}
                onChange={handleFileChange}
              />
              <FormText>
                Toegestane bestandstypen: .AI, .PDF, .SVG, .EPS, .DXF, .JPG, .PNG, .PSD en .ZIP, maximaal 5 bestanden met een maximumgroote van 20MB per bestand.
              </FormText>
            </FormGroup>
          </Col>
          <Col sm="12">
            <Alert color="danger" isOpen={error} toggle={()=>setError(false)}>
              <p className="m-0" style={{whiteSpace:"pre"}}>{errorMsg}</p>
            </Alert>
            <Button color="primary" onClick={SubmitQuote} disabled={submitting || submitted} outline>{!submitting?"Versturen":(<><FontAwesomeIcon className="me-2" icon={faCircleNotch} spin/> {progress < 100?`Bestanden uploaden (${progress.toFixed(0)}%)`:"Aan het laden" }</>)}</Button>
            
          </Col>
        </Row>
      </Form>
    </FadeInOut>
  </div>
}

export default QuoteEmbed