import React, { Component } from "react";
import { connect } from "react-redux";
import {Row,Col, FormGroup, Input, Label, Button} from "reactstrap"

import { getCoupon, loadCheckout, getVat } from "../../api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

class Coupon extends Component {
  state = {
    coupon:"",
    couponValid:true,
  }

  getCoupon = () => {
    if(this.state.coupon == ""){
      this.setState({couponValid:false})
      return
    }
    this.props.dispatch(getCoupon(this.state.coupon))
  }

  render() {
    return (
      <div className="border-bottom mb-3">
        <Row>
          <Col xs={9}>
            <FormGroup floating>
              <Input
                id="coupon"
                placeholder="Kortingscode"
                value={this.state.coupon}
                onChange={(ev)=>{this.setState({coupon:ev.target.value,couponValid:true})}}
                invalid={!this.state.couponValid}
                type="text"
              />
              <Label for="coupon">
                Kortingscode
              </Label>
            </FormGroup>
          </Col>
          <Col xs={3}>
            <Button color="info" className="w-100 rounded" onClick={this.getCoupon} style={{height:"50px"}}>
              {this.props.checkout.loading==false?<span className="text-white">Toepassen</span>:
              <FontAwesomeIcon icon={faCircleNotch} className="text-white" style={{fontSize:"36px"}} spin/>}
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const design = state.design;
  const checkout = state.checkout;
  const settings = state.settings;

  return {
    settings,
    design,
    checkout
  };
}

export default (connect(mapStateToProps)(Coupon));
