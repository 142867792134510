import React, { Component } from "react";
import { connect } from "react-redux";

import { Alert, Container, Row, Col, Button,
  Nav, NavItem,NavLink,
  Navbar, NavbarBrand, NavbarToggler, Collapse, UncontrolledDropdown,
DropdownToggle, DropdownMenu, DropdownItem, NavbarText,
FormGroup, Label, Input, InputGroup,
Card, CardBody, CardTitle, CardSubtitle,
TabContent,TabPane, Fade, UncontrolledTooltip } from "reactstrap";
import { Link, useLocation, useParams, Navigate, useNavigate  } from 'react-router-dom';
import * as d3 from "d3";
import { setField, validate, setDifferentInvoiceAddress,setCompany, back, checkoutData, resetVat } from "../../store/checkout";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt, faPhone, faEnvelope, faClock, faRecycle, faBoxOpen } from '@fortawesome/free-solid-svg-icons'

import FadeInOut from "./FadeInOut";
import Toast from "../Analyzer/components/Toast";
import Loader from "../Analyzer/components/Loader";

import { getCoupon, loadDetails, getVat, orderOperation, createNotification } from "../../api";
import Coupon from "./Coupon"
import Designs from "./Designs"
import Error404 from "../../components/error404"
import Error410 from "../../components/error410"
import { ToastContainer, toast } from 'react-toastify';

import success from "./success.svg"
import GTM from "../../gtm";
//Utility

function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {height, width};
}

function countryCodeToCountry(code){
  switch(code){
    case "NL":
      return "Nederland"
    case "BE":
      return "Belgie"
    case "DE":
      return "Duitsland"
  }
}

class Details extends Component {
    state = {
      show:true,
      loaded:false,
      notice:true,
      diff:false,
      error:false,
      preview:false,
      activeTab:1,
      animating:0,
      design:null,
      isMobile:getWindowDimensions().width<992,
      feedbackPage:0,
      feedbackText:""
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
      //Load Details
      loadDetails(this.props.params.id)
    }

    componentDidUpdate(prevProps) {
      const {checkout } = this.props
      if(prevProps.checkoutData == null && checkout.checkoutData != null && (checkout.checkoutData.details.status === "paid" || checkout.checkoutData.details.status === "on_account")) {
        //Send conversion of order placed reached to GTM
        GTM.sendEvent("purchase",{
          value: checkout.checkoutData.details.value,
          order_id: checkout.checkoutData.details.order
        })
      }
    }

    handleResize = () => {
      this.setState({isMobile: getWindowDimensions().width<992})
    }

    getCoupon = () => {
      if(this.state.coupon == ""){
        this.setState({couponValid:false})
        return
      }
      this.props.dispatch(getCoupon(this.state.coupon))
    }

    sendFeedback = async () => {
      var raw = JSON.stringify({
        feedback:this.state.feedbackText,
        rating: this.state.feedbackVal,
        id:this.props.params.id
      });
      var header = new Headers();
      header.append("Content-Type", "application/json");
      var requestOptions = {
        method: 'POST',
        body: raw,
        headers: header,
        redirect: 'follow',
      };

      try {
        var response = await fetch(`${process.env.REACT_APP_API}/api/feedback`,requestOptions)
      } catch (err) {
        createNotification({
          type: "error",
          title: "Netwerk fout",
          text:"Kan geen verbinding maken met server, probeer het later opnieuw.",
        },5000)
        return -1
      }
      if(response.status != 200){
        createNotification({
          type: "error",
          title: "Onverwachte fout",
          text:"Er is een server fout opgetreden, probeer het later opnieuw.",
        },5000)
        return -1
      }
      this.setState({feedbackPage:1})
    }

    stateText = (state) => {
      if(state == "open"){
        return ["openstaand", "nog niet afgerond"]
      } else if(state == "canceled"){
        return ["geannuleerd", "geannuleerd"]
      } else if(state == "failed"){
        return ["mislukt", "helaas mislukt"]
      } else {
        return ["verlopen", "verlopen"]
      }
    }
    recycle = (recycle) =>{
      if(recycle=="none"){
        return "Alles leveren"
      } else if(recycle=="outside"){
        return "Binnenvormen leveren"
      } else if(recycle=="all"){
        return "Recyclen"
      } else {
        return ""
      }
    }
    setFeedback = (ev) => {
      this.setState({feedbackText:ev.target.value})
    }

    render() {
      if(this.props.settings.error == 404){
        return <Error404/>
      } else if(this.props.settings.error == 410) {
        return <Error410/>
      }

      const {checkout } = this.props
      const {VATreverseCharge} = checkout
      if(checkout.checkoutData == null) {
          return <Loader text={this.props.settings.error?this.props.settings.error:"Data aan het laden..."}/>
      }
      if (checkout.checkoutData.redirect) {
        return <Navigate to={"/designs"+checkout.checkoutData.redirect} />
      }
      const {customer,shipping, shipCat, shipType, order,coupon, surcharge, status, recycle, feedback} = checkout.checkoutData.details
      const data = checkout.checkoutData.data
      var shipTime =""
        if (shipping != undefined) {
          shipTime = shipping.country=="NL"?"1 werkdag":"1-3 werkdagen"
        } else {
          shipTime = customer.country=="NL"?"1 werkdag":"1-3 werkdagen"
        }
        return (
            <React.Fragment>
                  <div className="checkoutBg">
                    <Container fluid="xl" className="position-relative">
                    <Row className="">
                      <Col lg={6} className="checkout">
                      <Container>
                        <img style={{"height":"80px"}} className={"my-0 mb-3 w-md-50 mr-auto font-weight-normal "} src={"/logo-dark.png"}/>
                        <span className="text-muted d-block">Bestelling {order}</span>
                        {(status=="paid" || status=="on_account")?
                        <React.Fragment>
                          <h5 className="">Bedankt voor je bestelling!</h5>
  			                  <p>Bedankt voor je bestelling bij Het Laser Lokaal, wij gaan gelijk voor je aan de slag om je bestelling gereed te maken! Je ontvangt binnen een paar minuten een bevestiging{status=="on_account"?" en factuur":""} van je bestelling per e-mail.</p>
                          <Row>
                            <Col xs={6} className="mb-3">
                              <div className="rounded border px-3 py-2">
                                <h6 className="">Contactgegevens</h6>
                                <span className="d-block"><FontAwesomeIcon className="text-pink me-2" icon={faUserAlt}/> {customer.firstname} {customer.lastname}</span>
                                <span className="d-block"><FontAwesomeIcon className="text-pink me-2" icon={faPhone}/> {customer.phone}</span>
                                <span className="d-block"><FontAwesomeIcon className="text-pink me-2" icon={faEnvelope}/> {customer.email}</span>
                              </div>
                            </Col>
                            <Col xs={6} className="mb-3">
                              <div className="rounded border px-3 py-2 h-100">
                                <h6 className="">Levering</h6>
                                <span className="d-block"><FontAwesomeIcon className="text-pink me-2" icon={faClock}/> {shipCat==="fast"?"Snel (1-2 werkdagen)":"Standaard (4-5 werkdagen)"}</span>
                                <span className="d-block"><FontAwesomeIcon className="text-pink me-1" icon={faBoxOpen}/> {shipType=="pickup"?"Ophalen":`Verzenden (+${shipTime})`}</span>
                                <span className="d-block"><FontAwesomeIcon className="text-pink me-2" icon={faRecycle}/> {this.recycle(recycle)}</span>
                              </div>
                            </Col>
                            <Col xs={6} className="mb-3">
                              <div className="rounded border px-3 py-2">
                                <h6 className="">Factuuradres</h6>
                                <span className="d-block">{customer.address}</span>
                                <span className="d-block">{customer.zipcode} {customer.city}</span>
                                <span className="d-block">{countryCodeToCountry(customer.country)}</span>
                              </div>
                            </Col>
                            {shipping != undefined?
                            <Col xs={6} className="mb-3">
                              <div className="rounded border px-3 py-2 h-100">
                                <h6 className="">Leveradres</h6>
                                <span className="d-block">{shipping.address}</span>
                                <span className="d-block">{shipping.zipcode} {shipping.city}</span>
                                <span className="d-block">{countryCodeToCountry(shipping.country)}</span>
                              </div>
                            </Col>:""}
                            <Col xs={12}>
                  						{feedback?<div className="border d-inline-block text-center p-3 rounded w-100 mb-5">
                                <FadeInOut show={this.state.feedbackPage==0} duration={250}>
                                  <div className={"feedback"}>
                                    <h4 className="">Heb je even tijd?</h4>
                                    <p>We zijn de online portal constant aan het verbeteren en we vinden je feedback erg belangrijk.</p>
                                    <div className="mx-auto">
                                      <p>Geef je ervaring een cijfer van 1 tot 10.</p>
                                      <div className="rating" >{this.state.selected}
                                        {[1,2,3,4,5,6,7,8,9,10].map((i)=><Button color="primary" active={(this.state.hovering>=i||(this.state.feedbackVal>=i && this.state.hovering===-1))?"true":""} onMouseLeave={()=>{this.setState({hovering:-1})}} onMouseOver={()=>{this.setState({hovering:i})}} onClick={()=>{this.setState({feedbackVal:i})}} className="mx-1" outline value={i}>{i}</Button>)}
                                      </div>
                                      <label className="mt-4 d-block">Heb je nog feedback?</label>
                                      <textarea className="form-control" id="feedback" rows="3" value={this.state.feedbackText} onChange={this.setFeedback}></textarea>

                                      <button className="btn btn-outline-primary mt-4 sendFeedback"  onClick={this.sendFeedback} disabled={this.state.feedbackVal==undefined}> Verstuur feedback</button>
                                    </div>
                                  </div>
                                </FadeInOut>
                                <FadeInOut show={this.state.feedbackPage==1} duration={250}>
                                  <div className="sent">
                                    <h4 className="">Bedankt!</h4>
                                    <div className="alert alert-success p-2">
                                      Je feedback is verstuurd. We gaan ermee aan de slag!
                                    </div>
                                    <img src={success}/>
                                  </div>
                                </FadeInOut>
                  						</div>:""}
                              <p className="mb-3 mt-3">Deze pagina is 48 uur bereikbaar vanaf het moment van bestellen.</p>
                            </Col>
                          </Row>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <h5 className="">Betaling {this.stateText(status)[0]}</h5>
                          <p className="mb-4">Je betaling is {this.stateText(status)[1]}, hieronder heb je een aantal opties waarmee je kan aangeven hoe je verder wil gaan.</p>
                          <Button color="primary" className="me-2 mb-2" onClick={()=>{orderOperation(this.props.params.id,"retry")}} outline>Betaling {status==="open"?"afronden":"opnieuw proberen"}</Button>
                          {status!=="open"&&<Button color="danger" className="me-2 mb-2" onClick={()=>{orderOperation(this.props.params.id,"cancel",this.props.history)}} outline>Bestelling annuleren</Button>}
                          {(!checkout.checkoutData.details.isQuote && status!=="open") && <><Button color="info" className="me-2 mb-2" onClick={()=>{orderOperation(this.props.params.id,"edit")}} outline>Bestelling aanpassen*</Button>
                          <span className="text-muted d-block mt-3" style={{fontSize: "12px"}}>* Je zal opnieuw je gegevens in moeten vullen</span></>}
                          <p className="mb-3 mt-3">Indien je niets doet, wordt je bestelling na 48 uur geannuleerd en verwijderd.</p>
                        </React.Fragment>
                        }
                        </Container>
                      </Col>
                      <Col lg={6} className="border-start checkout position-relative sidebar">
                        <Designs showCoupon={false} coupon={coupon} surcharge={surcharge} shipping={shipType}/>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/*}<Toast/>*/}

            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
  const design = state.design;
  const checkout = state.checkout;
  const settings = state.settings;

  return {
    settings,
    design,
    checkout
  };
}

const withRouter = (Component) => {
  const Wrapper = (props) => {
    const location = useLocation();
    const params = useParams();
    const history = useNavigate();

    return (
      <Component
        location={location}
        params={params}
        history={history}
        {...props}
        />
    );
  };

  return Wrapper;
};



export default withRouter(connect(mapStateToProps)(Details));


// export default Analyzer;
