import React, { Component } from "react";
import { connect } from "react-redux";

import { updateQuantity } from "../../../store/design";

import { Toast, ToastHeader, ToastBody } from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation, faInfo, faTimes, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import { closeNotification, removeNotification } from "../../../store/settings";

function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

class Notifications extends Component {
  constructor() {
    super();
    this.state = {
      notifications:[
        {
          id:0,
          type: "error",
          title: "test",
          text:"danger danger!",
          open:true
        },{
          id:1,
          type: "warning",
          title: "test",
          text:"danger danger!",
          open:true
        },{
          id:2,
          type: "info",
          title: "test",
          text:"danger danger!",
          open:true
        }
      ],
      uploading: {
        show:1,
        title:"1/5 uploaden",
        text:"3/3 Analyseren (20%)"
      }
    }
    this.upload = React.createRef()
  }

  componentDidMount = () => {
    document.addEventListener("mousemove", this.onEvent);
  }

  componentWillUnmount = () => {
    document.removeEventListener("mousemove", this.onEvent);
  }

  onEvent = (e) => {
    if (this.upload.current == undefined)
      return
    var el = this.upload.current.getBoundingClientRect()
    var x = e.pageX;
    var y = e.pageY;
    if((x > el.x && x < el.x+el.width) && (y > el.y && y < el.y+el.height)){
      this.upload.current.classList.add("hover")
    } else {
      this.upload.current.classList.remove("hover")
    }
  }

  closeNotification = (i) => {
    // var state = this.state
    // state.notifications[i].open = false
    // this.setState(state)
    //
    // setTimeout(()=>{
    //   this.state.notifications.splice(i,1)
    //   this.setState(this.state)
    // },250)
    this.props.dispatch(closeNotification([i,1]))
    setTimeout(()=>{
      this.props.dispatch(closeNotification([i,2]))
    },220)
    setTimeout(()=>{
      this.props.dispatch(removeNotification(i))
    },340)
  }

  render (){
    return (
      <div className="position-fixed end-0 bottom-0 pe-none" style={{zIndex:10000}}>
      {this.props.settings.notifications.map((notification, i)=>{
        if(notification.type == "error"){
          var icon = <div className="p-1 rounded text-white bg-danger"style={{lineHeight:1}}><FontAwesomeIcon icon={faTimes} style={{height: "1.125em", width:"1.125em"}} size="1x"/></div>
        } else if(notification.type == "warning") {
          var icon = <div className="p-1 rounded text-white bg-warning"style={{lineHeight:1}}><FontAwesomeIcon icon={faExclamation} style={{height: "1.125em", width:"1.125em"}} size="1x"/></div>
        } else {
          var icon = <div className="p-1 rounded text-white bg-info"style={{lineHeight:1}}><FontAwesomeIcon icon={faInfo} style={{height: "1.125em", width:"1.125em"}} size="1x"/></div>
        }
        return (
          <Toast key={notification.id}  className={`m-3 notification ${notification.open?"":"fadeOut"} ${notification.slide?"slideOut":""}`} onClick={()=>{this.closeNotification(notification.id)}}>
            <ToastHeader icon={icon}>
              {notification.title}
            </ToastHeader>
            <ToastBody>
              {notification.text}
            </ToastBody>
          </Toast>
        )
      })}

      {this.props.settings.uploading.show?
        <div ref={this.upload}>
          <Toast className="m-3 clickThrough" >
            <ToastHeader icon={<div className="p-1 rounded text-white bg-secondary"style={{lineHeight:1}}><FontAwesomeIcon icon={faCircleNotch} spin style={{height: "1.125em", width:"1.125em"}} size="1x"/></div>}>
              {this.props.settings.uploading.title}
            </ToastHeader>
            <ToastBody>
              {this.props.settings.uploading.text}
            </ToastBody>
          </Toast>
        </div>
      :""}

     </div>
    )
  }
}

function mapStateToProps(state) {
  const settings = state.settings
  return {
    settings
  };
}

export default connect(mapStateToProps)(Notifications);
// export default Notifications/
