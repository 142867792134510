import React, { Component } from "react";
import { connect } from "react-redux";

import { Alert, Container, Row, Col, Button,
  Nav, NavItem,NavLink,
  Navbar, NavbarBrand, NavbarToggler, Collapse, Dropdown,
DropdownToggle, DropdownMenu, DropdownItem, NavbarText,
FormGroup, Label, Input, InputGroup,
Card, CardBody, CardTitle, CardSubtitle, Modal, ModalHeader, ModalBody, ModalFooter,
TabContent,TabPane, Tooltip, UncontrolledTooltip, ButtonGroup } from "reactstrap";
import { Link, useParams, useLocation } from 'react-router-dom';

import * as d3 from "d3";
import {SlideDown} from '../../components/SlideDown'

import { updateLayers, showIgnored } from "../../store/design";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faInfoCircle, faTimes, faPlus, faMinus, faTruck,faInfo, faCircleNotch, faExclamation, faExclamationCircle, faQuestionCircle, faHome, faArrowRight } from '@fortawesome/free-solid-svg-icons'

import "./style.scss"

import DesignsMenu from "./components/DesignsMenu"
import MaterialOptions from "./components/MaterialOptions"
import Materials from "./components/Materials"
import Quantity from "./components/Quantity"
import Summary from "./components/Summary"
import Loader from "./components/Loader"
import Settings from "./components/Settings"
import Toast from "./components/Toast"
import EmptyFiles from "./components/EmptyFiles"
import Dimensions from "./components/Dimensions"
import Visualisation from "./components/Visualisation"

import Delete from "./components/Delete"
import Ignored from "./components/Ignored"
import DragAndDrop from "./components/DragAndDrop"

import { ToastContainer, toast } from 'react-toastify';

import {ReactComponent as Vis} from "./cm4qkzx2f.svg"

import { getMaterials,updateDesignColors,loadDesignGroup, createNotification, updateScale, reportMistake } from "../../api";

import Error404 from "../../components/error404"
import Error410 from "../../components/error410"
import Announcement from "../../components/announcement"
import {setDesigngroup, setTax} from '../../store/settings'
//Utility

import {ReactComponent as Success} from "./success.svg"
import FadeInOut from "../../components/FadeInOut";
import Cookies from 'universal-cookie';
import CookieConsent from "../../components/cookieConsent";
const cookies = new Cookies();

function clone(obj)
{
    if (Object.prototype.toString.call(obj) === '[object Array]')
    {
        var clone = [];
        for (var i=0; i<obj.length; i++)
            clone[i] = clone(obj[i]);

        return clone;
    }
    else if (typeof(this)=="object")
    {
        var clone = {};
        for (var prop in obj)
            if (obj.hasOwnProperty(prop))
                clone[prop] = clone(obj[prop]);

        return clone;
    }
    else
        return obj;
}



class Layers extends Component {
  state = {
    designColors: {...this.props.designColors}
  }
  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps.designColors) != JSON.stringify(this.props.designColors)){
      this.setState({designColors:{...this.props.designColors}})
    }
  }
  updateLayers = (ev) => {
    var { checked,dataset:{color,attr} } = ev.target
    checked = checked
    let designColor = JSON.parse(JSON.stringify(this.props.designColors))

    if(attr == "cut" && checked){
      designColor[color]["engrave"] = false
      designColor[color]["raster"] = false
    }

    if(attr == "engrave" && checked){
      designColor[color]["cut"] = false
      designColor[color]["raster"] = false
    }

    if(attr == "raster" && checked){
      designColor[color]["cut"] = false
      designColor[color]["engrave"] = false
    }
    designColor[color][attr] = checked
    updateDesignColors(designColor)
    //this.props.callLayers(updateLayers(designColor))
  }

  render (){
    return(
      <div className="overflow-auto">
      <h6 className="mt-2">Bewerkingen</h6>
      <table className="table design-colors m-0">
        <thead>
          <tr>
            <th className="border-top-0">Kleur</th>
            <th className="border-top-0">Vlakgraveren <FontAwesomeIcon id={"layers_raster"} className="ms-1 text-info" icon={faInfoCircle}/>
              <UncontrolledTooltip target={"layers_raster"}>Vlakgraveren zijn opperlakkige gegraveerde vlakken (Over het algemeen 0.2mm diep) in de bovenzijde van het materiaal. Vlakgravures zijn alleen mogelijk bij gesloten vormen.</UncontrolledTooltip>
            </th>
            <th className="border-top-0">Lijngraveren <FontAwesomeIcon id={"layers_engrave"} className="ms-1 text-info" icon={faInfoCircle}/>
              <UncontrolledTooltip target={"layers_engrave"}>Een graveerlijn is een ondiepe snede in de bovenzijde van het materiaal.</UncontrolledTooltip>
            </th>
            <th className="border-top-0">Snijden <FontAwesomeIcon id={"layers_cut"} className="ms-1 text-info" icon={faInfoCircle}/>
              <UncontrolledTooltip target={"layers_cut"}>Snijlijnen perforeren het materiaal volledig waardoor onderdelen uit een plaat gesneden kunnen worden.</UncontrolledTooltip>
            </th>
          </tr>
        </thead>
        <tbody>
        {Object.keys(this.props.designColors).map(
          (color,i)=>{
            var layer = this.props.designColors[color]
            return(<tr key={i}>
              <td style={{whiteSpace: "nowrap"}}>
                <div className="color-container mx-auto d-inline-block">
                  <div className="color" title={color} style={{"--c":color}} >
                  </div>
                </div>
                {/*}<b className="d-inline-block" style={{"fontFamily":"monospace","textTransform":"upperCase"}}>&nbsp;{color}</b>*/}
              </td>
              <td>
                <div className="form-check form-switch mx-auto">
                  <Input type="checkbox" id={color+"-raster"} data-color={color} disabled={!this.props.rasterable[color]} data-attr="raster" onChange={this.updateLayers} checked={layer.raster}/>
                  <Label className="form-check-label"for={color+"-raster"}></Label>
                  {!this.props.rasterable[color]?<React.Fragment>
                    <FontAwesomeIcon icon={faInfoCircle} id={"tooltip-"+color.substring(1)} className="text-info"/>
                    <UncontrolledTooltip target={"tooltip-"+color.substring(1)}>Deze laag heeft geen gesloten lijnen. Paden moeten gesloten zijn om vlak te kunnen graveren</UncontrolledTooltip>
                  </React.Fragment>:""}
                </div>
              </td>
              <td>
                <div className="form-check form-switch mx-auto">
                  <Input type="checkbox" id={color+"-engrave"} data-color={color} data-attr="engrave" onChange={this.updateLayers} checked={layer.engrave} />
                  <Label className="form-check-label" for={color+"-engrave"}></Label>
                </div>
              </td>
              <td>
                <div className="form-check form-switch mx-auto">
                  <Input type="checkbox" id={color+"-vector"} data-color={color} data-attr="cut" onChange={this.updateLayers} checked={layer.cut} />
                  <Label className="form-check-label" for={color+"-vector"}></Label>
                </div>
              </td>
            </tr>)
          })
        }
        </tbody>
      </table>
      </div>
    )
  }
}

class Loading extends Component {
  state = {
    designColors:{...this.props.designColors}
  }
  render (){
    return(
      <div className="h-100 w-100 position-relative">
        <div className="position-absolute top-50 start-50 translate-middle">
        <FontAwesomeIcon icon={faCircleNotch} className="text-muted" spin size="3x"/>
        </div>
      </div>
    )
  }
}

class WelcomePane extends Component {
  render() {
    
    return (
      <div className="h-100 overflow-auto">
        <div className="d-flex flex-column h-100 mx-3">
          <div className="flex-grow-1 pt-3">
          <h5>Nieuwe opdracht plaatsen via het Online Portal</h5>
          <ol>
            <li>Upload je tekening.</li>
            <li>Kies je materiaal en opties</li>
            <li>Krijg direct een prijs en plaats je bestelling</li>
          </ol>
          <p>
            <strong>Beknopte tekenregels</strong><br/>
            - Ondersteunde bestandstypen: .ai, .dxf, .eps<br/>
            - Geef verschillende bewerkingen aan in de tekening dmv verschillende lijnkleuren (geen witte of kleurloze lijnen)<br/>
            - Vlakvullingen worden niet herkent, zet daarom vlakvullingen om naar lijnkleuren<br/>
            - Houd minimaal 5 mm afstand tussen verschillende onderdelen en van de rand van het materiaal<br/>
            - Zorg dat vlakken volledig gesloten zijn en niet overlappen<br/>
            - Verwijder alle elementen behalve vector snij/graveerlijnen (zoals snijmaskers, maten, foto's en hulplijnen).<br/><br/>

            Lees hier de volledige <a target="_blank" href="https://laserlokaal.nl/pages/tekening-klaarmaken-voor-lasersnijden">tekenregels</a><br/>
            Lees hier de volledige handleiding van het <a target="_blank" href="https://laserlokaal.nl/pages/tekening-uploaden-bestellen">Online Portal</a><br/>
            Te groot of complex project? <a target="_blank" href="https://laserlokaal.nl/pages/offerte-aanvragen-1">Vraag dan een offerte aan</a>.
          </p>
          </div>
        </div>
      </div>
    )
  }
}

class MainMenu extends Component {
  state = {
    ...this.props.state
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(JSON.stringify(prevProps.state) != JSON.stringify(this.props.state) ){
      this.setState({...this.props.state})
    }
  }
  updateDims = () => {
    updateScale(0.5);
  }
  render (){
    return(
      <React.Fragment>
      <div className="flex-column d-flex h-100">
        <div className="flex-grow-0 flex-shrink-1 " style={{flexBasis: "auto"}}>
          <Nav className="me-auto px-2 mt-1 justify-content-around" tabs>
            <NavItem className="mx-1">
              <NavLink className="text-black" onClick={()=>{this.props.changeTab(1)}} active={this.props.activeTab == 1}>
                Ontwerp
              </NavLink>
            </NavItem>
            <NavItem className="mx-1">
              <NavLink className="text-black" onClick={()=>{this.props.changeTab(2)}} active={this.props.activeTab == 2}>
                Materiaalkeuze
              </NavLink>
            </NavItem>
            <NavItem className="mx-1">
              <NavLink className={(this.props.design.material != undefined && this.props.design.material.XY.length)?"text-black":""} disabled={(this.props.design.material != undefined && this.props.design.material.XY.length)?false:true} onClick={()=>{this.props.changeTab(3)}} active={this.props.activeTab == 3}>
                Materiaal opties
              </NavLink>
            </NavItem>
            <NavItem className="mx-1">
              <NavLink className={(this.props.design.material != undefined && this.props.design.material.XY.length)?"text-black":""}  disabled={(this.props.design.material != undefined && this.props.design.material.XY.length)?false:true} onClick={()=>{this.props.changeTab(4)}} active={this.props.activeTab == 4}>
                Samenvatting
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="flex-grow-1 flex-shrink-1  pb-5" id="optionsPane" style={{flexBasis: "auto", overflow:"auto",scrollBehavior:"smooth"}}>
        {this.props.animating==1?
          <Loading/>:
          <div className="">


            <TabContent activeTab={this.props.activeTab}>
              <TabPane tabId={1}>
                <div className="p-3">
                  <Row>
                    <Col xs={6}>
                      <h6>Bestandsnaam</h6>
                      <p>{this.props.design.origFilename}</p>
                    </Col>
                    <Col xs={6} className={"align-end"}>
                      <Delete/>
                    </Col>
                  </Row>
                  <Ignored ignored={this.props.design.ignored} showIgnored={this.props.design.showIgnored} checkbox={(e)=>{this.props.dispatch(showIgnored(e))}}/>
                  <Dimensions/>
                  <Layers callLayers={this.props.dispatch} rasterable={this.props.design.rasterable} designColors={this.props.design.designColors}/>
                  <Quantity/>

                </div>
              </TabPane>
              <TabPane tabId={2}>
                <Materials />
              </TabPane>
              <TabPane tabId={3}>
                {this.props.design.material != null?<MaterialOptions/>:""}
              </TabPane>
              <TabPane tabId={4}>
                <Summary/>
              </TabPane>
            </TabContent>

          </div>
        }
        </div>
        </div>
      </React.Fragment>
    )
  }
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return width;
}

const ReportMistake = (props) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const [email, setEmail] = React.useState({value:"",invalid:false});
  const updateEmail = (ev) => setEmail({value:ev.target.value,invalid:false});

  const [description, setDescription] = React.useState("");
  const updateDescription = (ev) => setDescription({value:ev.target.value,invalid:false});

  const [reportModal, setReportModal] = React.useState(false)
  const [showSuccess, setSuccess] = React.useState(false)
  const openModal = () => {
    setReportModal(true);
    setSuccess(false);
    setEmail({value:"",invalid:false})
    setDescription({value:"",invalid:false})
  };
  const closeModal = () => {
    setReportModal(false)
    setTooltipOpen(false);
  };

  const sendFile = async () => {
    let valid = true;
    if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value) === false){
      setEmail({value:email.value,invalid: true})
      valid = false;
    }
    if(description.value === ""){
      setDescription({value:description.value,invalid: true})
      valid = false;
    }
    // setSuccess(true);
    if(valid) {
      let resp = await reportMistake({email:email.value,description:description.value})
      if(resp === 200) {
        setSuccess(true);
        setTimeout(()=>{
          setReportModal(false)
        },2500)
      } else if(resp === 409){
        setReportModal(false)
      }
    }
  }
  const design = props.design
  return (
    <div className="position-absolute end-0 top-0 p-2">
      <FontAwesomeIcon id={"report_mistake"} className="text-danger cursor-pointer" onClick={openModal} icon={faQuestionCircle}/>
      <Tooltip trigger="hover" isOpen={tooltipOpen} toggle={toggleTooltip} target={"report_mistake"}>Klopt er iets niet aan het ontwerp? Klik dan hier.</Tooltip>
      <Modal isOpen={reportModal} centered>
        <ModalHeader toggle={closeModal}>Ontwerp sturen</ModalHeader>
        <ModalBody>
            <span className="d-block mb-2">Bestand: <b>{design.origFilename}</b></span>
            Heeft onze portal iets niet goed herkend, of is er iets anders aan het ontwerp mis? Stuur door middel van dit formulier je ontwerp naar ons toe dan kijken we graag met je mee om je een oplossing te bieden.
            <Row className="mt-3">
              <Col xs={12}>
                <FormGroup className="mb-2">
                  <Label for="email" className="fs-7 mb-1">
                    Email
                  </Label>
                  <Input
                    id="email"
                    name=""
                    placeholder=""
                    type="email"
                    invalid={email.invalid}
                    value={email.value}
                    onChange={updateEmail}
                  />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup className="mb-2">
                  <Label for="description" className="fs-7 mb-1">
                    Omschrijving
                  </Label>
                  <Input
                    id="description"
                    name="text"
                    type="textarea"
                    invalid={description.invalid}
                    value={description.value}
                    onChange={updateDescription}
                  />
                </FormGroup>
              </Col>
            </Row>
          <FadeInOut className="bg-white position-absolute start-0 top-0 h-100 w-100" show={showSuccess} duration={250}>
            <div className="align-items-center flex-column justify-content-center h-100 d-flex">
                <Success className="mb-2"/>
                <p>Bedankt voor je melding!</p>
            </div>
          </FadeInOut>
        </ModalBody>
        <ModalFooter>
          {!showSuccess?
          <Button color="primary" onClick={sendFile}>
            Versturen
          </Button>
          :
          <Button color="primary" onClick={closeModal}>
            Sluiten
          </Button>
          }
        </ModalFooter>
      </Modal>
    </div>
  )
}
const body = (title,text) =>{
  return <React.Fragment>
    <div className="toast-header p-0" style={{fontSize: "15px"}}>
      <strong className="me-auto p-0">{title}</strong>
    </div>
    <div className="toast-body pt-1 p-0" style={{fontSize: "14px"}}>
      {text}
    </div>
  </React.Fragment>
}
class TaxModal extends Component {
  setIncl = () => {
    this.props.dispatch(setTax(1))
    toast.dismiss("taxToast")
    toast.success(body("BTW Keuze",<>Top! je ziet nu de prijzen inclusief BTW. Je kunt dit aanpassen onder <svg className="align-text-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z"/><path d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" fill={"#6c757d"}/></svg> &gt; BTW</>))
  }
  setExcl = () => {
    this.props.dispatch(setTax(0))
    toast.dismiss("taxToast")
    toast.success(body("BTW Keuze",<>Top! je ziet nu de prijzen exclusief BTW. Je kunt dit aanpassen onder <svg className="align-text-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z"/><path d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" fill={"#6c757d"}/></svg> &gt; BTW</>))
  }

  componentDidMount(){    
    let tax = cookies.get("tax")
    if(tax != undefined)
      return
    toast(<div className="m-2">
      <b>Welkom!</b> <br/>
      Bestel je zakelijk of als particulier?
      <br/>
      <div className="gap-2 d-flex">
      <Button color="primary" className="mt-2" onClick={this.setIncl}>Zakelijk <span className="fs-8 text-nowrap">(Prijzen excl. BTW)</span></Button>
      <Button color="primary" className="mt-2" onClick={this.setExcl}>Particulier <span className="fs-8 text-nowrap">(Prijzen Incl. BTW)</span></Button>
      </div>
    </div>, {
      position: "bottom-center",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      theme: "light",
      toastId: "taxToast",
      closeButton: false
      });
  }
}


class Analyzer extends Component {
  constructor(props) {
        super(props);
        //this.state.orderId = "-"
    this.state = {
      slider:false,
      notice:false,
      quote:false,
      error:false,
      preview:false,
      activeTab:1,
      animating:0,
      design:null,
      activeDesign:"",
      isMobile:getWindowDimensions()<992,
      dropdownOpen: false,
      dropdownHover: false,
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
      dropdownHover: prevState.dropdownOpen?false:this.state.dropdownHover
    }));
  }

  onMouseEnter = () => {
    this.setState({dropdownHover: true});
  }

  onMouseLeave = () => {
    this.setState({dropdownHover: false});
  }

    componentDidMount() {
      getMaterials();
      window.addEventListener('resize', this.handleResize);

      loadDesignGroup(this.props.params.designGroup)

      document.addEventListener("mousemove", this.uploadEvent);

    }

    componentWillUnmount() {
      document.removeEventListener("mousemove", this.uploadEvent);
    }

    uploadEvent = (e) => {
      var upload = document.getElementById("upload")
      if (upload == undefined)
        return
      var el = upload.getBoundingClientRect()
      var x = e.pageX;
      var y = e.pageY;
      if((x > el.x && x < el.x+el.width) && (y > el.y && y < el.y+el.height)){
        upload.classList.add("hover")
      } else {
        upload.classList.remove("hover")
      }
    }

    componentDidUpdate(prevProps) {

      if(JSON.stringify(prevProps) == JSON.stringify(this.props) )
        return

      if(this.props.designs.designs != null && this.props.designs.designs.length !=0 && (prevProps.designs.designs == null || prevProps.designs.designs.length == 0)){
        this.setState({slider:true})
      }
      if(this.props.design == null || !this.props.design.loaded)
        this.setState({activeTab:1})
      if(prevProps.params.designGroup != this.props.params.designGroup){
        loadDesignGroup(this.props.params.designGroup)

      }
    }

    handleResize = () => {
      this.setState({isMobile: getWindowDimensions()<992})
    }

    changeTab = (activeTab) => {
      // || activeTab != 2 && this.state.activeTab == 2
      if(activeTab == 2 && this.state.activeTab != 2  ){
        this.setState({animating:1})
        setTimeout(()=>this.setState({animating:0}),200)
      }
      this.setState({activeTab:activeTab})
    }

    info = (ev) => {

      createNotification({
        type: "error",
        title: "Ontwerpgroep nog niet compleet",
        text: "Zorg dat alle ontwerpen geldig zijn. Je kan bij ongeldige ontwerpen over het rode info bolletje bewegen om te zien wat er ongeldig is."
      },5000)
    }

    slider = () => {
      this.setState({slider:!this.state.slider})
      setTimeout((()=>{this.setState({sliderDone:!this.state.slider})}),600)
    }

    quote = () => {
      this.setState({quote:!this.state.quote})
    }

    render() {
      if(this.props.settings.error == 404){
        return <Error404/>
      } else if(this.props.settings.error == 410) {
        return <Error410/>
      }
      if(this.props.designs.designs == null){
        return <Loader text={this.props.settings.error?this.props.settings.error:"Data aan het laden..."} />
      }
      const disabled = Math.max(...this.props.designs.designs.map((el)=>el.status))!= 0?true:false
      return (
          <React.Fragment>
            <div className="flex-column d-flex h-100">
              <div className="flex-grow-0 flex-shrink-1 " style={{flexBasis: "auto"}}>
                <Announcement/>
                <Navbar
                  color="white"
                  expand
                  light
                  className="border-bottom"
                >
                  <Link to={"/"}><img style={{"height":"70px"}} className={"my-0 w-md-50 mr-auto font-weight-normal "+(this.state.isMobile?"py-3 ps-3":"py-1 ps-3")} src={this.state.isMobile?"/logo-sm-dark.png":"/logo-dark.png"}/></Link>
                  <a href="https://laserlokaal.nl/" rel="noopener noreferrer" className="expand-right ms-2 btn btn-sm btn-outline-primary"><FontAwesomeIcon icon={faHome}/><span className="text">Terug naar Het Laser Lokaal</span></a>
                  <NavbarToggler onClick={function noRefCheck(){}} />
                  <Nav className="me-auto" navbar>
                    {/*<NavItem>
                      <NavLink href="/components/">
                        Components
                      </NavLink>
                    </NavItem>*/}
                  </Nav>
                  <Settings/>
                  <ButtonGroup>
                    <Button tag={!disabled?Link:undefined} outline={disabled} color={disabled?"danger":"primary"} to={(!disabled && "/checkout/"+this.props.settings.designGroup) || ""} onClick={disabled && this.info}>Afrekenen</Button>
                    
                    <Dropdown group onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} isOpen={this.state.dropdownOpen || this.state.dropdownHover} toggle={this.toggle}>
                      
                      <DropdownToggle outline={disabled} style={{transition:"none"}} color={disabled?"danger":"primary"} >
                        <FontAwesomeIcon icon={faChevronDown}/>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem tag={!disabled?Link:undefined} onClick={disabled && this.info} to={(!disabled && "/checkout/"+this.props.settings.designGroup) || ""}>
                          Bestelling plaatsen
                        </DropdownItem>
                        <DropdownItem tag={!disabled?Link:undefined} onClick={disabled && this.info} to={(!disabled && "/quote/"+this.props.settings.designGroup) || ""}>
                          Offerte genereren
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </ButtonGroup>
                </Navbar>
              </div>

              <Container fluid className="flex-grow-1 flex-shrink-1 " style={{flexBasis: "auto", overflow:"auto"}}> {/*style={{"padding-top":"calc( 1rem + 71px )"}}*/}
                <Row className="h-100">
                    <Col sm={12} lg={this.state.activeTab!=2?6:4}  xxl={this.state.activeTab!=2?7:4} id="visualisation" className="animateFlexbox h-lg-100 p-3 pb-5 position-relative">
                          {(this.props.design==null)?
                            <DragAndDrop />:((this.props.design.loaded==0)?
                              <Loader />:<>
                                <Visualisation sliderDone={this.state.sliderDone} tab={this.state.activeTab} design={this.props.design} showIgnored={this.props.design.showIgnored} material={this.props.design.material} ignored={this.props.design.ignored} visualisation={this.props.design.visualisation} viewbox={this.props.design.viewbox} designColors={this.props.design.designColors}/>
                                <ReportMistake design={this.props.design}/>
                              </>
                            )
                        }
                    </Col>
                    <Col sm={12} lg={this.state.activeTab!=2?6:8}  xxl={this.state.activeTab!=2?5:8} className="animateFlexbox menu h-lg-100 dropshadow px-0" style={{overflow: "hidden"}}>

                      {(this.props.design==null)?
                        <WelcomePane dispatch={this.props.dispatch} tax={this.props.settings.tax}/>:((this.props.design.loaded==0)?
                        null:<MainMenu dispatch={this.props.dispatch} design={this.props.design} state={this.props} activeTab={this.state.activeTab} animating={this.state.animating} changeTab={this.changeTab} changeData={this.changeData}/>)
                      }

                    </Col>
                </Row>
              </Container>

              <div  className="flex-grow-0 flex-shrink-1 bg-white w-100 " style={{zIndex:200}}>
                <div className="position-relative ">
                  <div className="position-absolute px-4 pt-4 overflow-hidden bottom-0"style={{left:"50px", cursor:"pointer"}}>
                    <div className="rounded-top dropshadow bg-white">
                      <Button className="rounded-top position-relative" color="primary" style={{borderBottom:0}} outline onClick={this.slider}>Ontwerpen <FontAwesomeIcon className={this.state.slider?"transition rotate-180":"transition"} icon={faChevronUp}/>
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-pink">
                        {this.props.designs.designs.length}
                      </span>
                      </Button>
                    </div>
                  </div>
                  {/*}<div className="position-absolute px-4 pt-4 overflow-hidden bottom-0" style={{right:"50px", width:"200px", cursor:"pointer"}}>
                    <div className="rounded-top">
                      <Button className="rounded-top position-relative bg-pink" color="pink" style={{borderBottom:0, color:"white"}} onClick={this.quote}>Offerte {!this.state.quote?<FontAwesomeIcon icon={faChevronUp}/>:<FontAwesomeIcon icon={faChevronDown}/>}
                      </Button>
                      <SlideDown transitionOnAppear={false} className={'my-dropdown-slidedown bg-pink text-white'} style={{zIndex:200}} closed={!this.state.quote}>
                        <div><span className="m-2">Ontvang je liever een offerte? Vraag deze dan aan via ons Offerte formulier. Je ontvangt binnen 24 uur een vrijblijvend voorstel.</span></div>
                      </SlideDown>
                    </div>

                  </div>*/}
                </div>
                {/*<div className="border-top"></div>*/}
                <SlideDown transitionOnAppear={false} className={'my-dropdown-slidedown dropshadow'} style={{zIndex:200}} closed={!this.state.slider}>
                  {/*<Container fluid className="" style={{}}>*/}
                    <DesignsMenu active={this.state.activeDesign}/>
                  {/*</Container>*/}
                </SlideDown>
              </div>
            </div>
            {/*}<Toast/>*/}


            <EmptyFiles/>
            {/* <TaxModal dispatch={this.props.dispatch}/> */}
          </React.Fragment>
      );
    }
}

function mapStateToProps(state) {
  const design = state.design;
  const designs = state.designs;
  const settings = state.settings;
  return {
    design,
    designs,
    settings
  };
}

const withRouter = (Component) => {
  const Wrapper = (props) => {
    const location = useLocation();
    const params = useParams();

    return (
      <Component
        location={location}
        params={params}
        {...props}
        />
    );
  };

  return Wrapper;
};


export default withRouter(connect(mapStateToProps)(Analyzer));
// export default Analyzer;
