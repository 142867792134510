import { createSlice } from '@reduxjs/toolkit'

import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    designGroup:null,
    tax: cookies.get("tax")==1?true:false,
    notifications:[],
    uploading: {
      show:false,
      title:"1/5 uploaden",
      text:"3/3 Analyseren (20%)"
    },
    error:null,
    material: {
      sortBy: "price",
      direction: "asc"
    },
    announcement: null
  },

  reducers: {
    sort: (state, action)=>{
      if(state.material.sortBy == action.payload){
        state.material.direction = state.material.direction=="asc"?"desc":"asc"
      } else {
        state.material.direction = "asc"
      }
      state.material.sortBy = action.payload
      return state
    },
    error: (state, action)=>{
      state.error = action.payload
      return state
    },
    setDesigngroup: (state, action) => {
      state.designGroup = action.payload

      return state
    },
    setTax: (state,action) => {
      state.tax = action.payload
      var expires = new Date()
      expires.setFullYear(expires.getFullYear()+10)
      cookies.set("tax", action.payload?1:0, { path: '/', expires: expires })
      return state
    },
    decrement: (state) => {
      state.value -= 1
    },
    setAnnouncement: (state,action) => {
      state.announcement = action.payload || null
      if (state.announcement != null) {
        if(new Date(state.announcement.expires) > new Date() || state.announcement.expires == false){
          var cookie = cookies.get("announcement")
          if(cookie != undefined && cookie == state.announcement.id) {
            state.announcement.show = false;
          } else {
            state.announcement.show = true;
          }
        } else {
          state.announcement = null
        }

      }
      return state;
    },
    announcementDismiss: (state) => {
      var expires = new Date()
      expires.setFullYear(expires.getFullYear()+1)
      var id = cookies.set("announcement", state.announcement.id, { path: '/', expires: expires })
      state.announcement.show = false;
      return state;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    closeNotification: (state,action, action2) => {
      var idx = state.notifications.findIndex((el)=>el.id == action.payload[0])
      if(idx == -1)
        return state
      if(action.payload[1] == 1){
        state.notifications[idx].open = false
      } else {
        state.notifications[idx].slide = true
      }

      // setTimeout(()=>{
      //   state.notification.splice(action.payload,1)
      //   return state
      // },250)
      return state
    },
    removeNotification: (state,action) => {
      var idx = state.notifications.findIndex((el)=>el.id == action.payload)
      if(idx == -1)
        return state
      state.notifications.splice(idx,1)
      return state
    },
    uploading: (state,action) => {
      state.uploading = {
        show:action.payload.show || false,
        title:action.payload.title || "",
        text:action.payload.text || ""
      }
      return state
    }
  },
})

// Action creators are generated for each case reducer function
export const { setTax, closeNotification, removeNotification, uploading, setDesigngroup, error, sort, setAnnouncement, announcementDismiss} = counterSlice.actions

export default counterSlice.reducer
