import React, { Component } from "react";
import { connect } from "react-redux";

import { loadDesign, uploadFiles } from "../../../api";

import { Row, Col, Button, Container,
Card, CardBody, CardTitle, CardSubtitle, UncontrolledTooltip, Popover, PopoverHeader, PopoverBody } from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import upload from "./upload.svg"

function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

class DesignElement extends Component {
  constructor(props) {
    super(props);
        this.state = {
            open: false
        }
    }
    componentDidMount(){
      this.setState({open:true})
    }
  render() {
    const value = this.props.design.amount
    return (
      <Card>
        <div className="border-bottom p-2">
        <div className="ratio ratio-16x9">

          <img className="w-100" style={{cursor:"pointer"}} onClick={() => loadDesign(this.props.design.ident)} src={process.env.REACT_APP_API+this.props.design.img}/>
          {/*}<div style={{backgroundImage:"url(http://localhost:8888"+this.props.design.img+")", backgroundPosition:"center", backgroundSize:"contain", backgroundRepeat:"no-repeat"}}>
          </div>*/}
          </div>
        </div>
        <CardBody>
          <Row>
            <Col sm="6">
              <CardTitle className="ellipsis" tag="h6" title={this.props.design.name}>
                {this.props.design.name}
              </CardTitle>
              <CardSubtitle
                 className="text-muted"
                 tag="h6"
              >
                 {this.props.design.status==0?
                   <React.Fragment>
                    &euro; {roundTo(value.total.value*(this.props.tax?1.21:1),2)}
                    <FontAwesomeIcon id={"design_"+this.props.design.ident} className="ms-1 text-info" onClick={()=>this.setState({open: !this.state.open})} icon={faInfoCircle}/>
                    <UncontrolledTooltip placement="right" className="mb-1" target={"design_"+this.props.design.ident} style={{width: "300px", maxWidth:"300px"}}>
                    <Container>
                      <Row>
                      <Col className="text-start">
                      Snij- en graveerkosten<br/>
                      Materiaal<br/>
                      Bescherm/Plakfolie<br/>
                      {value.total.quantity!= 1?
                        <React.Fragment>
                        Korting ({value.discount.discount}%)<br/>
                        Prijs per stuk<br/>
                        </React.Fragment>
                      :null}
                      </Col>
                      <Col xs="auto" className="text-end">
                      € {roundTo(value.operations,2)}<br/>
                      € {roundTo(value.material,2)}<br/>
                      € {roundTo(value.additional,2)}<br/>
                      {value.total.quantity!= 1?
                        <React.Fragment>
                          € -{roundTo(value.discount.value,2)}<br/>
                          € {roundTo(value.total.unitPrice,2)}<br/>
                        </React.Fragment>
                      :null}
                      </Col>
                      <Col xs={12} className="text-start">
                        <hr className="my-1 bg-white"/>
                      </Col>
                      <Col className="text-start">
                      {value.total.quantity} stuk{value.total.quantity>1?"s":""} (ex. BTW)<br/>
                      BTW<br/>
                      </Col>
                      <Col xs="auto"  className="text-end">
                      {value.total.quantity!= 1?
                        <React.Fragment>
                        € {roundTo(value.discount.value,2)}<br/>

                        </React.Fragment>
                      :null}
                      € {roundTo(value.total.value,2)}<br/>
                      € {roundTo(value.tax,2)}<br/>
                      </Col>
                      </Row>
                      </Container>
                    </UncontrolledTooltip>
                   </React.Fragment>
                  :
                   <React.Fragment>
                    <FontAwesomeIcon id={"design_"+this.props.design.ident} className={"text-danger"} icon={faInfoCircle}/>
                    <UncontrolledTooltip placement="right" target={"design_"+this.props.design.ident}>{this.props.design.status==2?"Geen materiaal gekozen":(this.props.design.status==3?"Alle lagen in het ontwerp staan uit":"Het ontwerp is te groot voor het gekozen materiaal")}</UncontrolledTooltip>
                   </React.Fragment>
                 }
              </CardSubtitle>
            </Col>
            <Col sm="6" className=" text-end align-bottom">
              <Button color="primary" disabled={this.props.active == this.props.design.ident} outline size="sm" onClick={() => loadDesign(this.props.design.ident)}>Aanpassen</Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}

class DesignsMenu extends Component {
  constructor(props) {
        super(props);

        this.onFileChange = this.onFileChange.bind(this);
        this.uploadBtn = this.uploadBtn.bind(this);

        this.state = {
            files: []
        }
        this.upload = React.createRef()
    }

    onFileChange(e) {
        // this.setState({ files: e.target.files })
        uploadFiles(e.target.files)
    }

    uploadBtn(e) {
        this.upload.current.value = null
        e.preventDefault()
        this.upload.current.click()
    }

  render (){
    return(
      <div className="w-100 d-flex" style={{"overflowX":"auto", "overflowY":"none", whiteSpace: "nowrap"}}>
        <div className="p-2 designElement start-0 position-sticky bg-white d-inline-block" style={{zIndex:"100",boxShadow:"0px 0px 5px 2px #9999994f"}}>
          <Card className="h-100">
            <div className="border-bottom p-2">
              <div className="" style={{paddingTop:"calc( 100% * 9/16 )", background:"url("+upload+")", backgroundPosition:"center", backgroundSize:"25%", backgroundRepeat:"no-repeat"}}>
              </div>
            </div>
            <CardBody className="text-center">
              <input type="file" className="d-none" ref={this.upload} onChange={this.onFileChange} multiple/>
              <Button color="primary" onClick={this.uploadBtn}>Upload</Button>
            </CardBody>
          </Card>
        </div>
        {this.props.designs.designs.map((design,i)=>
          <div key={design.ident} className="p-2 designElement flex-shrink-0" style={{display:"inline-block"}}>
            <DesignElement tax={this.props.settings.tax} design={design} active={this.props.design?this.props.design.ident:null} dispatch={this.props.dispatch} />
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const designs = state.designs;
  const design = state.design;
  const settings = state.settings;
  return {
    designs,
    design,
    settings
  };
}

export default connect(mapStateToProps)(DesignsMenu);
// export default DesignsMenu
