import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  TabContent,
  TabPane,
  Fade,
  UncontrolledTooltip,
} from "reactstrap";
import { Link, useLocation, useParams, Navigate } from "react-router-dom";
import * as d3 from "d3";

import {
  setField,
  validate,
  setDifferentInvoiceAddress,
  setCompany,
  back,
  checkoutData,
  resetVat,
} from "../../store/checkout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faInfoCircle,
  faTimes,
  faPlus,
  faMinus,
  faTruck,
  faInfo,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";

import FadeInOut from "./FadeInOut";
import Toast from "../Analyzer/components/Toast";
import Loader from "../Analyzer/components/Loader";

import { getCoupon, loadCheckout, getVat } from "../../api";
import Coupon from "./Coupon";
import Error404 from "../../components/error404";
import Error410 from "../../components/error410";
import { ToastContainer, toast } from "react-toastify";
//Utility

function roundTo(value, decimals) {
  return Number(
    Math.round(+value.toFixed(11) + "e" + decimals) + "e-" + decimals
  ).toFixed(2);
}

class Designs extends Component {
  state = {};

  getExtraText = ({ top, bot }) => {
    if (top == 1 && bot == 1) {
      return "Beschermfolie beide kanten";
    } else if (top == 1 && bot == 2) {
      return "Bescherm- en dubbelzijdige plakfolie";
    } else if (bot == 2) {
      return "Dubbelzijdige plakfolie";
    } else if (top == 1) {
      return "Beschermfolie boven";
    } else if (bot == 1) {
      return "Beschermfolie onder";
    }
    return -1;
  };

  render() {
    const { checkout } = this.props;
    const { VATreverseCharge } = checkout;
    let coupon = checkout.coupon;
    const { data, shipCat } = checkout.checkoutData;
    let shipText =
      checkout.shipping.shipping.value == "ship"
        ? coupon.discount == "shipping"
          ? "Gratis verzending"
          : "Verzending"
        : "Ophalen";
    let shipVal =
      checkout.shipping.shipping.value == "ship"
        ? coupon.discount == "shipping"
          ? 0
          : checkout.checkoutData.shipping
        : 0;
    if (this.props.shipping != undefined) {
      shipText = this.props.shipping.text;
      shipVal = this.props.shipping.val;
    }
    if (this.props.coupon != undefined) {
      coupon = this.props.coupon;
    }
    if (this.props.shipping != undefined) {
      shipText =
        this.props.shipping == "ship"
          ? coupon.discount == "shipping"
            ? "Gratis verzending"
            : "Verzending"
          : "Ophalen";
      shipVal =
        this.props.shipping == "ship"
          ? coupon.discount == "shipping"
            ? 0
            : checkout.checkoutData.shipping
          : 0;
    }

    const operations = data.reduce(
      (acc, val) => acc + parseFloat(val.qty * val.price.operations),
      0
    );
    var discountVal =
      coupon.discount == "percentage"
        ? -roundTo((coupon.value / 100) * operations, 2)
        : coupon.discount == "amount"
        ? -coupon.value
        : 0;
    const subtotal = data.reduce(
      (acc, val) => acc + parseFloat(val.price.total.value),
      0
    );

    const surcharge = this.props.surcharge
      ? this.props.surcharge
      : (shipCat[checkout.shipping.production.value] - 1) * operations;
    if (-discountVal > subtotal + surcharge) {
      discountVal = -(subtotal + surcharge);
    }
    const total = subtotal + discountVal + shipVal + surcharge;

    return (
      <React.Fragment>
        <div
          className="position-sticky flex-column d-flex"
          style={{ height: "calc(100vh - 4em)", top: "4em" }}
        >
          <Container>
            <Row>
              <Col xs="2"></Col>
              <Col xs="5">
                <span style={{ fontSize: "12px" }}>Ontwerp</span>
              </Col>
              <Col xs="2">
                <div className="h-100 d-flex justify-content-end align-items-center">
                  <span style={{ fontSize: "12px" }}>Stukprijs</span>
                </div>
              </Col>
              <Col xs="3">
                <div className="h-100 d-flex justify-content-end align-items-center">
                  <span style={{ fontSize: "12px" }}>Prijs</span>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="overflow-auto flex-grow-1 border-bottom pb-3 mb-3">
            <Container>
              {data.map((design, i) => (
                <Row key={i} className="mt-3">
                  <Col xs="2">
                    <div className="h-100 d-flex justify-content-start align-items-center">
                      <div className="border p-2 bg-white w-100 rounded position-relative ">
                        <div
                          style={{
                            paddingTop: "100%",
                            background:
                              "url(" +
                              process.env.REACT_APP_API +
                              design.img +
                              ")",
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-pink">
                          {design.qty}x
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xs="5">
                    <div className="h-100 d-flex justify-content-start align-items-center">
                      <div>
                        <span className="d-block fw-bold">
                          {design.origFilename}
                        </span>
                        <span
                          className="d-block lh-sm"
                          style={{ fontSize: "12px" }}
                        >
                          Snij- en graveerkosten: €{" "}
                          {roundTo(design.price.operations, 2)}
                        </span>
                        <span
                          className="d-block lh-sm"
                          style={{ fontSize: "12px" }}
                        >
                          Materiaal: € {roundTo(design.price.material, 2)}{" "}
                        </span>
                        {(design.price.additional && (
                          <span
                            className="d-block lh-sm text-danger"
                            style={{ fontSize: "12px" }}
                          >
                            {this.getExtraText(design.extra)}: €{" "}
                            {roundTo(design.price.additional, 2)}
                          </span>
                        )) ||
                          ""}
                      </div>
                    </div>
                  </Col>
                  <Col xs="2">
                    <div className="h-100 d-flex justify-content-end align-items-center">
                      <b>&euro; {roundTo(design.price.total.unitPrice, 2)}</b>
                    </div>
                  </Col>
                  <Col xs="3">
                    <div className="h-100 d-flex justify-content-end align-items-center">
                      <b>&euro; {roundTo(design.price.total.value, 2)}</b>
                    </div>
                  </Col>
                </Row>
              ))}
            </Container>
          </div>
          <Container>
            {this.props.showCoupon == false ? "" : <Coupon />}
            {/*
            <div className="border-bottom mb-3">
              <Row>
                <Col xs={9}>
                  <FormGroup floating>
                    <Input
                      id="coupon"
                      placeholder="Kortingscode"
                      value={this.state.coupon}
                      onChange={(ev)=>{this.setState({coupon:ev.target.value,couponValid:true})}}
                      invalid={!this.state.couponValid}
                      type="text"
                    />
                    <Label for="coupon">
                      Kortingscode
                    </Label>
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <Button color="info" className="w-100 rounded" onClick={this.getCoupon} style={{height:"50px"}}>
                    {checkout.loading==false?<span className="text-white">Toepassen</span>:
                    <FontAwesomeIcon icon={faCircleNotch} className="text-white" style={{fontSize:"36px"}} spin/>}
                  </Button>
                </Col>
              </Row>
            </div>*/}
            <div className="border-bottom pb-3 mb-3">
              <Row>
                <Col xs="9">
                  <div className="h-100 d-flex justify-content-start align-items-center">
                    <div>
                      <span className="d-block">Subtotaal</span>
                      {surcharge > 0 ? (
                        <span className="d-block">Toeslag levertijd</span>
                      ) : (
                        ""
                      )}
                      <span className="d-block">{shipText}</span>
                      {coupon.discount == "percentage" ? (
                        <span className="d-block">
                          Korting ({coupon.value}% op snij- en graveertijd)
                        </span>
                      ) : coupon.discount == "amount" ? (
                        <span className="d-block">
                          Korting (&euro; {coupon.value})
                        </span>
                      ) : (
                        ""
                      )}
                      {VATreverseCharge ? (
                        <span className="d-block">BTW-verlegd</span>
                      ) : (
                        <span className="d-block">BTW</span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs="3">
                  <div className="h-100 d-flex justify-content-end align-items-center">
                    <div className="text-end">
                      <span className="d-block fw-bold">
                        &euro; {roundTo(subtotal, 2)}
                      </span>
                      {surcharge > 0 ? (
                        <span className="d-block fw-bold">
                          &euro; {roundTo(surcharge, 2)}
                        </span>
                      ) : (
                        ""
                      )}
                      <span className="d-block fw-bold">
                        &euro; {roundTo(shipVal, 2)}
                      </span>
                      {coupon.discount == "percentage" ||
                      coupon.discount == "amount" ? (
                        <span className="d-block fw-bold">
                          &euro; {roundTo(discountVal, 2)}
                        </span>
                      ) : (
                        ""
                      )}
                      {VATreverseCharge ? (
                        <span className="d-block fw-bold">&euro; 0.00</span>
                      ) : (
                        <span className="d-block fw-bold">
                          &euro; {roundTo(total * 0.21, 2)}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mb-5">
              <Row>
                <Col xs="9">
                  <div className="h-100 d-flex justify-content-start align-items-center">
                    <div>
                      <span className="d-block">Totaal</span>
                    </div>
                  </div>
                </Col>
                <Col xs="3">
                  <div className="h-100 d-flex justify-content-end align-items-center">
                    <div className="text-end">
                      {VATreverseCharge ? (
                        <span className="d-block fs-4 fw-bold lh-sm">
                          &euro; {roundTo(total, 2)}
                        </span>
                      ) : (
                        <React.Fragment>
                          <span className="d-block fs-4 fw-bold lh-sm">
                            &euro;{" "}
                            {roundTo(
                              +total + parseFloat(roundTo(total * 0.21, 2)),
                              2
                            )}
                          </span>
                          <span
                            className="d-block lh-sm"
                            style={{ fontSize: "12px" }}
                          >
                            &euro; {roundTo(total, 2)} Excl. BTW
                          </span>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const design = state.design;
  const checkout = state.checkout;
  const settings = state.settings;

  return {
    settings,
    design,
    checkout,
  };
}

export default connect(mapStateToProps)(Designs);

// export default Analyzer;
