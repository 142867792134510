import React, { Component } from 'react';
import { Button } from "reactstrap";

//Import images
import errorImg from "./paper-plane-regular.svg";

class Error404 extends Component {

  goBack = ()=>window.history.back()
    render() {
        return (
            <React.Fragment>
              <div className=" w-50 text-center position-absolute top-50 start-50 translate-middle">
                    <h1 className="font-weight-bold text-error mb-3"><img src={errorImg} alt="" style={{maxWidth:"200px"}}className="error-img w-100"/></h1>
                    <h3 className="">Gelukt!</h3>
                    <span className="">Je ontvangt zo een mail met een bestel overzicht en een link om af te rekenen. De offerte is 14 dagen geldig.</span>
                    <div className="mt-5 text-center">
                      <Button outline color="primary" className="ms-2" href={"/"}>Startpagina</Button>
                    </div>
              </div>
            </React.Fragment>
        );
    }
}

export default Error404;
