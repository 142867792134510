import { toast } from 'react-toastify';
import { setTax } from './store/settings';
import { Button } from 'reactstrap';
import Cookies from 'universal-cookie';
import store from './store';

import React from 'react';
import { setCompany } from './store/checkout';
const body = (title,text) =>{
  return <React.Fragment>
    <div className="toast-header p-0" style={{fontSize: "15px"}}>
      <strong className="me-auto p-0">{title}</strong>
    </div>
    <div className="toast-body pt-1 p-0" style={{fontSize: "14px"}}>
      {text}
    </div>
  </React.Fragment>
}
const cookies = new Cookies();
export default {
  init() {
    if(cookies.get("CookieConsent") === undefined) {
      return
    }
    if(cookies.get("tax") !== undefined){
      if(cookies.get("tax") == 0){
        store.dispatch(setTax(0))
        store.dispatch(setCompany({checked:true}))
      }
      return
    }
    toast(<div className="m-2">
      <b>Welkom!</b> <br/>
      Bestel je zakelijk of als particulier?
      <br/>
      <div className="gap-2 d-flex">
      <Button color="primary" className="mt-2" onClick={this.setExcl}>Zakelijk <span className="fs-8 text-nowrap">(Prijzen excl. BTW)</span></Button>
      <Button color="primary" className="mt-2" onClick={this.setIncl}>Particulier <span className="fs-8 text-nowrap">(Prijzen Incl. BTW)</span></Button>
      </div>
    </div>, {
      position: "bottom-center",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      theme: "light",
      toastId: "taxToast",
      closeButton: false
      });
  },

  setIncl() {
    store.dispatch(setTax(1))
    store.dispatch(setCompany({checked:false}))
    toast.dismiss("taxToast")
    toast.success(body("BTW Keuze",<>Top! je ziet nu de prijzen inclusief BTW. Je kunt dit aanpassen onder <span className='no-wrap'><svg className="align-text-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z"/><path d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" fill={"#6c757d"}/></svg> &gt; BTW</span></>),{
      className: "pe-auto"
    })
  },
  setExcl() {
    store.dispatch(setTax(0))
    store.dispatch(setCompany({checked:true}))
    toast.dismiss("taxToast")
    toast.success(body("BTW Keuze",<>Top! je ziet nu de prijzen exclusief BTW. Je kunt dit aanpassen onder <span className='no-wrap'><svg className="align-text-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z"/><path d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" fill={"#6c757d"}/></svg> &gt; BTW</span></>),{
      className: "pe-auto"
    })
  }
}
