import { createSlice } from '@reduxjs/toolkit'
import { act } from 'react-dom/test-utils'

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    data: {
      email: { value:"", required:true, valid:true },
      firstname: { value:"", required:true, valid:true },
      lastname: { value:"", required:true, valid:true },
      terms: { value:false, required:true, valid:true },
    },
    quoteData: null,
    loading:false,
    valid:false,
    submitted:false,
  },
  reducers: {
    quoteData: (state, action) => {
      state.quoteData = action.payload
      return state
    },
    quoteReset: (state) => {
      return undefined
    },
    quoteLoading: (state, action) => {
      state.loading = action.payload
    },
    quoteSubmit: (state) => {
      state.submitted = true
      state.loading = false
      return state
    },
    setQuoteField: (state, action) => {
      const { field, type, group, value } = action.payload
      state[type][field].value = value
      state[type][field].valid = true
      return state
    },
    quoteValidate: (state) => {
      var allValid = true
      if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.data.email.value) == false){
        allValid = false
        state.data.email.valid = false
      }
      if(state.data.firstname.value == ""){
        allValid = false
        state.data.firstname.valid = false
      }
      if(state.data.lastname.value == ""){
        allValid = false
        state.data.lastname.valid = false
      }
      if(state.data.terms.value == false){
        allValid = false
        state.data.terms.valid = false
      }
      state.valid = allValid
      return state
    },
    rstValidate: (state, action) => {
      const { field, group } = action.payload
      if(group != undefined){

      } else {

      }
    }
  },
})

// Action creators are generated for each case reducer function
export const {setQuoteField,quoteReset, quoteValidate,quoteLoading, quoteData, quoteSubmit} = counterSlice.actions

export default counterSlice.reducer
