import React, { Component } from "react";
import { connect } from "react-redux";

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Label} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import {setTax} from "../../../store/settings"
import { setCompany } from "../../../store/checkout";

function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

class Settings extends Component {
  state = {
    dropdown:false,
  }

  tax = (e) => {
    this.props.dispatch(setTax(e.target.checked))
    this.props.dispatch(setCompany({checked:e.target.checked?false:true}))
  }
  render (){
    return(
      <React.Fragment>
        <Dropdown isOpen={this.state.dropdown} toggle={()=>{this.setState({dropdown:!this.state.dropdown})}}>
          <DropdownToggle tag="div" className="me-2" style={{cursor:"pointer"}}>
            <svg className="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" fill={this.state.dropdown?"#000":"#6c757d"}/></svg>
          </DropdownToggle>
          <DropdownMenu
            end
            flip={false}
          >
            <DropdownItem header>
              BTW
            </DropdownItem>
            <div style={{padding:"0 0.875rem"}}>
              <div className="form-check form-switch m-0">
                <Input type="checkbox" id="tax" checked={this.props.settings.tax} onChange={this.tax}/>
                <Label className="form-check-label m-0" for="tax">{this.props.settings.tax?"Incl.":"Excl."} BTW</Label>
              </div>
            </div>
            {/*}<DropdownItem divider />
            <DropdownItem>
              Foo Action
            </DropdownItem>
            <DropdownItem>
              Bar Action
            </DropdownItem>
            <DropdownItem>
              Quo Action
            </DropdownItem>*/}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}
function mapStateToProps(state) {
  const settings = state.settings;
  return {
    settings
  };
}


export default connect(mapStateToProps)(Settings);
