import React, { Component } from "react";
import { connect } from "react-redux";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faMinus } from '@fortawesome/free-solid-svg-icons'


import { deleteDesign } from "../../../api";

function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

class Delete extends Component {
  state = {
    delete:false
  }
  delete = ()=>{
    this.setState({delete:false})
    deleteDesign()
  }
  render (){
    return (
      <React.Fragment>
        <Button
          color="danger"
          className="mt-2"
          outline
          onClick={()=>{this.setState({delete:true})}}
        >
          <FontAwesomeIcon icon={faTrash}/> Verwijder
        </Button>
        <Modal
          centered
          size="sm"
          isOpen={this.state.delete}
          toggle={()=>{this.setState({delete:false})}}
        >
          <ModalHeader toggle={()=>{this.setState({delete:false})}}>
            Verwijder ontwerp?
          </ModalHeader>
          <ModalBody>
            Weet je zeker dat je het ontwerp wilt verwijderen?
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={this.delete}
            >
              <FontAwesomeIcon icon={faTrash}/> Verwijder
            </Button>
            {' '}
            <Button onClick={()=>{this.setState({delete:false})}}>
              Annuleer
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  // const qty = state.design.qty;
  // return {
  //   qty
  // };
  return {}
}

export default connect(mapStateToProps)(Delete);
// export default DesignsMenu
