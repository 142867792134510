import { configureStore } from '@reduxjs/toolkit'
import designsReducer from './designs'
import designReducer from './design'
import materialsReducer from './materials'
import settingsReducer from './settings'
import checkoutReducer from './checkout'
import quoteReducer from './quote'

export default configureStore({
  reducer: {
    designs: designsReducer,
    design: designReducer,
    materials: materialsReducer,
    settings: settingsReducer,
    checkout: checkoutReducer,
    quote: quoteReducer
  },
})
