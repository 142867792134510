import React, { Component } from 'react'
import { uploadFiles } from "../../../api";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'

class DragAndDrop extends Component {
  state = {
    drag: false
  }
  upload = React.createRef()
  dropRef = React.createRef()
  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  dragCounter = 0
  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({drag: true})
    }
  }
  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({drag: false})
    }
  }
  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({drag: false})

    uploadFiles(e.dataTransfer.files)
  }
  componentDidMount() {
    let div = this.dropRef.current
    div.addEventListener('dragenter', this.handleDragIn)
    div.addEventListener('dragleave', this.handleDragOut)
    div.addEventListener('dragover', this.handleDrag)
    div.addEventListener('drop', this.handleDrop)
  }
  componentWillUnmount() {
    let div = this.dropRef.current
    div.removeEventListener('dragenter', this.handleDragIn)
    div.removeEventListener('dragleave', this.handleDragOut)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }

  onFileChange = (e) => {
      // this.setState({ files: e.target.files })
      uploadFiles(e.target.files)
  }

  uploadBtn = (e) => {
      this.upload.current.value = null
      e.preventDefault()
      this.upload.current.click()
  }

  render() {
    return (
      <div className="w-100 h-100"
        style={{display: 'inline-block', position: 'relative'}}
        ref={this.dropRef}
      >
        <div className="h-100" style={{border: 'dashed grey 2px', minHeight: "300px"}}>
        {this.state.drag &&
          <div
            style={{
              border: 'dashed grey 2px',
              backgroundColor: 'rgba(255,255,255,.8)',
              position: 'absolute',
              pointerEvents:"none",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 9999
            }}
          >
            <div className="position-absolute text-muted top-50 start-50 translate-middle">
              <h6>Zet het bestand hier neer.</h6>
            </div>
          </div>
        }
        <div style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}>
        <div className="position-absolute top-50 start-50 translate-middle text-center">
          <FontAwesomeIcon icon={faUpload} className="text-muted mb-2" size="4x"/>
          <h6 className="d-block mb-2 text-muted">Sleep bestanden om te uploaden</h6>
          <div className="d-block"><h6 className=" d-inline mb-2 text-muted">of klik op </h6><button className="btn ms-2 btn-outline-primary" onClick={this.uploadBtn}>Upload</button></div>
          <span className='text-muted small'>(.ai, .eps, of .dxf)</span>
          <input type="file" className="d-none" ref={this.upload} onChange={this.onFileChange} multiple />

          {/*<div><Button color="success" outline>Upload</Button></div>*/}
        </div>
        </div>
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  const design = state.design;
  const checkout = state.checkout;
  const settings = state.settings;

  return {
    settings,
    design,
    checkout
  };
}



export default connect(mapStateToProps)(DragAndDrop);
