import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  TabContent,
  TabPane,
  Fade,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link, useLocation, useParams, Navigate } from "react-router-dom";
import * as d3 from "d3";
import { SlideDown } from "../../components/SlideDown";

import {
  setField,
  validate,
  setDifferentInvoiceAddress,
  setCompany,
  back,
  checkoutData,
  resetVat,
} from "../../store/checkout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faInfoCircle,
  faTimes,
  faPlus,
  faMinus,
  faTruck,
  faInfo,
  faCircleNotch,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

import FadeInOut from "./FadeInOut";
import CheckOperations from "./CheckOperations";
import Toast from "../Analyzer/components/Toast";
import Loader from "../Analyzer/components/Loader";

import { getCoupon, loadCheckout, checkout, getVat } from "../../api";
import Coupon from "./Coupon";
import Designs from "./Designs";
import Error404 from "../../components/error404";
import Error410 from "../../components/error410";
import Announcement from "../../components/announcement";
import { ToastContainer, toast } from "react-toastify";
import GTM from "../../gtm";

const fastDeliveryDisabled = false; // Disables fast delivery (also disable in backend and mateials)

//Utility

function roundTo(value, decimals) {
  return Number(
    Math.round(+value.toFixed(11) + "e" + decimals) + "e-" + decimals
  ).toFixed(2);
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { height, width };
}

class Address extends Component {
  fieldChange = (ev) => {
    const { name, value } = ev.target;
    this.props.dispatch(
      setField({
        type: "data",
        group: this.props.prefix,
        field: name,
        value: value,
      })
    );
  };

  getVat = () => {
    if (this.props.data.vatNumber.checking) {
      return;
    }
    if (this.props.data.vatNumber.disabled) {
      this.props.dispatch(resetVat());
    } else {
      this.props.dispatch(getVat(this.props.data.vatNumber.value));
    }
  };
  render() {
    if (this.props.isOpen == false) {
      return "";
    }
    const {
      firstname,
      lastname,
      company,
      address,
      zipcode,
      city,
      country,
      vatNumber,
      reference,
    } = this.props.data;
    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <h5 className="fw-normal">{this.props.title}</h5>
          </Col>
          <Col xs={6}>
            <FormGroup floating>
              <Input
                id={this.props.prefix + "_firstname"}
                placeholder="Voornaam*"
                type="text"
                name="firstname"
                onChange={this.fieldChange}
                invalid={!firstname.valid ? "true" : ""}
                value={firstname.value}
                required={firstname.required}
              />
              <Label for={this.props.prefix + "_firstname"}>Voornaam*</Label>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup floating>
              <Input
                id={this.props.prefix + "_lastname"}
                placeholder="Achternaam*"
                type="text"
                name="lastname"
                onChange={this.fieldChange}
                invalid={!lastname.valid ? "true" : ""}
                value={lastname.value}
                required={lastname.required}
              />
              <Label id={this.props.prefix + "_lastname"}>Achternaam*</Label>
            </FormGroup>
          </Col>
          {this.props.prefix == "invoice" ? (
            <Col xs={12}>
              <div className="form-check form-switch mx-auto">
                <Input
                  type="checkbox"
                  id="isCompany"
                  onChange={(e) => {
                    this.props.dispatch(
                      setCompany({ checked: e.target.checked })
                    );
                  }}
                  checked={this.props.isCompany}
                />
                <Label className="form-check-label" for={"isCompany"}>
                  Zakelijk
                  <FontAwesomeIcon
                    id="companySwitch"
                    style={{ outline: "none", pointerEvents: "all" }}
                    className="text-info ms-1"
                    icon={faInfoCircle}
                  />
                </Label>
                <UncontrolledTooltip target="companySwitch">
                  Een zakelijke aankoop biedt je de mogelijkheid om op rekening
                  af te rekenen en om, indien van toepassing, de BTW te
                  verleggen.
                </UncontrolledTooltip>
              </div>
              <SlideDown closed={!this.props.isCompany}>
                <div className="">
                  <Row>
                    <Col xs={9}>
                      <FormGroup floating>
                        <Input
                          id={this.props.prefix + "_vatNumber"}
                          placeholder="BTW nummer"
                          type="text"
                          name="vatNumber"
                          value={vatNumber.value}
                          onChange={this.fieldChange}
                          required={vatNumber.required}
                          disabled={vatNumber.disabled ? "true" : ""}
                        />
                        <Label id={this.props.prefix + "_vatNumber"}>
                          BTW nummer{" "}
                          <FontAwesomeIcon
                            id="VAT-number-tooltip"
                            style={{ outline: "none", pointerEvents: "all" }}
                            className="text-info"
                            icon={faInfoCircle}
                          />
                        </Label>
                        <UncontrolledTooltip target="VAT-number-tooltip">
                          De bedrijfsinformatie gekoppeld aan het BTW nummer
                          wordt automatisch opgehaald uit de Europese databank.
                        </UncontrolledTooltip>
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <Button
                        color={!vatNumber.valid ? "danger" : "primary"}
                        className="w-100 rounded"
                        onClick={this.getVat}
                        style={{ height: "50px" }}
                      >
                        {vatNumber.checking == false ? (
                          vatNumber.disabled ? (
                            <span>Reset</span>
                          ) : (
                            <span>Controleren</span>
                          )
                        ) : (
                          <FontAwesomeIcon
                            icon={faCircleNotch}
                            style={{ fontSize: "36px" }}
                            spin
                          />
                        )}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup floating>
                        <Input
                          id={this.props.prefix + "_company"}
                          placeholder="Bedrijfsnaam"
                          type="text"
                          name="company"
                          onChange={this.fieldChange}
                          invalid={!company.valid ? "true" : ""}
                          value={company.value}
                          required={company.required}
                          disabled={company.disabled ? "true" : ""}
                        />
                        <Label id={this.props.prefix + "_company"}>
                          Bedrijfsnaam*
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className="pb-3 form-floating ">
                        <Input
                          id={this.props.prefix + "_reference"}
                          placeholder="Referentie"
                          type="text"
                          name="reference"
                          onChange={this.fieldChange}
                          invalid={!reference.valid ? "true" : ""}
                          required={reference.required}
                        />
                        <Label id={this.props.prefix + "_reference"}>
                          Referentie
                        </Label>
                      </div>
                    </Col>
                  </Row>
                </div>
              </SlideDown>
            </Col>
          ) : (
            <Col xs={12}>
              <FormGroup floating>
                <Input
                  id={this.props.prefix + "_company"}
                  placeholder="Bedrijfsnaam"
                  type="text"
                  name="company"
                  onChange={this.fieldChange}
                  invalid={!company.valid ? "true" : ""}
                  value={company.value}
                  required={company.required}
                />
                <Label id={this.props.prefix + "_company"}>Bedrijfsnaam</Label>
              </FormGroup>
            </Col>
          )}

          <Col xs={12}>
            <FormGroup floating>
              <Input
                id={this.props.prefix + "_address"}
                placeholder="Adres*"
                type="text"
                name="address"
                onChange={this.fieldChange}
                invalid={!address.valid ? "true" : ""}
                value={address.value}
                required={address.required}
                disabled={address.disabled ? "true" : ""}
              />
              <Label id={this.props.prefix + "_address"}>Adres*</Label>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup floating>
              <Input
                id={this.props.prefix + "_zipcode"}
                placeholder="Postcode*"
                type="text"
                name="zipcode"
                onChange={this.fieldChange}
                invalid={!zipcode.valid ? "true" : ""}
                value={zipcode.value}
                required={zipcode.required}
                disabled={zipcode.disabled ? "true" : ""}
              />
              <Label id={this.props.prefix + "_Postcode"}>Postcode*</Label>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup floating>
              <Input
                id={this.props.prefix + "_city"}
                placeholder="Plaats*"
                type="text"
                name="city"
                onChange={this.fieldChange}
                invalid={!city.valid ? "true" : ""}
                value={city.value}
                required={city.required}
                disabled={city.disabled ? "true" : ""}
              />
              <Label id={this.props.prefix + "_city"}>Plaats*</Label>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup floating>
              <Input
                id={this.props.prefix + "_country"}
                placeholder="Land*"
                type="select"
                name="country"
                onChange={this.fieldChange}
                invalid={!country.valid ? "true" : ""}
                value={country.value}
                required={country.required}
                disabled={country.disabled ? "true" : ""}
              >
                <option value="BE">België</option>
                <option value="NL">Nederland</option>
                <option value="DE">Duitsland</option>
              </Input>
              <Label id={this.props.prefix + "_country"}>Land*</Label>
            </FormGroup>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

class Info extends Component {
  state = {
    diff: false,
    CheckOperations: false,
  };

  fieldChange = (ev) => {
    const { name, value, checked } = ev.target;
    if (name == "operations" || name == "terms") {
      this.props.dispatch(
        setField({
          type: "data",
          field: name,
          value: checked,
        })
      );
    } else {
      this.props.dispatch(
        setField({
          type: "data",
          field: name,
          value: value,
        })
      );
    }
  };

  reduce = (obj) => {
    var data = {};
    Object.keys(obj).forEach((key, i) => {
      data[key] = obj[key].value;
    });
    return data;
  };

  formatData = async (ev) => {
    await this.props.dispatch(validate({ type: "data" }));
    if (this.props.valid == false) {
      return;
    }
    const { data, shipping, coupon } = this.props.checkout;
    var invoice = this.reduce(data.invoice);
    var vat = invoice.vatNumber;
    var reference = invoice.reference;
    delete invoice.reference;
    delete invoice.vatNumber;
    var shippingAddress = undefined;
    if (data.differentInvoiceAddress) {
      shippingAddress = {
        email: data.email.value,
        phone: data.number.value,
        ...this.reduce(data.shipping),
      };
    }
    var checkoutObject = {
      type: ev.target.getAttribute("data-checkout"),
      vat: vat,
      reference: reference,
      invoice: {
        email: data.email.value,
        phone: data.number.value,
        ...invoice,
      },
      code: coupon.code,
      shippingAddress: shippingAddress,
      ...this.reduce(shipping),
    };
    checkout(this.props.designGroup, checkoutObject);
  };

  CheckOperations = (open) => {
    this.setState({ CheckOperations: open });
  };

  render() {
    const {
      email,
      number,
      shipping,
      invoice,
      differentInvoiceAddress,
      isCompany,
      terms,
      operations,
    } = this.props.data;
    return (
      <React.Fragment>
        <CheckOperations
          isOpen={this.state.CheckOperations}
          close={this.CheckOperations}
          data={this.props.designData}
        />
        <h4 className="fw-normal">Gegevens </h4>
        <Row className="my-3">
          <Col xs={12}>
            <FormGroup floating>
              <Input
                id="email"
                name="email"
                placeholder="Email*"
                type="email"
                invalid={!email.valid ? "true" : ""}
                value={email.value}
                required={email.required}
                onChange={this.fieldChange}
              />
              <Label for="email">Email{email.required ? "*" : ""}</Label>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup floating>
              <Input
                id="number"
                placeholder="Telefoonnummer*"
                type="text"
                name="number"
                onChange={this.fieldChange}
                invalid={!number.valid ? "true" : ""}
                value={number.value}
                required={number.required}
              />
              <Label for="number">
                Telefoonnummer{number.required ? "*" : ""}
              </Label>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <Address
              dispatch={this.props.dispatch}
              isCompany={isCompany}
              data={invoice}
              title={differentInvoiceAddress ? "Factuuradres" : "Adres"}
              prefix="invoice"
            />
          </Col>
          <Col xs={12}>
            <div className="form-check form-switch mx-auto">
              <Input
                type="checkbox"
                id="diff"
                onChange={(e) => {
                  this.props.dispatch(
                    setDifferentInvoiceAddress({ checked: e.target.checked })
                  );
                }}
                checked={differentInvoiceAddress}
              />
              <Label className="form-check-label" for={"diff"}>
                Afwijkend leveradres
              </Label>
            </div>
          </Col>
          <Col xs={12}>
            <SlideDown closed={!differentInvoiceAddress}>
              <Address
                dispatch={this.props.dispatch}
                isCompany={isCompany}
                data={shipping}
                title="Leveradres"
                prefix="shipping"
                isOpen={true}
              />
            </SlideDown>
          </Col>
        </Row>
        <div>
          <FormGroup check inline>
            <Input
              id="terms"
              type="checkbox"
              name="terms"
              checked={terms.value}
              invalid={!terms.valid ? "true" : ""}
              required={terms.required}
              onChange={this.fieldChange}
            />
            <Label check for="terms">
              Ik ga akkoord met de{" "}
              <a
                href="https://cdn.shopify.com/s/files/1/0025/2058/0194/files/algemene_voorwaarden_2019.pdf"
                target="_blank"
              >
                algemene voorwaarden
              </a>
              *
            </Label>
          </FormGroup>
        </div>
        <div>
          <FormGroup check inline>
            <Input
              type="checkbox"
              id="operations"
              name="operations"
              checked={operations.value}
              invalid={!operations.valid ? "true" : ""}
              required={operations.required}
              onChange={this.fieldChange}
            />
            <Label check for="operations">
              Ik heb de gekozen{" "}
              <a
                className="btn btn-link align-baseline p-0 link-primary"
                onClick={() => {
                  this.CheckOperations(true);
                }}
                target="_blank"
              >
                bewerkingen en materialen
              </a>{" "}
              gecontroleerd*
            </Label>
          </FormGroup>
        </div>
        <Button
          className="me-2"
          data-checkout="direct"
          onClick={this.formatData}
          color="success"
          outline
        >
          Afrekenen
        </Button>
        {isCompany ? (
          <>
            <Button
              className="me-2"
              data-checkout="on_account"
              onClick={this.formatData}
              disabled={invoice.vatNumber.disabled ? false : true}
              color="info"
              outline
            >
              Op rekening afrekenen
              <FontAwesomeIcon
                id="on_account"
                style={{ outline: "none", pointerEvents: "all" }}
                className="text-info ms-1"
                icon={faInfoCircle}
              />
              <UncontrolledTooltip target="on_account">
                Alleen beschikbaar als er een geldig BTW nummer is ingevoerd.
              </UncontrolledTooltip>
            </Button>
          </>
        ) : null}

        <span
          role="button"
          className="text-decoration-underline"
          onClick={() => {
            this.props.dispatch(back());
          }}
        >
          Terug naar leveropties
        </span>
      </React.Fragment>
    );
  }
}

class Shipping extends Component {
  fieldChange = (ev) => {
    const { name, value } = ev.target;
    this.props.dispatch(
      setField({
        type: "shipping",
        field: name,
        value: value,
      })
    );
  };

  render() {
    const checkout = this.props.checkout;
    const { production, comments, recycle, shipping } = checkout.shipping;
    const { shipCat, data } = checkout.checkoutData;
    const shipType = Math.max(
      ...checkout.checkoutData.data.map((design) =>
        parseInt(design.material.delivery)
      )
    );
    const operations = data.reduce(
      (acc, val) => acc + parseFloat(val.qty * val.price.operations),
      0
    );
    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <h4 className="fw-normal">Leveropties</h4>
          </Col>
          <Col xs={12}>
            <Label>Productietijd</Label>
            <Row className="mb-2">
              <Col xs={12}>
                <div className="rounded border mb-2 p-2">
                  <FormGroup check className="mb-0">
                    <input
                      className="form-check-input"
                      name="production"
                      id="production-standard"
                      type="radio"
                      value="standard"
                      invalid={!production.valid ? "true" : "false"}
                      required={production.required}
                      checked={production.value == "standard"}
                      onChange={this.fieldChange}
                    />

                    <Label
                      className="mb-0 me-auto d-block"
                      for="production-standard"
                      check
                    >
                      Standaard (4-5 werkdagen)
                    </Label>
                  </FormGroup>
                </div>
                <div className="rounded border mb-2 p-2">
                  <FormGroup check className="mb-0">
                    <input
                      className="form-check-input"
                      name="production"
                      id="production-fast"
                      type="radio"
                      value="fast"
                      invalid={!production.valid ? "true" : "false"}
                      required={production.required}
                      checked={production.value == "fast"}
                      onChange={this.fieldChange}
                      disabled={shipType == 1 || fastDeliveryDisabled}
                    />

                    <Label
                      className="mb-0 me-auto d-block"
                      for="production-fast"
                      check
                    >
                      <Row>
                        <Col>Snel (1-2 werkdagen)</Col>
                        <Col className="d-flex justify-content-end">
                          <span className="me-2">
                            +&euro;{" "}
                            {roundTo((shipCat.fast - 1) * operations, 2)}
                            {shipType == 1 ? (
                              <React.Fragment>
                                <FontAwesomeIcon
                                  id="production-fast-tooltip"
                                  className="ms-1 text-info"
                                  style={{ outline: "none" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                  icon={faInfoCircle}
                                />
                                <UncontrolledTooltip target="production-fast-tooltip">
                                  Deze bestelling bevat een of meerdere
                                  materialen die niet direct uit voorraad
                                  leverbaar zijn, snelle levering is helaas niet
                                  mogelijk.
                                </UncontrolledTooltip>
                              </React.Fragment>
                            ) : null}
                          </span>
                        </Col>
                      </Row>
                    </Label>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Label>Recyclen</Label>
            <FontAwesomeIcon
              id="recycle"
              className=" ms-1 text-info"
              style={{ outline: "none" }}
              onClick={(e) => {
                e.preventDefault();
              }}
              icon={faInfoCircle}
            />
            <UncontrolledTooltip target="recycle">
              In sommige gevallen leveren we niet of juist wel de restplaat
              indien de delen daardoor minder risico lopen op beschadigingen
              tijdens transport.
            </UncontrolledTooltip>
            <Row className="mb-2">
              <Col xs={12}>
                <div className="rounded border mb-2 p-2">
                  <FormGroup check className="mb-0">
                    <input
                      className="form-check-input"
                      name="recycle"
                      id="recycle-all"
                      type="radio"
                      value="all"
                      invalid={!recycle.valid ? "true" : ""}
                      required={recycle.required}
                      checked={recycle.value == "all"}
                      onChange={this.fieldChange}
                    />

                    <Label
                      className="mb-0 me-auto d-block"
                      for="recycle-all"
                      check
                    >
                      <Row>
                        <Col>Recyclen</Col>
                        <Col className="d-flex justify-content-end">
                          <span className="me-2">
                            <FontAwesomeIcon
                              id="recycle-all-tooltip"
                              className="text-info"
                              style={{ outline: "none" }}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                              icon={faInfoCircle}
                            />
                            <UncontrolledTooltip target="recycle-all-tooltip">
                              We recyclen alle delen die niet nodig zijn, indien
                              het niet duidelijk is of het restmateriaal is
                              leveren we het voor de zekerheid.
                            </UncontrolledTooltip>
                          </span>
                        </Col>
                      </Row>
                    </Label>
                  </FormGroup>
                </div>
                <div className="rounded border mb-2 p-2">
                  <FormGroup check className="mb-0">
                    <input
                      className="form-check-input"
                      id="recycle-outside"
                      name="recycle"
                      type="radio"
                      value="outside"
                      invalid={!recycle.valid ? "true" : ""}
                      required={recycle.required}
                      checked={recycle.value == "outside"}
                      onChange={this.fieldChange}
                    />

                    <Label
                      className="mb-0 me-auto d-block"
                      for="recycle-outside"
                      check
                    >
                      <Row>
                        <Col>Binnenvormen leveren</Col>
                        <Col className="d-flex justify-content-end">
                          <span className="me-2">
                            <FontAwesomeIcon
                              id="recycle-outside-tooltip"
                              className="text-info"
                              style={{ outline: "none" }}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                              icon={faInfoCircle}
                            />
                            <UncontrolledTooltip target="recycle-outside-tooltip">
                              We recyclen alleen het overige plaatmateriaal.
                              Uitsnijdes en de binnenvormen worden geleverd.
                            </UncontrolledTooltip>
                          </span>
                        </Col>
                      </Row>
                    </Label>
                  </FormGroup>
                </div>
                <div className="rounded border mb-2 p-2">
                  <FormGroup check className="mb-0">
                    <input
                      className="form-check-input"
                      id="recycle-none"
                      name="recycle"
                      type="radio"
                      value="none"
                      invalid={!recycle.valid ? "true" : ""}
                      required={recycle.required}
                      checked={recycle.value == "none"}
                      onChange={this.fieldChange}
                    />

                    <Label
                      className="mb-0 me-auto d-block"
                      for="recycle-none"
                      check
                    >
                      <Row>
                        <Col>Alles leveren</Col>
                        <Col className="d-flex justify-content-end">
                          <span className="me-2">
                            <FontAwesomeIcon
                              id="recycle-none-tooltip"
                              className="text-info"
                              style={{ outline: "none" }}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                              icon={faInfoCircle}
                            />
                            <UncontrolledTooltip target="recycle-none-tooltip">
                              We leveren alle delen en het overige
                              plaatmateriaal.
                            </UncontrolledTooltip>
                          </span>
                        </Col>
                      </Row>
                    </Label>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Label>Verzendmethode</Label>
            <Row className="mb-2">
              <Col xs={12}>
                <div className="rounded border mb-2 p-2">
                  <FormGroup check className="mb-0">
                    <input
                      className="form-check-input"
                      name="shipping"
                      id="shipping-pickup"
                      type="radio"
                      value="pickup"
                      invalid={!shipping.valid ? "true" : ""}
                      required={shipping.required}
                      checked={shipping.value == "pickup"}
                      onChange={this.fieldChange}
                    />

                    <Label
                      className="mb-0 me-auto d-block"
                      for="shipping-pickup"
                      check
                    >
                      <Row>
                        <Col>Ophalen</Col>
                        <Col className="d-flex justify-content-end">
                          <span className="me-2">Gratis</span>
                        </Col>
                      </Row>
                    </Label>
                  </FormGroup>
                </div>
              </Col>
              <Col xs={12} className="">
                <div className="rounded border mb-2 p-2">
                  <FormGroup check className="mb-0">
                    <input
                      className="form-check-input"
                      name="shipping"
                      id="shipping-ship"
                      type="radio"
                      value="ship"
                      invalid={!shipping.valid ? "true" : ""}
                      required={shipping.required}
                      checked={shipping.value == "ship"}
                      onChange={this.fieldChange}
                    />

                    <Label
                      className="mb-0 me-auto d-block"
                      for="shipping-ship"
                      check
                    >
                      <Row>
                        <Col xs={9}>
                          Verzenden (+1 werkdag NL, +1-3 werkdagen BE,DE)
                        </Col>
                        <Col xs={3} className="d-flex justify-content-end">
                          <span className="me-2">
                            {checkout.coupon.discount == "shipping" ? (
                              "Gratis"
                            ) : (
                              <React.Fragment>
                                &euro;{" "}
                                {roundTo(checkout.checkoutData.shipping, 2)}
                              </React.Fragment>
                            )}
                          </span>
                        </Col>
                      </Row>
                    </Label>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <FormGroup floating>
              <Input
                id="comment"
                placeholder="Opmerkingen"
                type="textarea"
                name="comments"
                invalid={!comments.valid ? "true" : ""}
                required={comments.required}
                value={comments.value}
                onChange={this.fieldChange}
              />
              <Label for="comment">Opmerkingen</Label>
            </FormGroup>
          </Col>
        </Row>
        <Button
          onClick={() => {
            this.props.dispatch(validate({ type: "delivery" }));
          }}
          color="success"
          outline
        >
          Mijn Gegevens
        </Button>
      </React.Fragment>
    );
  }
}

class Analyzer extends Component {
  state = {
    show: true,
    loaded: false,
    notice: true,
    diff: false,

    error: false,
    preview: false,
    activeTab: 1,
    animating: 0,
    design: null,
    activeDesign: "",
    coupon: "",
    couponValid: true,
    designs: [
      {
        qty: 5,
        img: "/5prhukv02.svg",
      },
      {
        qty: 5,
        img: "/5prhukv02.svg",
      },
      {
        qty: 5,
        img: "/5prhukv02.svg",
      },
      {
        qty: 5,
        img: "/5prhukv02.svg",
      },
      {
        qty: 5,
        img: "/5prhukv02.svg",
      },
    ],
    isMobile: getWindowDimensions().width < 992,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    loadCheckout(this.props.params.designGroup);

    //Send conversion of checkout page reached to GTM
    GTM.sendEvent("Checkout");

    // document.getElementById("root").style = "height:auto;"
  }

  componentWillUnmount() {
    this.props.dispatch(checkoutData(null));
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) == JSON.stringify(this.props)) return;

    if (this.props.design == null || !this.props.design.loaded)
      this.setState({ activeTab: 1 });
  }

  handleResize = () => {
    this.setState({ isMobile: getWindowDimensions().width < 992 });
  };

  getCoupon = () => {
    if (this.state.coupon == "") {
      this.setState({ couponValid: false });
      return;
    }
    this.props.dispatch(getCoupon(this.state.coupon));
  };

  render() {
    if (this.props.settings.error == 404) {
      return <Error404 />;
    } else if (this.props.settings.error == 410) {
      return <Error410 />;
    }

    const { checkout } = this.props;
    const { VATreverseCharge } = checkout;
    if (checkout.checkoutData == null) {
      return (
        <Loader
          text={
            this.props.settings.error
              ? this.props.settings.error
              : "Data aan het laden..."
          }
        />
      );
    }
    if (checkout.checkoutData.redirect) {
      return <Navigate to={"/designs" + checkout.checkoutData.redirect} />;
    }
    const coupon = checkout.coupon;
    const { data, shipCat } = checkout.checkoutData;
    const shipText =
      checkout.shipping.shipping.value == "ship"
        ? coupon.discount == "shipping"
          ? "Gratis verzending"
          : "Verzending"
        : "Pickup";
    const shipVal =
      checkout.shipping.shipping.value == "ship"
        ? coupon.discount == "shipping"
          ? 0
          : checkout.checkoutData.shipping
        : 0;
    const operations = data.reduce(
      (acc, val) => acc + parseFloat(val.qty * val.price.operations),
      0
    );
    var discountVal =
      coupon.discount == "percentage"
        ? -roundTo((coupon.value / 100) * operations, 2)
        : coupon.discount == "amount"
        ? -coupon.value
        : 0;
    const subtotal = data.reduce(
      (acc, val) => acc + parseFloat(val.price.total.value),
      0
    );

    const surcharge =
      (shipCat[checkout.shipping.production.value] - 1) * operations;
    if (-discountVal > subtotal + surcharge) {
      discountVal = -(subtotal + surcharge);
    }
    const total = subtotal + discountVal + shipVal + surcharge;
    return (
      <React.Fragment>
        <Announcement />
        <div className="checkoutBg">
          <Container fluid="xl" className="position-relative">
            <Row className="">
              <Col lg={6} className="checkout pb-4">
                <img
                  style={{ height: "80px" }}
                  className={"my-0 mb-3 w-md-50 mr-auto font-weight-normal "}
                  src={"/logo-dark.png"}
                />
                {!this.props.params.designGroup.startsWith("quote_") && (
                  <div className="d-block">
                    <Button
                      tag={Link}
                      to={`/designs/${this.props.params.designGroup}`}
                      className="mb-2"
                      color="primary"
                      outline
                      size="sm"
                    >
                      <FontAwesomeIcon icon={faArrowLeft} /> Terug naar
                      ontwerpen
                    </Button>
                  </div>
                )}
                <FadeInOut show={checkout.page == 2} duration={250}>
                  <Info
                    designGroup={this.props.params.designGroup}
                    dispatch={this.props.dispatch}
                    designData={data}
                    checkout={checkout}
                    valid={checkout.valid}
                    data={checkout.data}
                  />
                </FadeInOut>
                <FadeInOut show={checkout.page == 1} duration={250}>
                  <Shipping
                    designGroup={this.props.params.designGroup}
                    checkout={checkout}
                    dispatch={this.props.dispatch}
                  />
                </FadeInOut>
              </Col>
              <Col
                lg={6}
                className="border-start checkout position-relative sidebar"
              >
                <Designs />
              </Col>
            </Row>
          </Container>
        </div>
        {/*}<Toast/>*/}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const design = state.design;
  const checkout = state.checkout;
  const settings = state.settings;

  return {
    settings,
    design,
    checkout,
  };
}

const withRouter = (Component) => {
  const Wrapper = (props) => {
    const location = useLocation();
    const params = useParams();

    return <Component location={location} params={params} {...props} />;
  };

  return Wrapper;
};

export default withRouter(connect(mapStateToProps)(Analyzer));

// export default Analyzer;
