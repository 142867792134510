import store from "../store";
import React from "react";
import { loadMaterials } from "../store/materials";
import { load, loading, updateLayers, unloadDesign } from "../store/design";
import {
  addDesigns,
  removeDesign,
  updateDesigns,
  loadDesigns,
  closeEmptyFiles,
  loadEmptyFiles,
} from "../store/designs";
import {
  setCoupon,
  CouponLoading,
  checkoutData,
  setChecking,
  setVat,
  setEmptyVatNumber,
} from "../store/checkout";
import { quoteData, quoteSubmit, quoteLoading } from "../store/quote";

import {
  closeNotification,
  removeNotification,
  setDesigngroup,
  error,
  setAnnouncement,
} from "../store/settings";
import { toast } from "react-toastify";

const createNotification = (data, timer) => {
  toast[data.type](
    <React.Fragment>
      <div className="toast-header p-0" style={{ fontSize: "15px" }}>
        <strong className="me-auto p-0">{data.title}</strong>
      </div>
      <div className="toast-body pt-1 p-0" style={{ fontSize: "14px" }}>
        {data.text}
      </div>
    </React.Fragment>,
    {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "pe-auto",
    }
  );
};

const body = (title, text) => {
  return (
    <React.Fragment>
      <div className="toast-header p-0" style={{ fontSize: "15px" }}>
        <strong className="me-auto p-0">{title}</strong>
      </div>
      <div className="toast-body pt-1 p-0" style={{ fontSize: "14px" }}>
        {text}
      </div>
    </React.Fragment>
  );
};

const uploadFiles = async (files) => {
  var formdata = new FormData();
  let errors = [];
  let fileTypes = ["ai", "dxf", "eps"];
  for (const key of Object.keys(files)) {
    if (!fileTypes.includes(files[key].name.split(".").pop().toLowerCase())) {
      errors.push(files[key].name);
    } else {
      formdata.append("files", files[key]);
    }
  }
  if (errors.length > 0) {
    createNotification(
      {
        type: "error",
        title: "Bestandstype niet ondersteund",
        text: `${errors.length} bestand${
          errors.length > 1 ? "en" : ""
        } (${errors.join(", ")}) ${
          errors.length > 1 ? "hebben" : "heeft"
        } een extensie die niet ondersteund wordt, we accepteren alleen .ai, .dxf en .eps.`,
      },
      5000
    );
    if (errors.length === files.length) {
      return;
    }
  }
  let sid = window.io_sid;
  formdata.append("sid", sid);

  toast.loading(
    body(
      "Uploaden",
      `${files.length} bestand${
        files.length === 1 ? "" : "en"
      } aan het uploaden (0%)`
    ),
    { toastId: "upload", className: "clickThrough" },
    { type: "loading" }
  );
  // store.dispatch(uploading({show:true,title:"Uploaden", text:`${files.length} bestand${files.length==1?"":"en"} aan het uploaden (0%)`}))

  let request = new XMLHttpRequest();
  var designGroup = store.getState().settings.designGroup;

  request.open(
    "POST",
    process.env.REACT_APP_API +
      "/api/upload" +
      (designGroup != null ? "/" + designGroup : ""),
    true
  );
  request.withCredentials = true;

  request.upload.addEventListener("progress", function (e) {
    let percent_completed = (e.loaded / e.total) * 100;
    toast.update("upload", {
      render: body(
        "Uploaden",
        `${files.length} bestand${
          files.length === 1 ? "" : "en"
        } aan het uploaden (${percent_completed.toFixed(1)}%)`
      ),
    });
    // store.dispatch(uploading({show:true,title:"Uploaden", text:`${files.length} bestand${files.length==1?"":"en"} aan het uploaden (${percent_completed.toFixed(1)}%)`}))
  });

  request.addEventListener("load", function (e) {
    // store.dispatch(uploading({show:false,title:"", text:``}))
    var notification;
    if (request.status >= 400 && request.status < 500) {
      notification = {
        title: "Er is een fout opgetreden",
        text: request.response,
      };
    } else if (request.status >= 500 && request.status < 600) {
      notification = {
        title: "Server fout",
        text: request.response,
      };
      console.error("server fout");
    }

    if (request.status !== 200) {
      toast.update("upload", {
        type: "error",
        render: body(notification.title, notification.text),
        className: "pe-auto",
        isLoading: false,
        draggable: true,
        closeOnClick: true,
        autoClose: 5000,
        closeButton: true,
        delay: 0,
      });
      return;
    } else {
      toast.dismiss("upload");
    }
    store.dispatch(closeEmptyFiles());
    let data = JSON.parse(request.response);
    if (data.designGroup) {
      window.history.pushState({}, "", `/designs/${data.designGroup}`);
      store.dispatch(setDesigngroup(data.designGroup));
    }
    store.dispatch(loadEmptyFiles(data.emptyFiles));
    for (var i in data.errorFiles) {
      createNotification(
        {
          type: data.errorFiles[i].type,
          title: data.errorFiles[i].title,
          text: data.errorFiles[i].text,
        },
        5000
      );
    }
    store.dispatch(addDesigns(data.data));
    // store.dispatch(uploading({show:false}))
  });

  request.addEventListener("error", function (e) {
    // store.dispatch(uploading({show:false,title:"", text:``}))
    toast.update("upload", {
      type: "error",
      render:
        "Kan geen verbinding maken met server, probeer het later opnieuw.",
      className: "pe-auto",
      isLoading: false,
      draggable: true,
      closeOnClick: true,
      autoClose: 5000,
      closeButton: true,
      delay: 10,
    });
    console.error(e);
  });

  // send POST request to server
  request.send(formdata);
  return 0;
};

const loadDesignGroup = async (designGroup) => {
  try {
    store.dispatch(error(null));

    // store.dispatch(loadDesigns([]))
    if (designGroup === undefined) {
      store.dispatch(loadDesigns([]));
      return;
    } else {
      store.dispatch(loadDesigns(null));
    }
    var res = await fetch(
      process.env.REACT_APP_API + "/api/designs/" + designGroup
    );

    if (res.status === 404) {
      //window.location = "/"
      store.dispatch(error(404));
    } else if (res.status === 410) {
      //window.location = "/"
      store.dispatch(error(410));
    } else if (res.status !== 200) {
      store.dispatch(
        error("Er is een server fout opgetreden, probeer het later opnieuw")
      );
    }

    if (res.status !== 200) {
      return -1;
    }
    var json = await res.json();
    store.dispatch(setDesigngroup(designGroup));
    store.dispatch(loadDesigns(json));
  } catch (err) {
    store.dispatch(
      error(
        "Kan geen verbinding maken met de server, probeer het later opnieuw"
      )
    );
    return 0;
  }
};

const getMaterials = async () => {
  try {
    let res = await fetch(process.env.REACT_APP_API + "/api/materials");

    if (res.status === 404) {
      //window.location = "/"
      store.dispatch(error(404));
    } else if (res.status === 410) {
      //window.location = "/"
      store.dispatch(error(410));
    } else if (res.status !== 200) {
      store.dispatch(
        error("Er is een server fout opgetreden, probeer het later opnieuw")
      );
    }

    if (res.status !== 200) {
      return -1;
    }
    var json = await res.json();
    store.dispatch(loadMaterials(json));
  } catch (err) {
    store.dispatch(
      error(
        "Kan geen verbinding maken met de server, probeer het later opnieuw"
      )
    );
    return 0;
  }
};

const getVat = async (vatNumber) => {
  if (vatNumber === "") {
    store.dispatch(setEmptyVatNumber(true));
    return;
  }
  store.dispatch(setChecking(true));

  var header = new Headers();
  header.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: header,
    redirect: "follow",
  };

  try {
    var response = await fetch(
      `${process.env.REACT_APP_API}/api/${
        "checkVat/" + encodeURIComponent(vatNumber)
      }`,
      requestOptions
    );
  } catch (err) {
    createNotification(
      {
        type: "error",
        title: "Netwerk fout",
        text: "Kan geen verbinding maken met server, probeer het later opnieuw.",
      },
      5000
    );
    store.dispatch(setChecking(false));
    return -1;
  }

  if (response.status >= 200 && response.status <= 299) {
    var json = await response.json();
    if (json.validated === false) {
      createNotification({
        type: "warning",
        title: "BTW-nummer niet geverifieerd",
        text: "We kunnen het BTW-nummer momenteel niet controleren, controleer zelf de gegevens nogmaals",
      });
      if (json.country === "DE" || json.country === "BE") {
        createNotification(
          {
            type: "success",
            title: "BTW verlegd",
            text: "BTW wordt verlegd.",
          },
          5000
        );
      }
      delete json.validated;
    } else if (json.country === "DE" || json.country === "BE") {
      createNotification(
        {
          type: "success",
          title: "BTW-nummer Geldig",
          text: "Het BTW-nummer is geldig, BTW wordt automatisch verlegd.",
        },
        5000
      );
    }
    if (json.validated) {
      delete json.validated;
    }
    delete json.msg;
    store.dispatch(setChecking(false));
    store.dispatch(setVat(json));
  } else {
    await errorHandle(response);
  }
  store.dispatch(setChecking(false));
  return 0;
};

const getAnnouncement = async () => {
  try {
    var response = await fetch(`${process.env.REACT_APP_API}/api/announcement`);
  } catch (err) {
    createNotification(
      {
        type: "error",
        title: "Netwerk fout",
        text: "Kan geen verbinding maken met server, probeer het later opnieuw.",
      },
      5000
    );
    store.dispatch(setChecking(false));
    return -1;
  }

  if (response.status >= 200 && response.status <= 299) {
    let json = await response.json();

    store.dispatch(setAnnouncement(json));
  } else {
    await errorHandle(response);
  }
  return 0;
};

const loadDesign = async (ident) => {
  try {
    store.dispatch(loading());
    var designGroup = store.getState().settings.designGroup;
    fetch(
      process.env.REACT_APP_API + "/api/designs/" + designGroup + "/" + ident
    )
      .then(async (res) => {
        let notification;
        if (res.status === 404) {
          //window.location = "/"
          //store.dispatch(error(404))
          throw new Error("error");
        } else if (res.status === 406) {
          //alert(JSON.stringify(res.text()))
          return res.json();
        } else if (res.status >= 400 && res.status < 500) {
          notification = {
            title: "Er is een fout opgetreden",
            text: await res.text(),
          };
        } else if (res.status >= 500 && res.status < 600) {
          notification = {
            title: "Server fout",
            text: await res.text(),
          };
        }
        if (notification !== undefined) {
          createNotification(
            {
              type: "error",
              ...notification,
            },
            5000
          );
        }
        return res.json();
      })
      .then((json) => {
        store.dispatch(load(json));
      });
    return 0;
  } catch (err) {
    return 0;
  }
};

const loadCheckout = async (designGroup) => {
  try {
    fetch(process.env.REACT_APP_API + "/api/checkout/" + designGroup)
      .then(async (res) => {
        var notification;
        if (!res.ok) {
          if (res.status === 404) {
            //window.location = "/"
            store.dispatch(error(404));
            throw "err";
          } else if (res.status === 410) {
            //window.location = "/"
            store.dispatch(error(410));
            throw "err";
          } else if (res.status === 406) {
            //window.location = "/"
            return res.json();
          } else if (res.status >= 400 && res.status < 500) {
            notification = {
              title: "Er is een fout opgetreden",
              text: (await res.text()) + "",
            };
          } else if (res.status >= 500 && res.status < 600) {
            notification = {
              title: "Server fout",
              text: (await res.text()) + "",
            };
          }
          createNotification(
            {
              type: "error",
              ...notification,
            },
            5000
          );
        }
        return res.json();
      })
      .then((json) => {
        if (json.redirect) {
          createNotification(
            {
              type: "error",
              title: "Ontwerpgroep nog niet compleet",
              text: "Zorg dat alle ontwerpen geldig zijn. Je kan bij ongeldige ontwerpen over het rode info bolletje bewegen om te zien wat er ongeldig is.",
            },
            10000
          );

          store.dispatch(checkoutData(json));
          // window.location = "/designs"+json.redirect
          return 1;
        } else {
          store.dispatch(checkoutData(json));
        }
        //store.dispatch(checkoutData(json))
        return 1;
      })
      .catch((err) => {
        console.error(err);
        if (err != "err")
          store.dispatch(
            error(
              "Kan geen verbinding maken met de server, probeer het later opnieuw"
            )
          );
      });
  } catch (err) {
    console.error(err);
    if (err != "err")
      store.dispatch(
        error(
          "Kan geen verbinding maken met de server, probeer het later opnieuw"
        )
      );
    return 0;
  }
};

const loadquote = async (designGroup) => {
  try {
    fetch(process.env.REACT_APP_API + "/api/quote/" + designGroup)
      .then(async (res) => {
        var notification;
        if (!res.ok) {
          if (res.status === 404) {
            //window.location = "/"
            store.dispatch(error(404));
            throw "err";
          } else if (res.status === 410) {
            //window.location = "/"
            store.dispatch(error(410));
            throw "err";
          } else if (res.status === 406) {
            //window.location = "/"
            return res.json();
          } else if (res.status >= 400 && res.status < 500) {
            notification = {
              title: "Er is een fout opgetreden",
              text: (await res.text()) + "",
            };
          } else if (res.status >= 500 && res.status < 600) {
            notification = {
              title: "Server fout",
              text: (await res.text()) + "",
            };
          }
          createNotification(
            {
              type: "error",
              ...notification,
            },
            5000
          );
        }
        return res.json();
      })
      .then((json) => {
        if (json.redirect) {
          createNotification(
            {
              type: "error",
              title: "Ontwerpgroep nog niet compleet",
              text: "Zorg dat alle ontwerpen geldig zijn. Je kan bij ongeldige ontwerpen over het rode info bolletje bewegen om te zien wat er ongeldig is.",
            },
            10000
          );

          store.dispatch(quoteData(json));
          // window.location = "/designs"+json.redirect
          return 1;
        } else {
          store.dispatch(quoteData(json));
        }
        return 1;
      })
      .catch((err) => {
        console.error(err);
        if (err != "err")
          store.dispatch(
            error(
              "Kan geen verbinding maken met de server, probeer het later opnieuw"
            )
          );
      });
  } catch (err) {
    console.error(err);
    if (err != "err")
      store.dispatch(
        error(
          "Kan geen verbinding maken met de server, probeer het later opnieuw"
        )
      );
    return 0;
  }
};

const checkout = async (designGroup, data) => {
  var raw = JSON.stringify(data);
  var header = new Headers();
  header.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    body: raw,
    headers: header,
    redirect: "follow",
  };

  try {
    var response = await fetch(
      process.env.REACT_APP_API + "/api/checkout/" + designGroup,
      requestOptions
    );
  } catch (err) {
    createNotification(
      {
        type: "error",
        title: "Netwerk fout",
        text: "Kan geen verbinding maken met server, probeer het later opnieuw.",
      },
      5000
    );
    return -1;
  }

  if (response.status >= 200 && response.status <= 299) {
    var json = await response.json();
    window.location = json.redirect;
  } else {
    await errorHandle(response);
  }
};

export const quote = async (designGroup, data) => {
  var raw = JSON.stringify(data);
  var header = new Headers();
  header.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    body: raw,
    headers: header,
    redirect: "follow",
  };

  try {
    var response = await fetch(
      process.env.REACT_APP_API + "/api/quote/" + designGroup,
      requestOptions
    );
  } catch (err) {
    createNotification(
      {
        type: "error",
        title: "Netwerk fout",
        text: "Kan geen verbinding maken met server, probeer het later opnieuw.",
      },
      5000
    );
    return -1;
  }

  if (response.status >= 200 && response.status <= 299) {
    store.dispatch(quoteSubmit());
  } else {
    store.dispatch(quoteLoading(false));
    await errorHandle(response);
  }
};

const orderOperation = async (id, type, history) => {
  var requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  try {
    var response = await fetch(
      process.env.REACT_APP_API + "/api/details/" + id + "/" + type,
      requestOptions
    );
  } catch (err) {
    createNotification(
      {
        type: "error",
        title: "Netwerk fout",
        text: "Kan geen verbinding maken met server, probeer het later opnieuw.",
      },
      5000
    );
    return -1;
  }

  if (response.status >= 200 && response.status <= 299) {
    var json = await response.json();
    if (json.redirect) {
      window.location = json.redirect;
    } else {
      createNotification(
        {
          type: "success",
          title: "Bestelling verwijderd",
          text: "Je bestelling is succesvol verwijderd.",
        },
        5000
      );
      history("/");
    }
  } else {
    if (response.status === 400) {
      let json = await response.json();
      if (json.type === "paid") {
        window.location.reload();
      } else {
        createNotification(
          {
            type: "error",
            title: json.msg,
            text: "",
          },
          5000
        );
      }
    } else {
      await errorHandle(response);
    }
  }
};

const loadDetails = async (id) => {
  try {
    fetch(process.env.REACT_APP_API + "/api/details/" + id)
      .then(async (res) => {
        var notification;
        if (!res.ok) {
          if (res.status === 404) {
            //window.location = "/"
            store.dispatch(error(404));
            throw "err";
          } else if (res.status === 410) {
            //window.location = "/"
            store.dispatch(error(410));
            throw "err";
          } else if (res.status === 406) {
            //window.location = "/"
            return res.json();
          } else if (res.status >= 400 && res.status < 500) {
            notification = {
              title: "Er is een fout opgetreden",
              text: (await res.text()) + "",
            };
          } else if (res.status >= 500 && res.status < 600) {
            notification = {
              title: "Server fout",
              text: (await res.text()) + "",
            };
          }
          createNotification(
            {
              type: "error",
              ...notification,
            },
            5000
          );
        } else {
          return res.json();
        }
      })
      .then((json) => {
        if (json.redirect) {
          // window.location = json.redirect
          return 1;
        } else {
          store.dispatch(checkoutData(json));
        }
        //store.dispatch(checkoutData(json))
        return 1;
      })
      .catch((err) => {
        console.error(err);
        if (err != "err")
          store.dispatch(
            error(
              "Kan geen verbinding maken met de server, probeer het later opnieuw"
            )
          );
      });
  } catch (err) {
    console.error(err);
    if (err != "err")
      store.dispatch(
        error(
          "Kan geen verbinding maken met de server, probeer het later opnieuw"
        )
      );
    return 0;
  }
};

const getCoupon = async (coupon) => {
  try {
    store.dispatch(CouponLoading(true));
    fetch(process.env.REACT_APP_API + "/api/coupon/" + coupon)
      .then(async (res) => {
        var notification;
        if (!res.ok) {
          if (res.status === 404) {
            notification = {
              title: "Kortingscode ongeldig",
              text: "Kan kortingscode " + coupon + " niet vinden.",
            };
          } else if (res.status >= 400 && res.status < 500) {
            notification = {
              title: "Er is een fout opgetreden",
              text: (await res.text()) + "",
            };
          } else if (res.status >= 500 && res.status < 600) {
            notification = {
              title: "Server fout",
              text: (await res.text()) + "",
            };
          }
          store.dispatch(CouponLoading(false));
          createNotification(
            {
              type: "error",
              ...notification,
            },
            5000
          );
        }
        return res.json();
      })
      .then((json) => {
        store.dispatch(
          setCoupon({
            valid: true,
            code: coupon,
            discount: json.discountType,
            value: json.discountVal,
          })
        );
        createNotification(
          {
            type: "info",
            title: "Kortingscode toegepast",
            text: "Code toegepast",
          },
          5000
        );
      })
      .catch((err) => {
        store.dispatch(CouponLoading(false));
      });
    return 0;
  } catch (err) {
    return 0;
  }
};

const deleteDesign = async (data) => {
  try {
    // var designColors = JSON.parse(JSON.stringify(store.designs.designColors))
    //store.dispatch(updateLayers({designColors:data}))
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };
    var designGroup = store.getState().settings.designGroup;
    var ident = store.getState().design.ident;
    fetch(
      process.env.REACT_APP_API + "/api/designs/" + designGroup + "/" + ident,
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => {
        var file = store.getState().design.origFilename;
        createNotification(
          {
            type: "info",
            title: `Ontwerp verwijderd`,
            text: "Ontwerp " + file + " is verwijderd.",
          },
          5000
        );
        store.dispatch(unloadDesign());
        store.dispatch(removeDesign(ident));
      })
      .catch(() => {
        // store.dispatch(updateLayers({designColors:designColors}))
        createNotification(
          {
            type: "error",
            title: "Er is een fout opgetreden",
            text: "Er is een fout opgetreden bij het aanpassen van de lagen, probeer het later nog eens.",
          },
          5000
        );
      });
    return 0;
  } catch (err) {
    return 0;
  }
};

const closeNot = (i) => {
  store.dispatch(closeNotification([i, 1]));
  setTimeout(() => {
    store.dispatch(closeNotification([i, 2]));
  }, 220);
  setTimeout(() => {
    store.dispatch(removeNotification(i));
  }, 340);
};

const updateData = (ident, data) => {
  if (data.design !== undefined) {
    store.dispatch(
      updateDesigns({
        ident: ident,
        data: data.design,
      })
    );
    delete data.design;
  }
  var material = store.getState().design.material;
  if (data.status == 3) {
    createNotification(
      {
        type: "error",
        title: "Ontwerp leeg",
        text: "Het ontwerp is leeg.",
      },
      5000
    );
  } else if (data.status == 1) {
    createNotification(
      {
        type: "error",
        title: "Ontwerp te groot voor materiaal",
        text:
          "Het ontwerp is te groot voor het gekozen materiaal (" +
          material.material +
          ").",
      },
      5000
    );
  }
  store.dispatch(updateLayers(data));
};

const updateDesignColors = async (data) => {
  try {
    await apiCall(endpointFormatter("update/designColors"), {
      designColors: data,
    });
    return 0;
  } catch (err) {
    return 0;
  }
};

const reportMistake = async (data) => {
  try {
    return await apiCall(endpointFormatter("report"), data, (data) => {
      return 200;
    });
  } catch (err) {
    return 0;
  }
};

const updateScale = async (data) => {
  try {
    apiCall(endpointFormatter("update/scale"), { scale: data });
    return 0;
  } catch (err) {
    return 0;
  }
};

const updateQty = async (data) => {
  try {
    apiCall(endpointFormatter("update/quantity"), { quantity: data });
    return 0;
  } catch (err) {
    return 0;
  }
};

const updateMaterial = async (data) => {
  try {
    await apiCall(endpointFormatter("update/material"), data);
    return 0;
  } catch (err) {
    return 0;
  }
};

const updateExtra = async (data) => {
  try {
    await apiCall(endpointFormatter("update/extra"), data);
    return 0;
  } catch (err) {
    return 0;
  }
};

function endpointFormatter(endpoint) {
  var ident = store.getState().design.ident;
  var designGroup = store.getState().settings.designGroup;
  return `designs/${designGroup}/${ident}/${endpoint}`;
}

async function errorHandle(res) {
  var notification;
  if (res.status == 400) {
    let json = await res.json();
    if (json.error) {
      notification = {
        title: "Er is een fout opgetreden",
        text: json.error.msg,
      };
    } else if (json.errors) {
      notification = {
        title: "Er is een fout opgetreden",
        text: json.errors.map((el) => el.msg).join("\n"),
      };
    }
  } else if (res.status >= 400 && res.status < 500) {
    notification = {
      title: "Er is een fout opgetreden",
      text: await res.text(),
    };
  } else if (res.status >= 500 && res.status < 600) {
    notification = {
      title: "Server fout",
      text: await res.text(),
    };
  }
  createNotification(
    {
      type: "error",
      ...notification,
    },
    5000
  );
  return res.status;
}

const apiCall = async (endpoint, data, customSuccess, customError) => {
  var raw = JSON.stringify(data);
  var header = new Headers();
  header.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    body: raw,
    headers: header,
    redirect: "follow",
  };

  var ident = store.getState().design.ident;
  try {
    var response = await fetch(
      `${process.env.REACT_APP_API}/api/${endpoint}`,
      requestOptions
    );
  } catch (err) {
    createNotification(
      {
        type: "error",
        title: "Netwerk fout",
        text: "Kan geen verbinding maken met server, probeer het later opnieuw.",
      },
      5000
    );
    return -1;
  }

  if (response.status >= 200 && response.status <= 299) {
    var json = await response.json();
    if (customSuccess === undefined) {
      return await updateData(ident, json);
    } else {
      return await customSuccess(json);
    }
  } else {
    if (customError === undefined) {
      return await errorHandle(response);
    } else {
      return await customError(response);
    }
  }
};

export {
  reportMistake,
  getMaterials,
  loadDesign,
  updateDesignColors,
  updateQty,
  updateMaterial,
  updateExtra,
  uploadFiles,
  deleteDesign,
  getCoupon,
  loadCheckout,
  checkout,
  orderOperation,
  loadDesignGroup,
  createNotification,
  getVat,
  loadDetails,
  updateScale,
  getAnnouncement,
  loadquote,
};
