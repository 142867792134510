import { useEffect, useState } from "react";
// import * as CC from "react-cookie-consent";
import { Button, Modal, ModalBody } from "reactstrap";
import GTM from "../gtm";
import Cookies from 'universal-cookie';
import Tax from "../tax";
const cookies = new Cookies();
function CookieConsent() {
  const [modal, setModal] = useState(false);
  const handleAcceptCookie = (userInteraction) => {
    cookies.set("CookieConsent", "accept", { path: "/", domain: process.env.NODE_ENV=="production"?".laserlokaal.nl":"" });
    setModal(false);
    GTM.updateConsent();
    Tax.init();
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    cookies.set("CookieConsent", "decline", { path: "/", domain: process.env.NODE_ENV=="production"?".laserlokaal.nl":"" });
    setModal(false);
    GTM.updateConsent();
    Tax.init();
  };

  useEffect(() => {
    if(cookies.get("CookieConsent") === undefined){
      setModal(true);
    }
  }, []);


  return (
    <Modal isOpen={modal} centered size="md">
      <ModalBody className="text-center">
        <img src="/logo-dark.png" className="w-50 mb-3" alt="Logo" />
        <div className="text-start">
          <b className="mb-1 d-block">Cookievoorkeuren</b>
          <p className="fs-7">
            Om jouw de beste ervaring te kunnen bieden, maken wij gebruik van cookies.
            Functionele cookies zijn noodzakelijk en zorgen dat de site naar behoren werkt.
            Analytische cookies helpen ons om de site te verbeteren.
            Marketing cookies zijn optioneel en helpen ons inzicht te krijgen in onderandere onze marketing campagnes.
            Klik op 'Accepteren' om te accepteren. Kies je voor <span role="button" className="text-decoration-underline text-primary" onClick={handleDeclineCookie}>weigeren</span>? Dan plaatsen we alleen functionele en analytische cookies.
            Lees <a onClick={handleDeclineCookie} href="https://laserlokaal.nl/policies/privacy-policy" target="_blank" rel="noopener noreferrer">hier</a> meer over onze cookie- en privacyverklaring. 
          </p>
        </div>
        <Button onClick={handleAcceptCookie} color="primary">Accepteren</Button>
      </ModalBody>
    </Modal>
    // <CC.CookieConsent
    //   enableDeclineButton
    //   onDecline={handleDeclineCookie}
    //   onAccept={handleAcceptCookie}
    //   location="bottom"
    //   buttonText={"Accepteren"}
    //   declineButtonText={"Alleen noodzakelijk"}
    //   cookieName="CookieConsent"
    //   style={(window.location.pathname.startsWith("/designs")|| window.location.pathname == "/" )?{ background: "#013765", position:"relative", flexGrow:1 }: { background: "#013765",position:"fixed", bottom:0, left:0, right:0, zIndex:9999}}
    //   declineButtonClasses="btn btn-outline-warning btn-sm"
    //   buttonClasses="btn btn-success text-white"
    //   containerClasses="d-flex p-3 text-white align-items-center"
    //   contentClasses="flex-grow-1"
    //   buttonWrapperClasses="d-flex align-items-center flex-wrap justify-content-center gap-2"
    //   disableStyles={true}
    //   extraCookieOptions={{ domain: process.env.NODE_ENV=="production"?".laserlokaal.nl":"" }}
    //   expires={150}
    // >
    //   Wij maken gebruik van cookies om ervoor te zorgen dat je de beste ervaring op onze website krijgt.{" "}
    //   <a href="https://cookiesandyou.com/" target="_blank" style={{ color:"white",fontSize: "12px" }}>Lees meer</a>
    // </CC.CookieConsent>
  );
}

export default CookieConsent;