import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  TabContent,
  TabPane,
  Fade,
  UncontrolledTooltip,
} from "reactstrap";
import { Link, useLocation, useParams, Navigate } from "react-router-dom";
import * as d3 from "d3";

import {
  setField,
  validate,
  setDifferentInvoiceAddress,
  setCompany,
  back,
  checkoutData,
  resetVat,
} from "../../store/checkout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faInfoCircle,
  faTimes,
  faPlus,
  faMinus,
  faTruck,
  faInfo,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";

import FadeInOut from "./FadeInOut";
import Toast from "../Analyzer/components/Toast";
import Loader from "../Analyzer/components/Loader";

import { getCoupon, loadCheckout, getVat } from "../../api";
import Coupon from "./Coupon";
import Error404 from "../../components/error404";
import Error410 from "../../components/error410";
import { ToastContainer, toast } from "react-toastify";
//Utility

function roundTo(value, decimals) {
  return Number(
    Math.round(+value.toFixed(11) + "e" + decimals) + "e-" + decimals
  ).toFixed(2);
}

class Designs extends Component {
  state = {};

  getExtraText = ({ top, bot }) => {
    if (top == 1 && bot == 1) {
      return "Beschermfolie beide kanten";
    } else if (top == 1 && bot == 2) {
      return "Bescherm- en dubbelzijdige plakfolie";
    } else if (bot == 2) {
      return "Dubbelzijdige plakfolie";
    } else if (top == 1) {
      return "Beschermfolie boven";
    } else if (bot == 1) {
      return "Beschermfolie onder";
    }
    return -1;
  };

  render() {
    const { quote } = this.props;
    const { VATreverseCharge } = quote;
    let coupon = quote.coupon;
    const { data, shipCat } = quote.quoteData;

    const operations = data.reduce(
      (acc, val) => acc + parseFloat(val.qty * val.price.operations),
      0
    );
    const total = data.reduce(
      (acc, val) => acc + parseFloat(val.price.total.value),
      0
    );
    return (
      <React.Fragment>
        <div className="h-100 d-flex flex-column">
          <Container className="">
            <Row className="overflow-auto">
              <Col xs="2"></Col>
              <Col xs="5">
                <span style={{ fontSize: "12px" }}>Ontwerp</span>
              </Col>
              <Col xs="2">
                <div className="h-100 d-flex justify-content-end align-items-center">
                  <span style={{ fontSize: "12px" }}>Stukprijs</span>
                </div>
              </Col>
              <Col xs="3">
                <div className="h-100 d-flex justify-content-end align-items-center">
                  <span style={{ fontSize: "12px" }}>Prijs</span>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="overflow-auto flex-grow-1">
            <Container>
              {data.map((design, i) => (
                <Row key={i} className="mt-3">
                  <Col xs="2">
                    <div className="h-100 d-flex justify-content-start align-items-center">
                      <div className="border p-2 bg-white w-100 rounded position-relative ">
                        <div
                          style={{
                            paddingTop: "100%",
                            background:
                              "url(" +
                              process.env.REACT_APP_API +
                              design.img +
                              ")",
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-pink">
                          {design.qty}x
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xs="5">
                    <div className="h-100 d-flex justify-content-start align-items-center">
                      <div>
                        <span className="d-block fw-bold">
                          {design.origFilename}
                        </span>
                        <span
                          className="d-block lh-sm"
                          style={{ fontSize: "12px" }}
                        >
                          Snij- en graveerkosten: €{" "}
                          {roundTo(design.price.operations, 2)}
                        </span>
                        <span
                          className="d-block lh-sm"
                          style={{ fontSize: "12px" }}
                        >
                          Materiaal: € {roundTo(design.price.material, 2)}
                        </span>
                        {(design.price.additional && (
                          <span
                            className="d-block lh-sm text-danger"
                            style={{ fontSize: "12px" }}
                          >
                            {this.getExtraText(design.extra)}: €{" "}
                            {roundTo(design.price.additional, 2)}
                          </span>
                        )) ||
                          ""}
                      </div>
                    </div>
                  </Col>
                  <Col xs="2">
                    <div className="h-100 d-flex justify-content-end align-items-center">
                      <b>&euro; {roundTo(design.price.total.unitPrice, 2)}</b>
                    </div>
                  </Col>
                  <Col xs="3">
                    <div className="h-100 d-flex justify-content-end align-items-center">
                      <b>&euro; {roundTo(design.price.total.value, 2)}</b>
                    </div>
                  </Col>
                </Row>
              ))}
            </Container>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const design = state.design;
  const quote = state.quote;
  const settings = state.settings;

  return {
    settings,
    design,
    quote,
  };
}

export default connect(mapStateToProps)(Designs);

// export default Analyzer;
