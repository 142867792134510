import React, { Component } from 'react';
import { Button, Alert } from "reactstrap";
import { connect } from "react-redux";
//Import images
import { announcementDismiss } from '../store/settings'
class Announcement extends Component {

  render() {
    var announcement = this.props.announcement
    if(announcement == null){
      return "";
    }
    return (
        <React.Fragment>
        <Alert
          className="rounded-0 mb-0 alert-sm"
          color="dark"
          isOpen={announcement.show}
          toggle={()=>{this.props.dispatch(announcementDismiss())}}
        >
          {announcement.message}
        </Alert>
        </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  const {announcement} = state.settings;
  return {
    announcement
  };
}

export default connect(mapStateToProps)(Announcement);
