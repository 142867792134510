import './style/scss/bootstrap.scss';
import './style/scss/dark.scss';

import { Button } from 'reactstrap';
import React, { Component, useEffect } from "react";
import { Routes , BrowserRouter as Router, Route, useParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { authProtectedRoutes, publicRoutes } from "./routes/";
// import AppRoute from "./routes/route";
import Analyzer from "./pages/Analyzer/";
import 'react-toastify/dist/ReactToastify.css';
import CookieConsent from './components/cookieConsent';


function App() {
  return (
    <React.Fragment>

      <Router>
        <Routes>
          {publicRoutes.map((route, idx) => {
            return <Route key={idx} path={route.path} element={<route.component />} />
          })}
        </Routes>
      </Router>
      {!(window.location.pathname.startsWith("/embed"))?
      <CookieConsent/>
      :null}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />

    </React.Fragment>
  );
}

export default App;
