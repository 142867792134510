import React, { Component } from "react";
import { connect } from "react-redux";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Container } from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faMinus } from '@fortawesome/free-solid-svg-icons'
import Ignored from './Ignored'
import FileEmpty from './FileEmpty.svg'
import * as d3 from "d3";

import { closeEmptyFiles } from "../../../store/designs";

function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

class EmptyFiles extends Component {
  state = {
    show:true,
    selected:0
  }
  constructor(props) {
    super(props);
    this.svg = React.createRef();
  }
  componentDidMount(){
    // if(this.props.emptyFiles.length != 0){
    //   this.setState({show:true})
    //   //this.updateSVG()
    // }
    this.updateSVG()
  }

  componentDidUpdate(prevProps,prevState,test){
    if((this.props.emptyFiles.length != 0 && prevProps.emptyFiles.length == 0 && this.state.show == false)){
      this.setState({show:true})
      this.updateSVG()
    }
    if(prevState.selected != this.state.selected){
      this.updateSVG()
    }
  }
  close = ()=>{
    this.setState({show:false,selected:0})
    setTimeout(()=>{
      this.props.dispatch(closeEmptyFiles())
    },1000)
  }

  updateSVG = () => {
    if(this.svg.current == undefined){
      return 0
    }
    var ignored = this.props.emptyFiles[this.state.selected]
    var smallX = Math.min(...ignored.text.map(el=>el.smallX), ...ignored.images.map(el=>el.smallX), ...ignored.fills.map(el=>el.smallX))
    var smallY =  Math.min(...ignored.text.map(el=>el.smallY), ...ignored.images.map(el=>el.smallY), ...ignored.fills.map(el=>el.smallY))
    var largeX = Math.max(...ignored.text.map(el=>el.largeX), ...ignored.images.map(el=>el.largeX), ...ignored.fills.map(el=>el.largeX))
    var largeY = Math.max(...ignored.text.map(el=>el.largeY), ...ignored.images.map(el=>el.largeY), ...ignored.fills.map(el=>el.largeY))
    var margin = 70
    var viewbox = [
      (smallX)-margin,
      (smallY)-margin,
      (largeX - smallX)+2*margin,
      (largeY - smallY)+2*margin
    ]
    var svg = d3.select(this.svg.current)
    svg.selectAll("*").remove()
    svg.attr("viewBox", viewbox.join(" "))
    var bbox = svg.append("g").append("path").attr("fill","none").attr("d", `M ${smallX-margin} ${smallY-margin} H ${largeX+margin} V ${largeY+margin} H ${smallX-margin} Z`)
    bbox = bbox.node().getBoundingClientRect()

    var test = ["fills","text","images"]
    test.map((key)=>{
      ignored[key].map((el,i)=>{
        svg.append("g").append("path").attr("fill","#dc3545").attr("opacity","0.05").attr("d", `M ${el.smallX} ${el.smallY} H ${el.largeX} V ${el.largeY} H ${el.smallX} Z`)
        if(key == "fills"){
          var error = svg.append("g").attr("id",key+i).attr("transform",`translate(${(el.smallX + el.width/2)} ${(el.smallY + el.height/2)}) scale(${(14/496)*((viewbox[2])/bbox.width)}) `)
          ///error.append("path").attr("fill","white").attr("d","M 256 -128 V -224 c 0 -17.67 -14.33 -32 -32 -32 h -96 c -17.67 0 -32 14.33 -32 32 H -96 c 0 -17.67 -14.33 -32 -32 -32 H -224 C -241.67 -256 -256 -241.67 -256 -224 v 96 c 0 17.67 14.33 32 32 32 v 192 c -17.67 0 -32 14.33 -32 32 v 96 c 0 17.67 14.33 32 32 32 h 96 c 17.67 0 32 -14.33 32 -32 h 192 c 0 17.67 14.33 32 32 32 h 96 c 17.67 0 32 -14.33 32 -32 v -96 c 0 -17.67 -14.33 -32 -32 -32 V -96 c 17.67 0 32 -14.33 32 -32 z")
          error.append("path").attr("fill","#dc3545").attr("d","M 256 -128 V -224 c 0 -17.67 -14.33 -32 -32 -32 h -96 c -17.67 0 -32 14.33 -32 32 H -96 c 0 -17.67 -14.33 -32 -32 -32 H -224 C -241.67 -256 -256 -241.67 -256 -224 v 96 c 0 17.67 14.33 32 32 32 v 192 c -17.67 0 -32 14.33 -32 32 v 96 c 0 17.67 14.33 32 32 32 h 96 c 17.67 0 32 -14.33 32 -32 h 192 c 0 17.67 14.33 32 32 32 h 96 c 17.67 0 32 -14.33 32 -32 v -96 c 0 -17.67 -14.33 -32 -32 -32 V -96 c 17.67 0 32 -14.33 32 -32 z m -96 -64 h 32 v 32 h -32 V -192 z M -192 -192 h 32 v 32 H -192 V -192 z m 32 384 H -192 v -32 h 32 v 32 z m 352 0 h -32 v -32 h 32 v 32 z m -32 -96 h -32 c -17.67 0 -32 14.33 -32 32 v 32 H -96 v -32 c 0 -17.67 -14.33 -32 -32 -32 H -160 V -96 h 32 c 17.67 0 32 -14.33 32 -32 V -160 h 192 v 32 c 0 17.67 14.33 32 32 32 h 32 v 192 z")
        } else if(key=="text") {
          var error = svg.append("g").attr("id",key+i).attr("transform",`translate(${(el.smallX + el.width/2)} ${(el.smallY + el.height/2)}) scale(${(14/496)*((viewbox[2])/bbox.width)}) `)
          //error.append("path").attr("fill","white").attr("d","M 248 0 c 0 136.997 -111.043 248 -248 248 S -248 136.997 -248 0 C -248 -136.917 -136.957 -248 0 -248 s 248 111.083 248 248 z")
          error.append("path").attr("fill","#dc3545").attr("d","M 215.5789 -256 H -215.5789 C -237.9021 -256 -256 -237.9021 -256 -215.5789 v 105.0678 c 0 22.3232 18.0979 40.4211 40.4211 40.4211 c 22.3232 0 40.4211 -18.0979 40.4211 -40.4211 V -175.1579 h 134.7368 v 350.3158 H -102.8419 c -22.3232 0 -40.4211 18.0979 -40.4211 40.4211 s 18.0979 40.4211 40.4211 40.4211 h 205.6839 c 22.3232 0 40.4211 -18.0979 40.4211 -40.4211 s -18.0979 -40.4211 -40.4211 -40.4211 H 40.4211 V -175.1579 h 134.7368 v 64.6467 c 0 22.3232 18.0979 40.4211 40.4211 40.4211 c 22.3232 0 40.4211 -18.0979 40.4211 -40.4211 V -215.5789 C 256 -237.9021 237.9021 -256 215.5789 -256 z")
        } else if(key=="images") {
          var error = svg.append("g").attr("id",key+i).attr("transform",`translate(${(el.smallX + el.width/2)} ${(el.smallY + el.height/2)}) scale(${(14/512)*((viewbox[2])/bbox.width)} ${(14/(512))*((viewbox[2])/bbox.width)}) `)
          //error.append("path").attr("fill","white").attr("d","M 208 -192 H -208 C -234.51 -192 -256 -170.51 -256 -144 v 288 c 0 26.51 21.49 48 48 48 h 416 c 26.51 0 48 -21.49 48 -48 V -144 c 0 -26.51 -21.49 -48 -48 -48 z m -6 336 H -202 a 6 6 0 0 1 -6 -6 V -138 a 6 6 0 0 1 6 -6 h 404 a 6 6 0 0 1 6 6 v 276 a 6 6 0 0 1 -6 6 z")
          error.append("path").attr("fill","#dc3545").attr("d","M 208 -192 H -208 C -234.51 -192 -256 -170.51 -256 -144 v 288 c 0 26.51 21.49 48 48 48 h 416 c 26.51 0 48 -21.49 48 -48 V -144 c 0 -26.51 -21.49 -48 -48 -48 z m -6 336 H -202 a 6 6 0 0 1 -6 -6 V -138 a 6 6 0 0 1 6 -6 h 404 a 6 6 0 0 1 6 6 v 276 a 6 6 0 0 1 -6 6 z M -128 -104 c -22.091 0 -40 17.909 -40 40 s 17.909 40 40 40 s 40 -17.909 40 -40 s -17.909 -40 -40 -40 z M -160 96 h 320 v -80 l -87.515 -87.515 c -4.686 -4.686 -12.284 -4.686 -16.971 0 L -64 48 l -39.515 -39.515 c -4.686 -4.686 -12.284 -4.686 -16.971 0 L -160 48 v 48 z")
        }
      })
    })
  }

  render (){

    if(this.props.emptyFiles == undefined || this.props.emptyFiles.length == 0){
      return ""
    }
    const ignored = this.props.emptyFiles[this.state.selected]
    return (
      <React.Fragment>

        <Modal
          centered
          size="xl"
          isOpen={this.state.show}

          onOpened={this.updateSVG}
        >
          <ModalHeader toggle={this.close}>
            Niet herkende bestand{this.props.emptyFiles.length>1?"en":""}
          </ModalHeader>
          <ModalBody className="p-0">

          <Container>
            <Row>
              <Col xl={8} style={{height: "50vh"}}>
              <div style={{height: "50vh"}} className="position-relative">
                {ignored.fills.length == 0 && ignored.text.length == 0 && ignored.images.length == 0?
                  <img className="position-absolute top-50 start-50 translate-middle" height="25%" width="25%"  src={FileEmpty}/>
                  :
                <svg height="100%" width="100%" ref={this.svg}>
                </svg>}
              </div>
              </Col>
              <Col xl={4} className="bg-light">
              <div className="d-flex flex-column align-items-stretch h-100">
              <div className="flex-grow-1">
              <h6 className="mt-2">Bestand: {ignored.file}</h6>
              {ignored.fills.length == 0 && ignored.text.length == 0 && ignored.images.length == 0?
                <React.Fragment>
                  <p>Dit bestand bevat geen paden die we kunnen herkennen. <a href="https://laserlokaal.nl/pages/tekening-uploaden-bestellen" target="_blank">Zorg dat je tekening voldoet aan onze tekenregels</a></p>
                </React.Fragment>
              :
                <React.Fragment>
                  <p>Dit bestand bevat geen paden die we kunnen herkennen. We hebben geprobeerd een illustratie te maken van paden die herkend zijn, maar niet voldoen aan onze <a href="https://laserlokaal.nl/pages/tekening-uploaden-bestellen" target="_blank">tekenregels</a>.</p>
                  <Ignored ignored={ignored}/>
                </React.Fragment>
              }


              </div>
              <div className="text-center m-2">
                {this.props.emptyFiles.map((file,i)=><Button className="btn-circle m-1" key={i} color="primary" active={this.state.selected == i} outline onClick={()=>{this.setState({selected:i})}}>{i+1}</Button>)}
              </div>
              </div>
              </Col>
            </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.close}>
              Sluiten
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const emptyFiles = state.designs.emptyFiles;
  return {
    emptyFiles
  };
}

export default connect(mapStateToProps)(EmptyFiles);
// export default DesignsMenu
