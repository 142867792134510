import React from "react";
import { Navigate } from "react-router-dom";

import Analyzer from "../pages/Analyzer/";
import Checkout from "../pages/Checkout/";
import Quote from "../pages/Quote/";

import Details from "../pages/Checkout/Details";
import Error404 from "../components/error404";

import QuoteEmbed from "../pages/Embeds/Quote"
import ContactEmbed from "../pages/Embeds/Contact"

const publicRoutes = [
	{ path: "/designs/:designGroup", component: Analyzer },
	{ path: "/", component: Analyzer },
	{ path: "/checkout/:designGroup", component: Checkout },
  { path: "/quote/:designGroup", component: Quote },
	{ path: "/details/:id", component: Details },
  { path: "/embed/quote", component: QuoteEmbed },
  { path: "/embed/contact", component: ContactEmbed },
	{ path: "*", component: Error404 },
	// { path: "/", exact: true, component: () => <Navigate to="/dashboard" /> }
];

const authProtectedRoutes = [
	// { path: "/logout", component: Logout },
	// { path: "/login/pin", component: LoginPin },
	// { path: "/login", component: Login },
];

export { authProtectedRoutes, publicRoutes };
