import React, { Component } from "react";
import { connect } from "react-redux";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Container, Card, CardBody } from "reactstrap";
import Visualisation from "../Analyzer/components/Visualisation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faMinus, faCheck } from '@fortawesome/free-solid-svg-icons'

function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

function foilText({top,bot}){
  if(top == 1 && bot == 1){
   return "Beschermfolie beide kanten"
  } else if(top == 1 && bot == 2){
    return "Bescherm- en dubbelzijdige plakfolie"
  } else if (bot == 2){
    return "Dubbelzijdige plakfolie"
  } else if(top == 1){
    return "Beschermfolie boven"
  } else if(bot == 1){
    return "Beschermfolie onder"
  }
  return -1;
}

class checkOperations extends Component {
  state = {
    selected:0
  }
  constructor(props) {
    super(props);
  }

  close = () =>{
    this.props.close()
    this.setState({selected:0})
  }

  render (){
    const selected = this.state.selected
    const data = this.props.data
    const design = data[selected]
    var text = foilText(design.extra)
    return (
      <React.Fragment>
        <div className="h-100 d-flex flex-column">
          <Container className="d-flex h-100 flex-column flex-grow-1 ">
            <Row className="overflow-auto">
              <Col xs={12} className="mb-3" style={{maxHeight:"45vh"}}>
                <Visualisation design={design} showIgnored={false} visualisation={design.img}/>
              </Col>
              <Col xs={12} className="mb-3">
                <Card className="h-100">
                  <CardBody>
                    <h6>Lagen</h6>
                    <table className="table design-colors text-center">
                      <thead>
                        <tr>
                          <th className="border-top-0">Kleur</th>
                          <th className="border-top-0">Vlakgraveren</th>
                          <th className="border-top-0">Lijngraveren</th>
                          <th className="border-top-0">Snijden</th>
                        </tr>
                      </thead>
                      <tbody>
                      {Object.keys(design.designColors).map(
                        (color,i,state)=>{
                          var layer = design.designColors[color]
                          return(<tr key={i}>
                            <td>
                              <div className="color-container mx-auto d-inline-block">
                                <div className="color " title={color} style={{"--c":color}} >
                                </div>
                              </div>
                            </td>
                            <td>
                              {layer.raster?<FontAwesomeIcon icon={faCheck}/>:""}
                            </td>
                            <td>
                              {layer.engrave?<FontAwesomeIcon icon={faCheck}/>:""}
                            </td>
                            <td>
                              {layer.cut?<FontAwesomeIcon icon={faCheck}/>:""}
                            </td>
                          </tr>)
                        })
                      }
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} className="mb-3">
                <Card className="h-100">
                  <CardBody>
                    <h6>Ontwerp</h6>
                    <Row>
                      <Col>
                        Naam:<br/>
                        Aantal:<br/>
                        Afmetingen:<br/>
                      </Col>
                      <Col>
                        {design.origFilename}<br/>
                        {design.qty}<br/>
                        {design.dimensions.x} x {design.dimensions.y} cm

                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} className="mb-3">
                <Card className="h-100">
                  <CardBody>
                    <h6>Materiaal</h6>
                    <Row className="mb-2">
                      <Col>
                        Naam:<br/>
                        Dikte<br/>
                        Afmetingen:<br/>
                      </Col>
                      <Col>
                        {design.material.material}<br/>
                        {design.material.thickness}mm<br/>
                        {design.material.XY[0]} x {design.material.XY[1]} cm
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" className="mb-3">
                <Card className="h-100">
                  <CardBody>
                    <h6>Prijs</h6>
                    <table className="table">
                      <tbody>
                      <tr>
                        <td>Snij- en graveerkosten</td>
                        <td className="text-end">&euro; {roundTo(design.price.operations,2)}</td>
                      </tr>
                      <tr>
                        <td>Materiaal</td>
                        <td className="text-end">&euro; {roundTo(design.price.material,2)}</td>
                      </tr>
                      {text!=-1?
                      <tr>
                        <td>{text}</td>
                        <td className="text-end">&euro; {roundTo(design.price.additional || 0,2)}</td>
                      </tr>
                      :""}
                      {design.price.discount.discount!=0?
                      <tr>
                        <td><b>{design.price.discount.discount}%</b> Productie korting</td>
                        <td className="text-end">&euro; -{design.price.discount.value}</td>
                      </tr>
                      :""}

                      {design.price.discount.discount!=0?
                      <tr>
                        <th>Prijs per stuk</th>
                        <td className="text-end">&euro; {design.price.total.unitPrice}</td>
                      </tr>
                      :""}

                      <tr style={{borderTop:"2px solid var(--bs-dark)"}}>
                        <th>Subtotaal</th>
                        <td className="text-end">&euro; {roundTo(design.price.total.value,2)}</td>
                      </tr>
                      <tr className="border-top">
                        <td>BTW</td>
                        <td className="text-end">&euro; {roundTo(design.price.tax,2)}</td>
                      </tr>
                      <tr style={{borderTop:"2px solid var(--bs-dark)"}}>
                        <th>Totaal</th>
                        <td className="text-end">&euro; {roundTo(design.price.total.value+design.price.tax,2)}</td>
                      </tr>
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="text-center m-2">
              {this.props.data.map((file,i)=><Button className="btn-circle m-1" key={i} color="primary" active={this.state.selected == i} outline onClick={()=>{this.setState({selected:i})}}>{i+1}</Button>)}
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}


export default (checkOperations);
// export default DesignsMenu
