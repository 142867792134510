import TagManager from "react-gtm-module"
import Cookies from 'universal-cookie';
const tagManagerArgs = {
  gtmId: 'GTM-N3D62Q7',
}

export default {

  init() {
    TagManager.initialize(tagManagerArgs)
    this.updateConsent()
    this.sendEvent("Visit")
  },
  updateConsent() {
    const cookie = (new Cookies()).get("CookieConsent");
    if(cookie !== undefined) {
      if(cookie === "accept"){
        TagManager.dataLayer({
          dataLayer: {
            event: "consent_update",
            "ad_storage": "granted",
            "analytics_storage": "granted"
          }
        })
      } else { 
        TagManager.dataLayer({
          dataLayer: {
            event: "consent_update",
            "ad_storage": "denied",
            "analytics_storage": "granted"
          }
        })
      }
    }
  },
  sendEvent(event, data = {}) {
    TagManager.dataLayer({
      dataLayer: {
        event: event,
        ...data
      }
    })
  }
}
